import { getIdToken } from '@firebase/auth'
import { CustomerSegmentService } from 'gen/proto/customer_segment/customer_segment_pb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useActionTracker } from 'hooks/useMixpanel'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { useState, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  customerSegmentId: string | undefined
  startDate: string
  endDate: string
  fileName: string
  page: string
}

export const useOrdersDownload = ({ customerSegmentId, startDate, endDate, fileName, page }: Props) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const { dispatch } = useActionTracker()
  const authUser = useAuthUser()
  const customerSegmentService = useGrpcClient(CustomerSegmentService)

  const [loading, setLoading] = useState(false)

  const headers = useMemo(
    () => [
      t('features.orders.id'),
      t('features.orders.name'),
      t('features.orders.amount'),
      t('features.orders.datetime'),
      t('features.orders.referrer'),
      t('features.orders.landingPage'),
      t('features.orders.channel'),
      t('features.orders.coupon'),
      t('features.orders.utmSource'),
      t('features.orders.utmMedium'),
      t('features.orders.utmCampaign'),
    ],
    [t]
  )

  const fetchCustomers = useCallback(async () => {
    if (!customerSegmentId) return []

    const token = await getIdToken(authUser!)
    const resp = await customerSegmentService.getOrders(
      { customerSegmentId: customerSegmentId, startDate: startDate, endDate: endDate },
      { headers: { Authorization: `Bearer ${token}` } }
    )
    return resp.orders.map((o) => ({
      id: o.id,
      name: o.name,
      amount: Number(o.amount),
      orderAt: o.orderAt,
      referrer: o.referrer,
      landingPage: o.landingPage,
      channel: o.channel,
      coupon: o.coupon,
      utmSource: o.utmSource,
      utmMedium: o.utmMedium,
      utmCampaign: o.utmCampaign,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSegmentId])

  const handleDownload = useCallback(async () => {
    if (!customerSegmentId) return

    try {
      setLoading(true)
      const customers = await fetchCustomers()

      // Create CSV content
      const csvContent = [
        headers.join(','), // Add headers
        ...customers.map((customer) =>
          [
            customer.id,
            customer.name,
            customer.amount,
            customer.orderAt,
            customer.referrer,
            customer.landingPage,
            customer.channel,
            customer.coupon,
            customer.utmSource,
            customer.utmMedium,
            customer.utmCampaign,
          ].join(',')
        ),
      ].join('\n')

      // Create a blob and trigger download
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${fileName}.csv`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      dispatch('DownloadCSV', { page: page })
    } catch (err) {
      enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
      notifySentry(err)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSegmentId])

  return {
    handleDownload,
    loading,
  }
}
