import { Card, CardContent } from '@/components/ui/card'
import { ChartConfig, ChartContainer, ChartLegend, ChartTooltip, ChartTooltipContent } from '@/components/ui/chart'
import { Box, Grid, Skeleton } from '@mui/material'
import { EmptyState } from 'components/emptyState'
import { InsightDimension } from 'gen/firestore'
import { LoaderCircle } from 'lucide-react'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from 'recharts'
import { HistogramInsightValue } from '../detail'

const chartConfig = {
  customerCount: {
    label: 'customerCount',
    color: 'hsl(var(--chart-2))',
  },
} satisfies ChartConfig

const VISUAL_PAPER_HEIGHT = '80vh'

type Props = {
  dimension: InsightDimension
  insightValues: HistogramInsightValue | undefined
  loading: boolean
  onChartPointClick: (min: number, max: number) => void
}

export const HistogramInsight: FC<Props> = ({ dimension, insightValues, loading, onChartPointClick }) => {
  const { t } = useTranslation()

  const chartData = useMemo(() => {
    if (!insightValues) return []
    return insightValues.values.map((value) => ({
      range: `${value.min}-${value.max}`,
      customerCount: value.customerCount,
      min: value.min,
      max: value.max,
    }))
  }, [insightValues])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          {loading || !insightValues ? (
            <Card className='flex justify-center items-center h-[80vh] rounded-xl border'>
              <LoaderCircle className='animate-spin' />
            </Card>
          ) : insightValues.values.length === 0 ? (
            <Card className='flex flex-col justify-center items-center h-[80vh] rounded-xl border'>
              <EmptyState title={t('features.insights.detail.noData')} />
            </Card>
          ) : (
            <Card className='flex flex-col w-full h-full justify-center'>
              <CardContent>
                <ChartContainer config={chartConfig}>
                  <BarChart accessibilityLayer data={chartData}>
                    <CartesianGrid vertical={false} />
                    <XAxis dataKey='range' tickLine={false} tickMargin={10} axisLine={false} tickFormatter={(value) => value} />
                    <YAxis dataKey='customerCount' tickLine={false} axisLine={false} tickMargin={10} width={40} />
                    <ChartTooltip cursor={false} content={<ChartTooltipContent hideLabel />} />
                    <Bar
                      dataKey='customerCount'
                      fill='var(--color-customerCount)'
                      radius={4}
                      cursor='pointer'
                      onClick={(data) => onChartPointClick(Number(data.min), Number(data.max))}
                    />
                    <ChartLegend
                      content={
                        <div className='flex flex-wrap justify-center pt-6'>
                          <div className='flex items-center mr-4 mb-2 text-base'>{t(`features.insights.dimension_${dimension}`)}</div>
                        </div>
                      }
                    />
                  </BarChart>
                </ChartContainer>
              </CardContent>
            </Card>
          )}
        </Grid>
        <Grid item xs={3}>
          <Grid container spacing={2} sx={{ height: VISUAL_PAPER_HEIGHT }}>
            {['mean', 'median', 'max'].map((stat) => (
              <Grid item xs={12} key={stat}>
                <Card className='flex flex-col justify-center text-center p-5 rounded-xl border h-full'>
                  <Box>{t(`features.insights.detail.histogramInsight.${stat}`)}</Box>
                  <Box fontSize='24px' fontWeight='bold' marginTop='8px' display='flex' justifyContent='center'>
                    {!loading && insightValues ? (
                      insightValues[stat as keyof HistogramInsightValue].toString()
                    ) : (
                      <Skeleton height='20px' width='50%' />
                    )}
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
