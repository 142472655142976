import { Card, CardContent } from '@/components/ui/card'
import { ChartConfig, ChartContainer, ChartLegend, ChartTooltip, ChartTooltipContent } from '@/components/ui/chart'
import { Box, Grid, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Tooltip } from '@mui/material'
import { EmptyState } from 'components/emptyState'
import { SkeletonTable } from 'components/skeletonTable'
import { addIndexToName } from 'features/insights/types/insight'
import { useSort } from 'hooks/useSort'
import { LoaderCircle } from 'lucide-react'
import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Bar, BarChart, LegendProps, XAxis, YAxis } from 'recharts'
import { OrderCountInsightValue, ViewType } from '../detail'
import { NameCheckBoxList } from './components/nameCheckBoxList'

const DEFAULT_VISIBLE_COUNT = 3
const VISUAL_PAPER_HEIGHT = '80vh'
const TABLE_ROWS_PER_PAGE = 10

type Props = {
  viewType: ViewType
  insightValues: OrderCountInsightValue[] | undefined
  loading: boolean
  onChartPointClick: (name: string, index: number) => void
}

export const OrderCountInsight: FC<Props> = ({ viewType, insightValues, loading, onChartPointClick }) => {
  const { t } = useTranslation()

  const [selectedNames, setSelectedNames] = useState<string[]>([])
  const [page, setPage] = useState(0)

  const { sortedData: sortedInsightValues, sort, toggleSort } = useSort<OrderCountInsightValue>(insightValues || [])

  useEffect(() => {
    setSelectedNames(insightValues?.slice(0, DEFAULT_VISIBLE_COUNT).map((value) => value.name) || [])
  }, [insightValues])

  useEffect(() => {
    if (loading) setPage(0) // Reset page
  }, [loading])

  const selectedInsightValues = useMemo(() => {
    if (!insightValues) return []
    return insightValues.filter((value) => selectedNames.includes(value.name))
  }, [insightValues, selectedNames])

  const chartData = useMemo(() => {
    if (!selectedInsightValues) {
      return []
    }

    return selectedInsightValues.map((value) => {
      const total = value.total
      // In some cases, the number may slightly exceed 100% (as in 100.0000001),
      // so it is truncated to a small number that does not affect the UI.
      const truncateToFiveDecimals = (num: number) => Math.floor(num * 100000) / 100000

      return {
        name: value.name,
        onceRatio: truncateToFiveDecimals((value.once / total) * 100),
        twiceRatio: truncateToFiveDecimals((value.twice / total) * 100),
        threeTimesRatio: truncateToFiveDecimals((value.threeTimes / total) * 100),
        fourTimesRatio: truncateToFiveDecimals((value.fourTimes / total) * 100),
        fiveTimesPlusRatio: truncateToFiveDecimals((value.fiveTimesPlus / total) * 100),
        once: value.once,
        twice: value.twice,
        threeTimes: value.threeTimes,
        fourTimes: value.fourTimes,
        fiveTimesPlus: value.fiveTimesPlus,
      }
    })
  }, [selectedInsightValues])

  const chartConfig = {
    onceRatio: {
      label: t('features.insights.detail.orderCountInsight.once'),
      color: `hsl(var(--chart-1))`,
    },
    twiceRatio: {
      label: t('features.insights.detail.orderCountInsight.twice'),
      color: `hsl(var(--chart-2))`,
    },
    threeTimesRatio: {
      label: t('features.insights.detail.orderCountInsight.threeTimes'),
      color: `hsl(var(--chart-3))`,
    },
    fourTimesRatio: {
      label: t('features.insights.detail.orderCountInsight.fourTimes'),
      color: `hsl(var(--chart-4))`,
    },
    fiveTimesPlusRatio: {
      label: t('features.insights.detail.orderCountInsight.fiveTimesPlus'),
      color: `hsl(var(--chart-5))`,
    },
  } satisfies ChartConfig

  return (
    <>
      {viewType === ViewType.visual && (
        <Grid container spacing={2}>
          <Grid item xs={9}>
            {loading || !insightValues ? (
              <Card className='flex justify-center items-center h-[80vh] rounded-xl border'>
                <LoaderCircle className='animate-spin' />
              </Card>
            ) : insightValues.length === 0 ? (
              <Card className='flex flex-col justify-center items-center h-[80vh] rounded-xl border'>
                <EmptyState title={t('features.insights.detail.noData')} />
              </Card>
            ) : (
              <Card className='flex flex-col w-full h-full justify-center'>
                <CardContent>
                  <ChartContainer config={chartConfig}>
                    <BarChart layout='vertical' data={chartData}>
                      <YAxis
                        type='category'
                        dataKey='name'
                        tickLine={false}
                        axisLine={true}
                        width={100}
                        tickFormatter={(value) => (value.length > 15 ? `${value.slice(0, 15)}...` : value)}
                      />
                      <XAxis type='number' />
                      {Object.entries(chartConfig).map(([name, config], idx) => (
                        <Bar
                          key={name}
                          dataKey={name}
                          stackId='ratio'
                          fill={config.color}
                          radius={[0, 0, 0, 0]}
                          cursor='pointer'
                          onClick={(data) => {
                            const barName = data.name
                            const index = idx + 1
                            onChartPointClick(barName, index)
                          }}
                        />
                      ))}
                      <ChartTooltip
                        content={
                          <ChartTooltipContent
                            formatter={(value, name, payload) => {
                              const config = chartConfig[name as keyof typeof chartConfig]
                              const stringName = name as string // The name is always a string because the dataKey of <Bar> is passed over.
                              const actualValueKey = stringName.replace('Ratio', '')
                              const actualValue = payload.payload[actualValueKey]

                              return (
                                <div className='flex min-w-[130px] items-center text-xs text-muted-foreground'>
                                  <span
                                    className='w-3 h-3 inline-block mr-2'
                                    style={{
                                      backgroundColor: config.color || 'gray',
                                    }}
                                  />
                                  {chartConfig[name as keyof typeof chartConfig].label}
                                  <div className='ml-2 flex items-baseline gap-0.5 font-mono font-medium tabular-nums text-foreground'>
                                    {actualValue}
                                    <span className='font-normal text-muted-foreground ml-1'>
                                      {t('features.insights.detail.orderCountInsight.count')}
                                    </span>
                                  </div>
                                </div>
                              )
                            }}
                          />
                        }
                        cursor={false}
                        defaultIndex={1}
                      />
                      <ChartLegend content={<OrderCountLegendContent chartConfig={chartConfig} />} />
                    </BarChart>
                  </ChartContainer>
                </CardContent>
              </Card>
            )}
          </Grid>
          <Grid item xs={3}>
            <NameCheckBoxList
              loading={loading}
              items={insightValues?.map((value) => ({ name: value.name, isVisible: value.isVisible })) || []}
              selectedNames={selectedNames}
              setSelectedNames={setSelectedNames}
              height={VISUAL_PAPER_HEIGHT}
            />
          </Grid>
        </Grid>
      )}

      {viewType === ViewType.table && (
        <Card className='p-[20px_36px] rounded-xl border'>
          {loading || !insightValues ? (
            <SkeletonTable columnCount={8} rowCount={10} />
          ) : insightValues.length === 0 ? (
            <EmptyState title={t('features.insights.detail.noData')} />
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      '& .MuiTableCell-head': {
                        color: (theme) => theme.palette.text.secondary,
                        fontSize: '13px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    }}
                  >
                    <TableCell>{t('features.insights.detail.name')}</TableCell>
                    <TableCell align='center' sx={{ borderRight: (theme) => `1px solid ${theme.palette.divider}` }}>
                      <TableSortLabel
                        active={sort.target === 'total'}
                        direction={sort.target === 'total' && sort.orderBy === 'asc' ? 'asc' : 'desc'}
                        onClick={() => toggleSort('total')}
                        hideSortIcon
                      >
                        {t('features.insights.detail.customerCount')}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align='center'>
                      <TableSortLabel
                        active={sort.target === 'once'}
                        direction={sort.target === 'once' && sort.orderBy === 'asc' ? 'asc' : 'desc'}
                        onClick={() => toggleSort('once')}
                        hideSortIcon
                      >
                        {t('features.insights.detail.orderCountInsight.once')}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align='center' sx={{ borderRight: (theme) => `1px solid ${theme.palette.divider}` }}>
                      <TableSortLabel
                        active={sort.target === 'twicePlus'}
                        direction={sort.target === 'twicePlus' && sort.orderBy === 'asc' ? 'asc' : 'desc'}
                        onClick={() => toggleSort('twicePlus')}
                        hideSortIcon
                      >
                        {t('features.insights.detail.orderCountInsight.twicePlus')}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align='center'>
                      <TableSortLabel
                        active={sort.target === 'twice'}
                        direction={sort.target === 'twice' && sort.orderBy === 'asc' ? 'asc' : 'desc'}
                        onClick={() => toggleSort('twice')}
                        hideSortIcon
                      >
                        {t('features.insights.detail.orderCountInsight.twice')}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align='center'>
                      <TableSortLabel
                        active={sort.target === 'threeTimes'}
                        direction={sort.target === 'threeTimes' && sort.orderBy === 'asc' ? 'asc' : 'desc'}
                        onClick={() => toggleSort('threeTimes')}
                        hideSortIcon
                      >
                        {t('features.insights.detail.orderCountInsight.threeTimes')}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align='center'>
                      <TableSortLabel
                        active={sort.target === 'fourTimes'}
                        direction={sort.target === 'fourTimes' && sort.orderBy === 'asc' ? 'asc' : 'desc'}
                        onClick={() => toggleSort('fourTimes')}
                        hideSortIcon
                      >
                        {t('features.insights.detail.orderCountInsight.fourTimes')}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align='center'>
                      <TableSortLabel
                        active={sort.target === 'fiveTimesPlus'}
                        direction={sort.target === 'fiveTimesPlus' && sort.orderBy === 'asc' ? 'asc' : 'desc'}
                        onClick={() => toggleSort('fiveTimesPlus')}
                        hideSortIcon
                      >
                        {t('features.insights.detail.orderCountInsight.fiveTimesPlus')}
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedInsightValues.slice(page * TABLE_ROWS_PER_PAGE, page * TABLE_ROWS_PER_PAGE + TABLE_ROWS_PER_PAGE).map((row) => (
                    <TableRow key={row.name}>
                      <TableCell
                        component='th'
                        scope='row'
                        style={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                      >
                        <Tooltip title={row.name} placement='top'>
                          <span>{addIndexToName(row.name, row.rank)}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell align='center' sx={{ borderRight: (theme) => `1px solid ${theme.palette.divider}` }}>
                        <Tooltip title={t('features.insights.detail.customers', { count: row.total })} placement='top'>
                          <span>{row.total}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell align='center'>
                        <Tooltip title={t('features.insights.detail.customers', { count: row.once })} placement='top'>
                          <span>{row.once}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell align='center' sx={{ borderRight: (theme) => `1px solid ${theme.palette.divider}` }}>
                        <Tooltip title={t('features.insights.detail.customers', { count: row.twicePlus })} placement='top'>
                          <span>{row.twicePlus}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell align='center'>
                        <Tooltip title={t('features.insights.detail.customers', { count: row.twice })} placement='top'>
                          <span>{row.twice}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell align='center'>
                        <Tooltip title={t('features.insights.detail.customers', { count: row.threeTimes })} placement='top'>
                          <span>{row.threeTimes}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell align='center'>
                        <Tooltip title={t('features.insights.detail.customers', { count: row.fourTimes })} placement='top'>
                          <span>{row.fourTimes}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell align='center'>
                        <Tooltip title={t('features.insights.detail.customers', { count: row.fiveTimesPlus })} placement='top'>
                          <span>{row.fiveTimesPlus}</span>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {insightValues && insightValues.length > 0 && (
            <Box display='flex' justifyContent='center'>
              <Pagination
                shape='rounded'
                count={insightValues ? Math.ceil(insightValues.length / TABLE_ROWS_PER_PAGE) : 0}
                page={page + 1}
                onChange={(_, v) => setPage(v - 1)}
                sx={{ marginY: '20px' }}
              />
            </Box>
          )}
        </Card>
      )}
    </>
  )
}

type orderCountLegendContentProps = LegendProps & {
  chartConfig: ChartConfig
}
const OrderCountLegendContent: React.FC<orderCountLegendContentProps> = ({ payload, chartConfig }) => {
  if (!payload) return null

  return (
    <div className='flex flex-wrap justify-center pt-6'>
      {payload.map((entry, index) => {
        const label = chartConfig[entry.value as keyof typeof chartConfig].label

        return (
          <div key={`item-${index}`} className='flex items-center mr-4 mb-2 text-xs'>
            <span className='w-3 h-3 inline-block mr-1' style={{ backgroundColor: entry.color }} />
            {label}
          </div>
        )
      })}
    </div>
  )
}
