import { Button } from '@/components/ui/button'
import { Trans, useTranslation } from 'react-i18next'

export const FatalError: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className='flex flex-col items-center justify-center h-screen'>
      <div className='flex flex-col items-center gap-4'>
        <img src='/images/fatal_error.png' alt='fatalError' className='w-[440px]' />
        <h1 className='text-xl font-bold text-center'>{t('features.error.fatalError.title')}</h1>
        <p className='text-sm text-center'>
          <Trans i18nKey='features.error.fatalError.description' />
        </p>
        <Button onClick={() => window.location.reload()}>{t('features.error.fatalError.submit')}</Button>
      </div>
    </div>
  )
}
