import { getIdToken } from '@firebase/auth'
import dayjs from 'dayjs'
import { CustomerSegmentService } from 'gen/proto/customer_segment/customer_segment_pb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OrderState } from '../types/types'

export const useOrders = (customerSegmentId: string | undefined, startDate: string, endDate: string, limit: number) => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()

  const customerSegmentService = useGrpcClient(CustomerSegmentService)
  const [orders, setOrders] = useState<OrderState[]>()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (!customerSegmentId) return

    const handleFetch = async () => {
      setLoading(true)
      try {
        const token = await getIdToken(authUser!)
        const resp = await customerSegmentService.getOrders(
          {
            customerSegmentId,
            startDate,
            endDate,
            limit: BigInt(limit),
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        const orders = resp.orders.map((order) => {
          return {
            id: order.id,
            name: order.name,
            amount: Number(order.amount),
            orderAt: dayjs(order.orderAt),
            referrer: order.referrer,
            landingPage: order.landingPage,
            channel: order.channel,
            coupon: order.coupon,
            utmSource: order.utmSource,
            utmMedium: order.utmMedium,
            utmCampaign: order.utmCampaign,
          }
        })
        setOrders(orders)
      } catch (err) {
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      } finally {
        setLoading(false)
      }
    }

    handleFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSegmentId, startDate, endDate])

  return { orders, loading }
}
