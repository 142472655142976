import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { cn } from '@/lib/utils'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { useAccount } from 'hooks/useAccount'
import { useTranslation } from 'react-i18next'
import { OP_INT_RANGE } from '../schema/const'
import { QuerySetFormState } from '../schema/querySetFormSchema'
import { IntRangeValue } from '../value/intRangeValue'
import { QueryItemProps } from './type'

export const OrderValueQuery: FC<QueryItemProps> = ({ queryItemPath }) => {
  const { t } = useTranslation()
  const { shop } = useAccount()
  const { control } = useFormContext<QuerySetFormState>()

  return (
    <>
      <Controller
        control={control}
        name={`${queryItemPath}.operator`}
        render={({ field, fieldState: { error } }) => {
          return (
            <div className={cn('min-w-[140px]', error && 'border-red-500')}>
              <Select value={field.value} onValueChange={field.onChange}>
                <SelectTrigger className='w-full'>
                  <SelectValue placeholder={t('features.customerSegments.querySet.querySetForm.op', { context: field.value })} />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value={OP_INT_RANGE}>{t('features.customerSegments.querySet.querySetForm.op', { context: OP_INT_RANGE })}</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          )
        }}
      />

      <IntRangeValue
        minValuePath={`${queryItemPath}.value.min`}
        maxValuePath={`${queryItemPath}.value.max`}
        inputAdornmentString={shop ? shop.currency : ''}
        inputPropsMaxValue={9999999}
      />
    </>
  )
}
