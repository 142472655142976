import { convertPresetOptionToDateRange, DateRangePresetOption } from '@/components/dateRangePicker'
import { PageContentWrapper } from '@/components/pageContentWrapper'
import { PageHeader } from '@/components/pageHeader'
import { Button } from '@/components/ui/button'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { timestampToDayjs } from '@/utils/timeUtil'
import dayjs from 'dayjs'
import { DetailSegmentMenu } from 'features/customerSegments/components/detailSegmentMenu'
import { useAccount } from 'hooks/useAccount'
import { useActionTracker } from 'hooks/useMixpanel'
import { Activity, Bot, ChartSpline, Ellipsis, Lightbulb, RefreshCcw, ScanText, User } from 'lucide-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { convertQuerySetToForm, convertStringToApiQuerySetState } from '../../querySetForm/schema/converter'
import { Customers } from './components/customers'
import { DetailDialog } from './components/detailDialog'
import { Metrics } from './components/metrics'
import { Orders } from './components/orders'
import { SuggestInsightDialog } from './components/suggestInsightDialog'
import { Sync } from './components/sync'
import { useCustomerSegment } from './hooks/useCustomerSegment'
import { useCustomers } from './hooks/useCustomers'
import { useDailyOrders } from './hooks/useDailyOrders'
import { useMetricsHistory } from './hooks/useMetricsHistory'
import { useOrders } from './hooks/useOrders'
import { useTagsRunningStatus } from './hooks/useTagsRunningStatus'

enum TabIndex {
  metrics = 'metrics',
  orders = 'orders',
  customers = 'customers',
  sync = 'sync',
}

export const Detail = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const { shop } = useAccount()
  const { dispatch } = useActionTracker()
  const navigate = useNavigate()

  const params = useParams()
  const customerSegmentId = params.id
  const customerSegment = useCustomerSegment(customerSegmentId)

  // Metrics
  const { startDate: initMetricsStartDate, endDate: initMetricsEndDate } = convertPresetOptionToDateRange(
    DateRangePresetOption.last30Days,
    shop.timezone,
    shop.lastDatasetEtlCompletedAt ? timestampToDayjs(shop.lastDatasetEtlCompletedAt) : dayjs()
  )
  const [metricsStartDate, setMetricsStartDate] = useState(initMetricsStartDate.format('YYYY-MM-DD'))
  const [metricsEndDate, setMetricsEndDate] = useState(initMetricsEndDate.format('YYYY-MM-DD'))
  const { metricsHistory, loading: loadingMetricsHistory } = useMetricsHistory(customerSegmentId, metricsStartDate, metricsEndDate)

  // Daily Orders
  const { startDate: orderStartDate, endDate: orderEndDate } = convertPresetOptionToDateRange(
    DateRangePresetOption.last7Days,
    shop.timezone,
    shop.lastDatasetEtlCompletedAt ? timestampToDayjs(shop.lastDatasetEtlCompletedAt) : dayjs()
  )
  const [ordersStartDate, setOrdersStartDate] = useState(orderStartDate.format('YYYY-MM-DD'))
  const [ordersEndDate, setOrdersEndDate] = useState(orderEndDate.format('YYYY-MM-DD'))
  const { dailyOrders, loading: loadingDailyOrders } = useDailyOrders(customerSegmentId, ordersStartDate, ordersEndDate)

  // Orders
  const { orders, loading: loadingOrders } = useOrders(customerSegmentId, ordersStartDate, ordersEndDate, 100)

  // Customers
  const { customers, loading: loadingCustomers } = useCustomers(customerSegment, 100)

  // Tags
  const { currentTagRunning, otherTagsRunning } = useTagsRunningStatus(customerSegmentId)

  const [suggestInsightDialogOpen, setSuggestInsightDialogOpen] = useState(false)
  const [detailDialogOpen, setDetailDialogOpen] = useState(false)

  const initialTabIndex = (): TabIndex => {
    if (location.hash === `#${TabIndex.orders}`) return TabIndex.orders
    if (location.hash === `#${TabIndex.customers}`) return TabIndex.customers
    if (location.hash === `#${TabIndex.sync}`) return TabIndex.sync
    return TabIndex.metrics
  }
  const [tabIndex, setTabIndex] = useState<TabIndex>(initialTabIndex)

  const handleTabChange = (newTabIndex: string) => {
    const tab = newTabIndex as TabIndex
    setTabIndex(tab)
    dispatch('SwitchSegmentDetailTab', { tab: tab })
    navigate(tab === TabIndex.metrics ? '' : `#${tab}`)
  }

  return (
    <>
      <PageHeader
        title={customerSegment?.name || ''}
        origin={{ title: t('features.customerSegments.detail.segments'), path: AUTHORIZED_ROUTE.CUSTOMER_SEGMENTS }}
        menuComponent={
          <>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant='ghost'
                  size='icon'
                  onClick={() => {
                    dispatch('OpenSegmentQueryset', { name: customerSegment?.name })
                    setDetailDialogOpen(true)
                  }}
                >
                  <ScanText />
                </Button>
              </TooltipTrigger>
              <TooltipContent>{t('features.customerSegments.detail.showDetails')}</TooltipContent>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button variant='ghost' size='icon' onClick={() => setSuggestInsightDialogOpen(true)}>
                  <Bot />
                </Button>
              </TooltipTrigger>
              <TooltipContent>{t('features.customerSegments.detail.askAi')}</TooltipContent>
            </Tooltip>

            {customerSegment && (
              <DetailSegmentMenu
                trigger={
                  <Button variant='ghost' size='icon'>
                    <Ellipsis />
                  </Button>
                }
                customerSegment={customerSegment}
              />
            )}
          </>
        }
      />

      <PageContentWrapper>
        <Tabs value={tabIndex} onValueChange={handleTabChange} className='w-full'>
          <div className='flex items-center justify-between mb-6'>
            <TabsList>
              <TabsTrigger value={TabIndex.metrics} className='gap-2'>
                <ChartSpline size={16} />
                {t('features.customerSegments.detail.tabIndexMetrics')}
              </TabsTrigger>
              <TabsTrigger value={TabIndex.orders} className='gap-2'>
                <Activity size={16} />
                {t('features.customerSegments.detail.tabIndexOrders')}
              </TabsTrigger>
              <TabsTrigger value={TabIndex.customers} className='gap-2'>
                <User size={16} />
                {t('features.customerSegments.detail.tabIndexCustomers')}
              </TabsTrigger>
              <TabsTrigger value={TabIndex.sync} className='gap-2'>
                <RefreshCcw size={16} />
                {t('features.customerSegments.detail.tabIndexSync')}
              </TabsTrigger>
            </TabsList>
            <Button variant='outline' size='sm' onClick={() => navigate(`${AUTHORIZED_ROUTE.INSIGHTS}?customer_segment_id=${customerSegment?.id}`)}>
              <Lightbulb />
              {t('features.customerSegments.detail.viewInsights')}
            </Button>
          </div>
          <TabsContent value={TabIndex.metrics}>
            <Metrics
              customerSegment={customerSegment}
              metricsHistory={metricsHistory}
              loading={loadingMetricsHistory}
              handleTimeRangeChange={(startDate, endDate) => {
                setMetricsStartDate(startDate)
                setMetricsEndDate(endDate)
              }}
            />
          </TabsContent>
          <TabsContent value={TabIndex.orders}>
            <Orders
              customerSegment={customerSegment}
              dailyOrders={dailyOrders}
              dailyOrdersLoading={loadingDailyOrders}
              orders={orders}
              ordersLoading={loadingOrders}
              handleOrdersTimeRangeChange={(startDate, endDate) => {
                setOrdersStartDate(startDate)
                setOrdersEndDate(endDate)
              }}
            />
          </TabsContent>
          <TabsContent value={TabIndex.customers}>
            <Customers customerSegment={customerSegment} customers={customers} loading={loadingCustomers} />
          </TabsContent>
          <TabsContent value={TabIndex.sync}>
            <Sync customerSegment={customerSegment} currentTagRunning={currentTagRunning} otherTagsRunning={otherTagsRunning} />
          </TabsContent>
        </Tabs>
      </PageContentWrapper>

      {customerSegment && (
        <SuggestInsightDialog
          open={suggestInsightDialogOpen}
          handleClose={() => setSuggestInsightDialogOpen(false)}
          segmentId={customerSegment.id}
          segmentName={customerSegment.name}
        />
      )}

      {detailDialogOpen && (
        <DetailDialog
          open={detailDialogOpen}
          handleClose={() => setDetailDialogOpen(false)}
          name={customerSegment?.name || ''}
          description={customerSegment?.description || ''}
          querySet={convertQuerySetToForm(convertStringToApiQuerySetState(customerSegment?.querySet || ''))}
        />
      )}
    </>
  )
}
