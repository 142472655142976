import { Box } from '@mui/material'
import { FC } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { CopyPlus, X } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { defaultQuerySubset } from './defalutValues'
import { QuerySubset } from './querySubset/querySubset'
import { QuerySetFormState } from './schema/querySetFormSchema'

export const QuerySetForm: FC = () => {
  const { t } = useTranslation()
  const { control } = useFormContext<QuerySetFormState>()
  const { fields, append, remove } = useFieldArray<QuerySetFormState>({
    control: control,
    name: 'query_subsets',
  })

  return (
    <>
      <Box className='flex flex-col gap-3'>
        {fields.map((field, querySubsetIndex) => (
          <div key={field.id} className='flex flex-col gap-3'>
            <Controller
              control={control}
              name={`join_operator`}
              render={({ field, fieldState: { error } }) => {
                return (
                  <div className={`max-w-[100px] ${querySubsetIndex === 0 ? 'hidden' : 'block'}`}>
                    <Select onValueChange={field.onChange} defaultValue='and' value={field.value}>
                      <SelectTrigger className={`w-full ${error ? 'border-red-500' : ''}`}>
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value='and'>{t('features.customerSegments.querySet.joinOperator', { context: 'and' })}</SelectItem>
                        <SelectItem value='or'>{t('features.customerSegments.querySet.joinOperator', { context: 'or' })}</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                )
              }}
            />
            <Card className='relative p-8 rounded-lg border bg-background'>
              <QuerySubset querySubsetPath={`query_subsets.${querySubsetIndex}`} />
              <Button
                variant='ghost'
                size='icon'
                className='absolute right-4 top-4 rounded-lg disabled:opacity-50'
                disabled={fields.length === 1}
                onClick={() => remove(querySubsetIndex)}
              >
                <X />
              </Button>
            </Card>
          </div>
        ))}
      </Box>
      <Button
        variant='secondary'
        size='sm'
        className='mt-3 flex items-center gap-2 disabled:opacity-50'
        disabled={fields.length >= 3}
        onClick={() => append(defaultQuerySubset)}
      >
        <CopyPlus />
        {t('features.customerSegments.querySet.querySetForm.addQuerySubset')}
      </Button>
    </>
  )
}
