import { SidebarInset, SidebarProvider } from '@/components/ui/sidebar'
import { FatalError } from 'features/error/fatalError'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { AppSidebar } from './components/appSidebar'
import { TrialBanner } from './components/trialBanner'
import { useAccount } from '@/hooks/useAccount'
import { timestampToDayjs } from '@/utils/timeUtil'
import dayjs from 'dayjs'
import { billingStatus } from '@/config/plan'

export const DashboardLayout = () => {
  const { t } = useTranslation()
  const { notifySentry } = useSentryNotifier()

  const { shop } = useAccount()
  const shopBillingStatus = billingStatus(shop, dayjs())

  const totalTrialDays = timestampToDayjs(shop.trialEndsAt).diff(timestampToDayjs(shop.createdAt), 'day')
  const elapsedTrialDays = dayjs().diff(timestampToDayjs(shop.createdAt), 'day')
  const remainingTrialDays = totalTrialDays - elapsedTrialDays

  return (
    <SidebarProvider>
      <AppSidebar />
      <SidebarInset>
        <ErrorBoundary
          FallbackComponent={() => <FatalError />}
          onError={(err, info) => notifySentry(err, info.componentStack)}
          resetKeys={[location.pathname]}
        >
          {shopBillingStatus === 'trial' && remainingTrialDays <= 7 && (
            <TrialBanner text={t('layouts.dashboardLayout.trialBannerText', { days: remainingTrialDays })} />
          )}
          <Outlet />
        </ErrorBoundary>
      </SidebarInset>
    </SidebarProvider>
  )
}
