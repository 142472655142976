import { SkeletonTable } from '@/components/skeletonTable'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'
import { Table, TableBody, TableCell, TableRow } from '@/components/ui/table'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { Skeleton, SwipeableDrawer } from '@mui/material'
import dayjs from 'dayjs'
import { OrderDrawer } from 'features/orders/orderDrawer'
import { getIdToken } from 'firebase/auth'
import { CustomerService } from 'gen/proto/customer/customer_pb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCurrency } from 'hooks/useCurrency'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useActionTracker } from 'hooks/useMixpanel'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { ChevronRight, X } from 'lucide-react'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatDate, formatDateTime } from 'utils/timeUtil'

type Props = {
  open: boolean
  handleOpen: () => void
  handleClose: () => void
  customer:
    | {
        id: string
        name: string
      }
    | undefined
}

type OrderDrawerState = {
  open: boolean
  order:
    | {
        id: string
        name: string
      }
    | undefined
}

enum TabIndex {
  profile = 'profile',
  orders = 'orders',
}

export type CustomerProfile = {
  email: string
  country: string
  province: string
  tags: string[]
  recency: number
  frequency: number
  monetary: number
}

export type CustomerOrder = {
  id: string
  name: string
  orderValue: number
  orderedAt: string
}

export const CustomerDrawer: FC<Props> = ({ open, handleOpen, handleClose, customer }) => {
  const { t, i18n } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const { formatCurrency } = useCurrency()
  const { dispatch } = useActionTracker()

  const customerService = useGrpcClient(CustomerService)

  const [tabIndex, setTabIndex] = useState<TabIndex>(TabIndex.profile)
  const [orderDrawerState, setOrderDrawerState] = useState<OrderDrawerState>({ open: false, order: undefined })

  const [customerProfile, setCustomerProfile] = useState<CustomerProfile>()
  const [customerOrders, setCustomerOrders] = useState<CustomerOrder[]>()

  useEffect(() => {
    if (!customer) {
      setCustomerProfile(undefined)
      return
    }
    const fetch = async () => {
      try {
        const token = await getIdToken(authUser!)
        const resp = await customerService.get({ id: customer.id }, { headers: { Authorization: `Bearer ${token}` } })
        setCustomerProfile({
          email: resp.email,
          country: resp.country,
          province: resp.province,
          tags: resp.tags.filter((str) => str !== ''),
          recency: resp.recency > 0 ? Number(resp.recency) : 0,
          frequency: Number(resp.frequency),
          monetary: Number(resp.monetary),
        })
      } catch (err) {
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      }
    }
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer])

  useEffect(() => {
    if (!customer) {
      setCustomerOrders(undefined)
      return
    }
    const fetch = async () => {
      try {
        const token = await getIdToken(authUser!)
        const resp = await customerService.getOrders({ id: customer.id }, { headers: { Authorization: `Bearer ${token}` } })
        const orders = resp.orders.map((o) => {
          return {
            id: o.id,
            name: o.name,
            orderValue: Number(o.orderValue),
            orderedAt: formatDateTime(dayjs(o.orderedAt), i18n.language),
          }
        })
        setCustomerOrders(orders)
      } catch (err) {
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      }
    }
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer])

  return (
    <>
      <SwipeableDrawer open={open} onOpen={handleOpen} onClose={handleClose} anchor='right'>
        <div className='w-[360px]'>
          <div className='flex justify-between items-center p-4'>
            <p className='text-sm font-bold'>{t('features.customers.customerDrawer.title')}</p>
            <Button variant='ghost' size='icon' onClick={handleClose}>
              <X />
            </Button>
          </div>

          <Separator />

          <div className='flex flex-col gap-4 p-4'>
            <Tabs value={tabIndex} onValueChange={(value) => setTabIndex(value as TabIndex)}>
              <TabsList className='w-full'>
                <TabsTrigger value={TabIndex.profile} className='w-full'>
                  {t('features.customers.customerDrawer.tabIndexProfile')}
                </TabsTrigger>
                <TabsTrigger value={TabIndex.orders} className='w-full'>
                  {t('features.customers.customerDrawer.tabIndexOrders')}
                </TabsTrigger>
              </TabsList>
            </Tabs>

            {tabIndex === TabIndex.profile && (
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>{t('features.customers.name')}</TableCell>
                    <TableCell className='text-right'>
                      {customer && customerProfile ? customer.name : <Skeleton className='w-[120px] ml-auto' />}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t('features.customers.email')}</TableCell>
                    <TableCell className='text-right'>
                      {customerProfile ? customerProfile.email : <Skeleton className='w-[120px] ml-auto' />}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t('features.customers.country')}</TableCell>
                    <TableCell className='text-right'>
                      {customerProfile ? customerProfile.country : <Skeleton className='w-[120px] ml-auto' />}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t('features.customers.province')}</TableCell>
                    <TableCell className='text-right'>
                      {customerProfile ? customerProfile.province : <Skeleton className='w-[120px] ml-auto' />}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t('features.customers.monetary')}</TableCell>
                    <TableCell className='text-right'>
                      {customerProfile ? formatCurrency(customerProfile.monetary) : <Skeleton className='w-[120px] ml-auto' />}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t('features.customers.frequency')}</TableCell>
                    <TableCell className='text-right'>
                      {customerProfile ? (
                        t('features.customers.customerDrawer.frequencyTimes', { count: customerProfile.frequency })
                      ) : (
                        <Skeleton className='w-[120px] ml-auto' />
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t('features.customers.recency')}</TableCell>
                    <TableCell className='text-right'>
                      {customerProfile ? (
                        t('features.customers.customerDrawer.recencyDays', { count: customerProfile.recency })
                      ) : (
                        <Skeleton className='w-[120px] ml-auto' />
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t('features.customers.tags')}</TableCell>
                    <TableCell className='text-right'>
                      {customerProfile ? (
                        customerProfile.tags.length === 0 ? (
                          t('features.customers.none')
                        ) : (
                          <div className='flex flex-wrap justify-end gap-2'>
                            {customerProfile.tags.map((tag) => (
                              <Badge key={tag} variant='outline'>
                                {tag}
                              </Badge>
                            ))}
                          </div>
                        )
                      ) : (
                        <Skeleton className='w-[120px] ml-auto' />
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )}

            {tabIndex === TabIndex.orders && (
              <Table>
                <TableBody>
                  {customerOrders ? (
                    customerOrders.map((order) => (
                      <TableRow key={order.id}>
                        <TableCell>
                          <Badge variant='outline'>{order.name}</Badge>
                        </TableCell>
                        <TableCell className='text-right'>{formatCurrency(order.orderValue)}</TableCell>
                        <TableCell className='text-right'>{formatDate(dayjs(order.orderedAt), i18n.language)}</TableCell>
                        <TableCell className='text-right'>
                          <Button
                            variant='ghost'
                            size='icon'
                            onClick={() => {
                              dispatch('OpenOrderDrawer', { orderId: order.id, orderName: order.name, referrer: 'customerDrawer' })
                              setOrderDrawerState({ open: true, order: { id: order.id, name: order.name } })
                            }}
                          >
                            <ChevronRight />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <SkeletonTable columnCount={3} rowCount={5} />
                  )}
                </TableBody>
              </Table>
            )}
          </div>
        </div>
      </SwipeableDrawer>

      {orderDrawerState.open && orderDrawerState.order && (
        <OrderDrawer
          open={orderDrawerState.open}
          handleOpen={() => setOrderDrawerState({ open: true, order: orderDrawerState.order })}
          handleClose={() => setOrderDrawerState({ open: false, order: undefined })}
          orderProps={orderDrawerState.order}
        />
      )}
    </>
  )
}
