import { Button } from '@/components/ui/button'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { List, ListItem, ListItemButton, ListItemText, Skeleton, SwipeableDrawer, Typography } from '@mui/material'

import { Separator } from '@/components/ui/separator'
import { MetricsItems } from 'components/metricsItems'
import { CustomerDrawer } from 'features/customers/customerDrawer'
import { convertFormToQuerySet } from 'features/customerSegments/querySetForm/schema/converter'
import { QuerySetFormState } from 'features/customerSegments/querySetForm/schema/querySetFormSchema'
import { getIdToken } from 'firebase/auth'
import { CustomerMetrics } from 'gen/firestore'
import { CustomerSegmentService } from 'gen/proto/customer_segment/customer_segment_pb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { UserPlus, X } from 'lucide-react'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'

type Props = {
  open: boolean
  handleOpen: () => void
  handleClose: () => void
  querySet: QuerySetFormState | undefined
}

type Customer = {
  id: string
  name: string
  email: string
}

type CustomerDrawerState = {
  open: boolean
  customer?: Customer
}

export const InsightDrawer: FC<Props> = ({ open, handleOpen, handleClose, querySet }) => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const customerSegmentService = useGrpcClient(CustomerSegmentService)

  const [metrics, setMetrics] = useState<CustomerMetrics | undefined>()
  const [customers, setCustomers] = useState<Customer[] | undefined>()
  const [customerDrawerState, setCustomerDrawerState] = useState<CustomerDrawerState>({ open: false })

  const [metricsLoading, setMetricsLoading] = useState(false)
  const [customersLoading, setCustomersLoading] = useState(false)

  useEffect(() => {
    if (!querySet) return
    const fetch = async () => {
      setMetricsLoading(true)
      try {
        const token = await getIdToken(authUser!)
        const resp = await customerSegmentService.estimateCustomerMetrics(
          { querySet: JSON.stringify(convertFormToQuerySet(querySet)) },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        if (!resp.result) return
        setMetrics({
          count: Number(resp.result.count),
          repeaterRate: resp.result.repeaterRate,
          ltv: Number(resp.result.ltv),
          avgOrderValue: Number(resp.result.avgOrderValue),
          avgOrderCount: resp.result.avgOrderCount,
          avgDaysBetweenOrders: resp.result.avgDaysBetweenOrders,
        })
      } catch (err) {
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      } finally {
        setMetricsLoading(false)
      }
    }
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [querySet])

  useEffect(() => {
    if (!querySet) return
    const fetch = async () => {
      setCustomersLoading(true)
      try {
        const token = await getIdToken(authUser!)
        const resp = await customerSegmentService.estimateCustomers(
          { querySet: JSON.stringify(convertFormToQuerySet(querySet)), limit: BigInt(10) }, // limit 10
          { headers: { Authorization: `Bearer ${token}` } }
        )
        setCustomers(
          resp.customers.map((customer) => ({
            id: customer.id,
            name: t('common.customer.fullName', { firstName: customer.firstName, lastName: customer.lastName }),
            email: customer.email,
          }))
        )
      } catch (err) {
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      } finally {
        setCustomersLoading(false)
      }
    }
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [querySet])

  return (
    <>
      <SwipeableDrawer open={open} onOpen={handleOpen} onClose={handleClose} anchor='right'>
        <div className='w-[360px]'>
          <div className='flex justify-between items-center p-4'>
            <p className='text-sm font-bold'>{t('features.customers.customerDrawer.title')}</p>
            <div className='flex items-center'>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant='ghost'
                    size='icon'
                    onClick={() => {
                      const querySetJsonString = JSON.stringify(convertFormToQuerySet(querySet as QuerySetFormState))
                      window.open(
                        `${AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_CREATE}?${createSearchParams({
                          querySet: encodeURIComponent(querySetJsonString),
                        }).toString()}`,
                        '_blank'
                      )
                    }}
                  >
                    <UserPlus />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>{t('features.insights.detail.insightDrawer.createSegment')}</TooltipContent>
              </Tooltip>
              <Button variant='ghost' size='icon' onClick={handleClose}>
                <X />
              </Button>
            </div>
          </div>

          <Separator />

          <div className='flex flex-col gap-4 p-4'>
            <div className='p-2'>
              <MetricsItems metrics={!metricsLoading ? metrics : undefined} type='list' />
            </div>
            <Separator />
            <List>
              {customersLoading
                ? [...Array(10)].map((_, index) => (
                    <ListItem key={index}>
                      <Skeleton width='100%' height='18px' />
                    </ListItem>
                  ))
                : customers &&
                  customers.map((customer, i) => (
                    <ListItem
                      key={i}
                      onClick={() =>
                        setCustomerDrawerState({
                          open: true,
                          customer: { id: customer.id, name: customer.name, email: customer.email },
                        })
                      }
                      dense
                      disableGutters
                    >
                      <ListItemButton dense disableGutters sx={{ paddingX: '8px', borderRadius: '4px' }}>
                        <ListItemText primary={<Typography variant='body2'>{customer.name}</Typography>} />
                        <ArrowForwardIosIcon fontSize='small' sx={{ fontSize: '12px' }} />
                      </ListItemButton>
                    </ListItem>
                  ))}
            </List>
          </div>
        </div>
      </SwipeableDrawer>

      <CustomerDrawer
        open={customerDrawerState.open}
        handleOpen={() => setCustomerDrawerState({ open: true, customer: customerDrawerState.customer })}
        handleClose={() => setCustomerDrawerState({ open: false })}
        customer={customerDrawerState.customer}
      />
    </>
  )
}
