import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { cn } from '@/lib/utils'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { useTranslation } from 'react-i18next'
import { OP_INT_RANGE, OP_NONE } from '../schema/const'
import { QuerySetFormState } from '../schema/querySetFormSchema'
import { IntRangeValue } from '../value/intRangeValue'
import { FilterItemProps } from './type'

export const OrderCountFilter: FC<FilterItemProps> = ({ filterPath }) => {
  const { t } = useTranslation()
  const { register, control, watch } = useFormContext<QuerySetFormState>()
  const operator = watch(`${filterPath}.operator`)

  return (
    <div className='flex items-center gap-3'>
      <div className='text-sm'>{t('features.customerSegments.querySet.filterType', { context: 'order_count' })}</div>
      <input type='hidden' value='order_count' {...register(`${filterPath}.filter_type`)} />
      <Controller
        control={control}
        name={`${filterPath}.operator`}
        render={({ field, fieldState: { error } }) => {
          return (
            <div className={cn('min-w-[120px]', error && 'border-red-500')}>
              <Select value={field.value} onValueChange={field.onChange}>
                <SelectTrigger className='w-full'>
                  <SelectValue placeholder={t('features.customerSegments.querySet.querySetForm.op_order_count', { context: field.value })} />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value={OP_NONE}>
                      {t('features.customerSegments.querySet.querySetForm.op_order_count', { context: OP_NONE })}
                    </SelectItem>
                    <SelectItem value={OP_INT_RANGE}>{t('features.customerSegments.querySet.querySetForm.op', { context: OP_INT_RANGE })}</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          )
        }}
      />

      {operator === OP_INT_RANGE && (
        <IntRangeValue
          minValuePath={`${filterPath}.value.min`}
          maxValuePath={`${filterPath}.value.max`}
          inputAdornmentString={t('features.customerSegments.querySet.querySetForm.suffixCount')}
          inputPropsMaxValue={99}
        />
      )}
    </div>
  )
}
