import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Separator } from '@/components/ui/separator'
import { Skeleton } from '@/components/ui/skeleton'
import { Table, TableBody, TableCell, TableRow } from '@/components/ui/table'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { ClickAwayListener, Typography } from '@mui/material'
import { getIdToken } from 'firebase/auth'
import { Account, Language } from 'gen/firestore'
import { AccountService } from 'gen/proto/account/account_pb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { Pencil } from 'lucide-react'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ResetPasswordDialog } from './components/resetPasswordDialog'
import { UpdateEmailDialog } from './components/updateEmailDialog'

type Props = {
  account: Account
}

export const MyAccount: FC<Props> = ({ account }) => {
  const { t, i18n } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const accountService = useGrpcClient(AccountService)

  const [updateEmailDialogOpen, setUpdateEmailDialogOpen] = useState(false)
  const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = useState(false)
  const [changeLanguageFormOpen, setChangeLanguageFormOpen] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeLanguage = async (lng: any) => {
    try {
      const token = await getIdToken(authUser!)
      await accountService.updateLanguage({ language: lng }, { headers: { Authorization: `Bearer ${token}` } })
      i18n.changeLanguage(lng)
      enqueueSnackbar(t('features.settings.myAccount.messageLanguageSuccess'), { severity: 'success' })
    } catch (err) {
      enqueueSnackbar(t('features.settings.myAccount.messageLanguageError'), { severity: 'error' })
      notifySentry(err)
    } finally {
      setChangeLanguageFormOpen(false)
    }
  }

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>{t('features.settings.myAccount.profile')}</CardTitle>
        </CardHeader>
        <CardContent>
          <Table>
            <TableBody>
              <TableRow className='border-none'>
                <TableCell className='w-[180px]'>{t('features.settings.myAccount.email')}</TableCell>
                <TableCell>
                  <div className='flex items-center gap-4'>
                    {account.email || <Skeleton className='w-[120px]' />}
                    <Tooltip>
                      <TooltipTrigger>
                        <Pencil className='w-3 h-3 cursor-pointer hover:text-muted-foreground' onClick={() => setUpdateEmailDialogOpen(true)} />
                      </TooltipTrigger>
                      <TooltipContent>{t('features.settings.edit')}</TooltipContent>
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
              <TableRow className='border-none'>
                <TableCell className='w-[180px]'>{t('features.settings.myAccount.password')}</TableCell>
                <TableCell>
                  <div className='flex items-center gap-4'>
                    <Typography fontSize='14px'>•••••••••••</Typography>
                    <Tooltip>
                      <TooltipTrigger>
                        <Pencil className='w-3 h-3 cursor-pointer hover:text-muted-foreground' onClick={() => setResetPasswordDialogOpen(true)} />
                      </TooltipTrigger>
                      <TooltipContent>{t('features.settings.edit')}</TooltipContent>
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>

        <Separator />

        <CardHeader>
          <CardTitle>{t('features.settings.myAccount.appearance')}</CardTitle>
        </CardHeader>
        <CardContent>
          <Table>
            <TableBody>
              <TableRow className='border-none'>
                <TableCell className='w-[180px]'>{t('features.settings.myAccount.language')}</TableCell>
                <TableCell>
                  {!changeLanguageFormOpen ? (
                    <div className='flex items-center gap-4'>
                      {t('features.settings.myAccount.language', { context: account.language }) || <Skeleton className='w-[120px]' />}
                      <Tooltip>
                        <TooltipTrigger>
                          <Pencil className='w-3 h-3 cursor-pointer hover:text-muted-foreground' onClick={() => setChangeLanguageFormOpen(true)} />
                        </TooltipTrigger>
                        <TooltipContent>{t('features.settings.edit')}</TooltipContent>
                      </Tooltip>
                    </div>
                  ) : (
                    <ClickAwayListener onClickAway={() => setChangeLanguageFormOpen(false)} mouseEvent='onMouseDown'>
                      <Select value={account.language} onValueChange={(value) => handleChangeLanguage(value as Language)}>
                        <SelectTrigger className='w-[180px]'>
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value='en'>{t('features.settings.myAccount.language_en')}</SelectItem>
                          <SelectItem value='ja'>{t('features.settings.myAccount.language_ja')}</SelectItem>
                        </SelectContent>
                      </Select>
                    </ClickAwayListener>
                  )}
                </TableCell>
              </TableRow>
              <TableRow className='border-none'>
                <TableCell className='w-[180px]'>{t('features.settings.myAccount.displayMode')}</TableCell>
                <TableCell>{account.displayDarkMode ? t('features.settings.myAccount.dark') : t('features.settings.myAccount.light')}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>

      {updateEmailDialogOpen && (
        <UpdateEmailDialog open={updateEmailDialogOpen} account={account} handleClose={() => setUpdateEmailDialogOpen(false)} />
      )}

      {resetPasswordDialogOpen && (
        <ResetPasswordDialog open={resetPasswordDialogOpen} account={account} handleClose={() => setResetPasswordDialogOpen(false)} />
      )}
    </>
  )
}
