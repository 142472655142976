import { Button } from '@/components/ui/button'
import { Card, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card'
import { cn } from '@/lib/utils'
import { planConfig } from 'config/plan'
import { BillingPlanIdentifier } from 'gen/firestore'
import { CircleCheckBig, Loader2 } from 'lucide-react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  plan: BillingPlanIdentifier
  selected: boolean
  selectable: boolean
  onClick: () => Promise<void>
  submitting: boolean
}

export const PlanItem: FC<Props> = ({ plan, selected, selectable, onClick, submitting }) => {
  const { t } = useTranslation()

  return (
    <Card className={cn('relative', { 'bg-blue-50': selected })}>
      <CardHeader>
        <CardTitle className='flex flex-col gap-1'>
          <div>{t('features.settings.plan.name', { context: plan })}</div>
          {plan === BillingPlanIdentifier.enterprise ? (
            <div className='text-lg font-bold'>{t('features.settings.plan.contactSupport')}</div>
          ) : (
            <div className='flex items-end gap-2'>
              <div className='text-xl font-bold'>${planConfig[plan].price}</div>
              <div className='text-sm font-bold mb-[2px]'>{t('features.settings.plan.perMonth')}</div>
            </div>
          )}
        </CardTitle>
        <CardDescription className='text-xs'>{t('features.settings.plan.description', { context: plan })}</CardDescription>
      </CardHeader>
      <CardFooter>
        <Button
          disabled={selected || !selectable}
          variant={selected ? 'outline' : selectable ? 'default' : 'secondary'}
          onClick={onClick}
          className='w-full'
        >
          {submitting ? (
            <Loader2 className='animate-spin' />
          ) : selected ? (
            t('features.settings.plan.currentPlan')
          ) : (
            t('features.settings.plan.choosePlan', { context: plan })
          )}
        </Button>
      </CardFooter>

      {selected && <CircleCheckBig className='absolute top-4 right-4 w-4 h-4' />}
    </Card>
  )
}
