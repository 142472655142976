import { DateRangePicker, DateRangePresetOption } from '@/components/dateRangePicker'
import { PageContentWrapper } from '@/components/pageContentWrapper'
import { PageHeader } from '@/components/pageHeader'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Table, TableBody, TableCell, TableRow } from '@/components/ui/table'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { GetPopularityReportResponse_InsightValue } from '@/gen/proto/insight/insight_pb'
import { AUTHORIZED_ROUTE } from '@/routing'
import { timestampToDayjs } from '@/utils/timeUtil'
import dayjs from 'dayjs'
import { getIdToken } from 'firebase/auth'
import { orderBy, query } from 'firebase/firestore'
import { InsightDimension } from 'gen/firestore'
import { InsightService } from 'gen/proto/insight/insight_pb'
import { useAccount } from 'hooks/useAccount'
import { useAuthUser } from 'hooks/useAuthUser'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useCollection } from 'hooks/useFirestoreData'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useActionTracker } from 'hooks/useMixpanel'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { CalendarRange, FileChartLine, Rows3, ShoppingBasket, User } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useLocation, useNavigate } from 'react-router-dom'
import { InsightCategoryValues } from './components/insightCategoryValues'
import { InsightNumericValues } from './components/insightNumericValues'
import { addIndexToName } from './types/insight'
import { insightDimensions } from './types/insightDimensions'

export type InsightValue = {
  name: string
  customerCount: number
  ratio: number
}

enum InsightType {
  CUSTOMER = 'customer',
  PRODUCT = 'product',
  COHORT = 'cohort',
}

enum ViewType {
  GRAPH = 'graph',
  LIST = 'list',
}

export const Insights = () => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const insightService = useGrpcClient(InsightService)
  const navigate = useNavigate()
  const { dispatch } = useActionTracker()

  const { account, shop } = useAccount()
  const { customerSegmentsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data: customerSegments } = useCollection(query(customerSegmentsRef, orderBy('createdAt', 'desc')))

  // TODO: Validate segment id
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const [customerSegmentId, setCustomerSegmentId] = useState<string>(params.get('customer_segment_id') || '')

  const baseEndDate = shop.lastDatasetEtlCompletedAt ? timestampToDayjs(shop.lastDatasetEtlCompletedAt) : dayjs()
  const defaultStartDate = baseEndDate.subtract(364, 'days').tz(shop.timezone).format('YYYY-MM-DD') // 1 year ago
  const defaultEndDate = baseEndDate.tz(shop.timezone).format('YYYY-MM-DD')
  const defaultCustomDateRange = DateRangePresetOption.last365Days
  const [startDate, setStartDate] = useState<string>(defaultStartDate)
  const [endDate, setEndDate] = useState<string>(defaultEndDate)

  const [viewType, setViewType] = useState<ViewType>(ViewType.GRAPH)

  const handleCustomerSegmentChange = (value: string | null) => {
    const selected = customerSegments && customerSegments.find((cs) => cs.name === value)
    const newSegmentId = selected?.ref.id || ''
    setCustomerSegmentId(newSegmentId)
    if (newSegmentId) {
      navigate(`?customer_segment_id=${newSegmentId}`)
    } else {
      navigate(location.pathname)
    }
  }

  const handleTabChange = (newInsightType: string) => {
    if (newInsightType === InsightType.PRODUCT) {
      navigate(AUTHORIZED_ROUTE.PRODUCT_INSIGHTS)
    } else if (newInsightType === InsightType.COHORT) {
      navigate(AUTHORIZED_ROUTE.COHORTS_MONTHLY + (customerSegmentId ? `?customer_segment_id=${customerSegmentId}` : ''))
    }
  }

  // Product
  const [productInsightValues, setProductInsightValues] = useState<InsightValue[] | undefined>(undefined)
  const [productInsightLoading, setProductInsightLoading] = useState(false)
  const [productInsightDimension, setProductInsightDimension] = useState<InsightDimension>(InsightDimension.product)
  const productInsightDimensions: InsightDimension[] = [
    InsightDimension.product,
    InsightDimension.productType,
    InsightDimension.productVendor,
    InsightDimension.productTag,
    InsightDimension.productVariant,
  ]
  useEffect(() => {
    fetchCategoryData(productInsightDimension, setProductInsightValues, setProductInsightLoading, 3)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSegmentId, productInsightDimension, startDate, endDate])

  // Source
  const [sourceInsightValues, setSourceInsightValues] = useState<InsightValue[] | undefined>(undefined)
  const [sourceInsightLoading, setSourceInsightLoading] = useState(false)
  const [sourceInsightDimension, setSourceInsightDimension] = useState<InsightDimension>(InsightDimension.referrer)
  const sourceInsightDimensions: InsightDimension[] = [
    InsightDimension.referrer,
    InsightDimension.utmSource,
    InsightDimension.utmMedium,
    InsightDimension.utmCampaign,
  ]
  useEffect(() => {
    fetchCategoryData(sourceInsightDimension, setSourceInsightValues, setSourceInsightLoading, 3)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSegmentId, sourceInsightDimension, startDate, endDate])

  // Order
  const [orderInsightValues, setOrderInsightValues] = useState<InsightValue[] | undefined>(undefined)
  const [orderInsightLoading, setOrderInsightLoading] = useState(false)
  const [orderInsightDimension, setOrderInsightDimension] = useState<InsightDimension>(InsightDimension.orderTag)
  const orderInsightDimensions: InsightDimension[] = [InsightDimension.orderTag, InsightDimension.channel, InsightDimension.coupon]
  useEffect(() => {
    fetchCategoryData(orderInsightDimension, setOrderInsightValues, setOrderInsightLoading, 3)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSegmentId, orderInsightDimension, startDate, endDate])

  // Customer
  const [customerInsightValues, setCustomerInsightValues] = useState<InsightValue[] | undefined>(undefined)
  const [customerInsightLoading, setCustomerInsightLoading] = useState(false)
  const [customerInsightDimension, setCustomerInsightDimension] = useState<InsightDimension>(InsightDimension.customerTag)
  const customerInsightDimensions: InsightDimension[] = [InsightDimension.customerTag, InsightDimension.country, InsightDimension.province]
  useEffect(() => {
    fetchCategoryData(customerInsightDimension, setCustomerInsightValues, setCustomerInsightLoading, 3)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSegmentId, customerInsightDimension, startDate, endDate])

  // Order Day & Hour
  const [orderDayHourInsightValues, setOrderDayHourInsightValues] = useState<InsightValue[] | undefined>(undefined)
  const [orderDayHourInsightLoading, setOrderDayHourInsightLoading] = useState(false)
  const [orderDayHourDimension, setOrderDayHourDimension] = useState<InsightDimension>(InsightDimension.weekday)
  const orderDayHourDimensions: InsightDimension[] = [InsightDimension.weekday, InsightDimension.hour]
  useEffect(() => {
    fetchCategoryData(orderDayHourDimension, setOrderDayHourInsightValues, setOrderDayHourInsightLoading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSegmentId, orderDayHourDimension, startDate, endDate])

  // Histogram
  const [histogramInsightValues, setHistogramInsightValues] = useState<InsightValue[] | undefined>(undefined)
  const [histogramInsightLoading, setHistogramInsightLoading] = useState(false)
  const [histogramInsightDimension, setHistogramInsightDimension] = useState<InsightDimension>(InsightDimension.intervalDays)
  const histogramInsightDimensions: InsightDimension[] = [
    InsightDimension.intervalDays,
    InsightDimension.lifetimeDays,
    InsightDimension.aov,
    InsightDimension.frequency,
    InsightDimension.recency,
    InsightDimension.monetary,
  ]
  useEffect(() => {
    fetchHistogramData(histogramInsightDimension, setHistogramInsightValues, setHistogramInsightLoading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSegmentId, histogramInsightDimension, startDate, endDate])

  const sortOrderWeekdayInsightValues = (values: GetPopularityReportResponse_InsightValue[]): InsightValue[] => {
    if (values.length === 0) return []
    const weekdayOrder = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    return weekdayOrder.map((day) => {
      const value = values.find((v) => v.name === day)
      return {
        name: t(`common.dayOfWeek.${day.toLowerCase()}`),
        customerCount: value ? Number(value.customerCount) : 0,
        ratio: value ? Number((value.ratio * 100).toFixed(1)) : 0,
      }
    })
  }

  const sortOrderHourInsightValues = (values: GetPopularityReportResponse_InsightValue[]): InsightValue[] => {
    if (values.length === 0) return []
    const hourOrder = [
      '12 AM',
      '1 AM',
      '2 AM',
      '3 AM',
      '4 AM',
      '5 AM',
      '6 AM',
      '7 AM',
      '8 AM',
      '9 AM',
      '10 AM',
      '11 AM',
      '12 PM',
      '1 PM',
      '2 PM',
      '3 PM',
      '4 PM',
      '5 PM',
      '6 PM',
      '7 PM',
      '8 PM',
      '9 PM',
      '10 PM',
      '11 PM',
    ]
    return hourOrder.map((hour) => {
      const value = values.find((v) => v.name === hour)
      return {
        name: hour,
        customerCount: value ? Number(value.customerCount) : 0,
        ratio: value ? Number((value.ratio * 100).toFixed(1)) : 0,
      }
    })
  }

  const fetchCategoryData = async (
    dimension: InsightDimension,
    setValues: React.Dispatch<React.SetStateAction<InsightValue[] | undefined>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    limit?: number
  ) => {
    setLoading(true)
    try {
      const token = await getIdToken(authUser!)
      const { values } = await insightService.getPopularityReport(
        { dimension, customerSegmentId, startDate, endDate },
        { headers: { Authorization: `Bearer ${token}` } }
      )

      if (dimension === InsightDimension.weekday) {
        setValues(sortOrderWeekdayInsightValues(values))
      } else if (dimension === InsightDimension.hour) {
        setValues(sortOrderHourInsightValues(values))
      } else {
        setValues(
          values.slice(0, limit).map((v, i) => ({
            name: addIndexToName(v.name, i + 1),
            customerCount: Number(v.customerCount),
            ratio: Number((v.ratio * 100).toFixed(1)),
          }))
        )
      }
    } catch (err) {
      enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
      notifySentry(err)
    } finally {
      setLoading(false)
    }
  }

  const fetchHistogramData = async (
    dimension: InsightDimension,
    setValues: React.Dispatch<React.SetStateAction<InsightValue[] | undefined>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setLoading(true)
    try {
      const token = await getIdToken(authUser!)
      const { values } = await insightService.getHistogramReport(
        { dimension, customerSegmentId, startDate, endDate },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      setValues(
        values.map((v) => ({
          name: v.min === v.max ? `${v.min}` : `${v.min} - ${v.max}`,
          customerCount: Number(v.customerCount),
          ratio: 0,
        }))
      )
    } catch (err) {
      enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
      notifySentry(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <PageHeader
        title={t('features.insights.title')}
        menuComponent={
          <Tabs defaultValue={InsightType.CUSTOMER} onValueChange={handleTabChange}>
            <TabsList>
              <TabsTrigger value={InsightType.CUSTOMER} className='gap-2'>
                <User size={16} />
                {t('features.insights.insightType_customer')}
              </TabsTrigger>
              <TabsTrigger value={InsightType.PRODUCT} className='gap-2'>
                <ShoppingBasket size={16} />
                {t('features.insights.insightType_product')}
              </TabsTrigger>
              <TabsTrigger value={InsightType.COHORT} className='gap-2'>
                <CalendarRange size={16} />
                {t('features.insights.insightType_cohort')}
              </TabsTrigger>
            </TabsList>
          </Tabs>
        }
      />

      <PageContentWrapper>
        <div className='flex items-center justify-between mb-6'>
          <div className='flex items-center gap-2'>
            <Select
              value={customerSegmentId}
              onValueChange={(value) => {
                const selected = customerSegments?.find((cs) => cs.ref.id === value)
                dispatch('FilterInsightBySegment', { name: selected?.name || null })
                handleCustomerSegmentChange(selected?.name || null)
              }}
              disabled={!customerSegments}
            >
              <SelectTrigger className='w-[280px]'>
                <SelectValue placeholder={t('features.insights.segment_all')} />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>{t('features.insights.segment')}</SelectLabel>
                  {customerSegments?.map((segment) => (
                    <SelectItem key={segment.ref.id} value={segment.ref.id}>
                      {segment.name}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
            <DateRangePicker
              dateRangePresetOption={defaultCustomDateRange}
              maxEndDate={baseEndDate}
              timezone={shop.timezone}
              onApplyDateRangeChange={(dateRange) => {
                setStartDate(dateRange.startDate.format('YYYY-MM-DD'))
                setEndDate(dateRange.endDate.format('YYYY-MM-DD'))
              }}
            />
          </div>

          <Tabs defaultValue={ViewType.GRAPH} onValueChange={(value) => setViewType(value as ViewType)}>
            <TabsList className='px-2'>
              <TabsTrigger value={ViewType.GRAPH} onClick={() => setViewType(ViewType.GRAPH)}>
                <FileChartLine size={16} />
              </TabsTrigger>
              <TabsTrigger value={ViewType.LIST} onClick={() => setViewType(ViewType.LIST)}>
                <Rows3 size={16} />
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </div>
        {viewType === ViewType.GRAPH && (
          <div className='grid grid-cols-1 sm:grid-cols-2 items-stretch gap-6'>
            <InsightCategoryValues
              title={t('features.insights.category_product')}
              customerSegmentId={customerSegmentId}
              insightDimension={productInsightDimension}
              insightValues={productInsightValues}
              loading={productInsightLoading}
              insightDimensions={productInsightDimensions}
              setInsightDimension={setProductInsightDimension}
            />
            <InsightCategoryValues
              title={t('features.insights.category_source')}
              customerSegmentId={customerSegmentId}
              insightDimension={sourceInsightDimension}
              insightValues={sourceInsightValues}
              loading={sourceInsightLoading}
              insightDimensions={sourceInsightDimensions}
              setInsightDimension={setSourceInsightDimension}
            />
            <InsightCategoryValues
              title={t('features.insights.category_order')}
              customerSegmentId={customerSegmentId}
              insightDimension={orderInsightDimension}
              insightValues={orderInsightValues}
              loading={orderInsightLoading}
              insightDimensions={orderInsightDimensions}
              setInsightDimension={setOrderInsightDimension}
            />
            <InsightCategoryValues
              title={t('features.insights.category_customer')}
              customerSegmentId={customerSegmentId}
              insightDimension={customerInsightDimension}
              insightValues={customerInsightValues}
              loading={customerInsightLoading}
              insightDimensions={customerInsightDimensions}
              setInsightDimension={setCustomerInsightDimension}
            />
            <InsightNumericValues
              title={t('features.insights.category_datetime')}
              customerSegmentId={customerSegmentId}
              insightDimension={orderDayHourDimension}
              insightValues={orderDayHourInsightValues}
              loading={orderDayHourInsightLoading}
              insightDimensions={orderDayHourDimensions}
              setInsightDimension={setOrderDayHourDimension}
            />
            <InsightNumericValues
              title={t('features.insights.category_histogram')}
              customerSegmentId={customerSegmentId}
              insightDimension={histogramInsightDimension}
              insightValues={histogramInsightValues}
              loading={histogramInsightLoading}
              insightDimensions={histogramInsightDimensions}
              setInsightDimension={setHistogramInsightDimension}
            />
          </div>
        )}

        {viewType === ViewType.LIST && (
          <Card>
            <CardHeader>
              <CardTitle>{t('features.insights.insight_all')}</CardTitle>
            </CardHeader>
            <CardContent>
              <Table>
                <TableBody>
                  {insightDimensions.map((dimension) => (
                    <TableRow
                      key={dimension.name}
                      className='cursor-pointer border-none group [&>*]:py-3'
                      onClick={() => {
                        navigate(
                          generatePath(AUTHORIZED_ROUTE.INSIGHT_DETAIL, {
                            id: dimension.name,
                          }) + (customerSegmentId ? `?customer_segment_id=${customerSegmentId}` : '')
                        )
                      }}
                    >
                      <TableCell className='w-[240px]'>{t('features.insights.dimension', { context: dimension.name })}</TableCell>
                      <TableCell className='text-muted-foreground'>
                        {t(`features.insights.questions.${dimension.name}_${dimension.reportTypes[0]}`)}
                      </TableCell>
                      <TableCell className='text-right text-xs text-muted-foreground group-hover:visible invisible'>
                        {t('features.insights.viewMore')}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        )}
      </PageContentWrapper>
    </>
  )
}
