import { DateRangePicker, DateRangePresetOption } from '@/components/dateRangePicker'
import { SkeletonTable } from '@/components/skeletonTable'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { ChartContainer, ChartTooltip, ChartTooltipContent } from '@/components/ui/chart'
import { Skeleton } from '@/components/ui/skeleton'
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { OrderDrawer } from '@/features/orders/orderDrawer'
import { useAccount } from '@/hooks/useAccount'
import { useActionTracker } from '@/hooks/useMixpanel'
import { EmptyState } from 'components/emptyState'
import dayjs from 'dayjs'
import { CustomerSegmentState } from 'features/customerSegments/types/types'
import { useCsvDownload } from 'hooks/useCsvDownload'
import { useCurrency } from 'hooks/useCurrency'
import { Download, FileSearch, Info, LoaderCircle } from 'lucide-react'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Area, AreaChart, CartesianGrid, XAxis, YAxis } from 'recharts'
import { formatDate, formatDateTime, timestampToDayjs } from 'utils/timeUtil'
import { useOrdersDownload } from '../hooks/useOrdersDownload'
import { DailyOrderSummary, OrderState } from '../types/types'

type Props = {
  customerSegment: CustomerSegmentState | undefined
  dailyOrders: DailyOrderSummary[] | undefined
  dailyOrdersLoading: boolean
  orders: OrderState[] | undefined
  ordersLoading: boolean
  handleOrdersTimeRangeChange: (startDate: string, endDate: string) => void
}

type OrderDrawerState = {
  open: boolean
  order:
    | {
        id: string
        name: string
      }
    | undefined
}

export const Orders: FC<Props> = ({ customerSegment, dailyOrders, dailyOrdersLoading, orders, ordersLoading, handleOrdersTimeRangeChange }) => {
  const { t, i18n } = useTranslation()
  const { formatCurrency } = useCurrency()
  const { shop } = useAccount()
  const { dispatch } = useActionTracker()

  const [orderStartDate, setOrderStartDate] = useState(dayjs().subtract(7, 'day').format('YYYY-MM-DD'))
  const [orderEndDate, setOrderEndDate] = useState(dayjs().format('YYYY-MM-DD'))

  const [orderDrawerState, setOrderDrawerState] = useState<OrderDrawerState>({ open: false, order: undefined })

  const ordersChartData = useMemo(() => {
    if (!dailyOrders) return []
    return dailyOrders.map((item) => ({
      date: item.date,
      revenue: item.orderAmount,
    }))
  }, [dailyOrders])

  const ordersChartConfig = {
    revenue: {
      label: t('features.customerSegments.detail.orders.orderAmount'),
      color: 'hsl(var(--chart-1))',
    },
  }

  const { handleDownload: handleDownloadOrders, loading: downloadOrdersLoading } = useOrdersDownload({
    customerSegmentId: customerSegment?.id,
    startDate: orderStartDate,
    endDate: orderEndDate,
    fileName: t('features.customerSegments.detail.orders.downloadFileName_history', {
      name: customerSegment?.name,
      date: formatDate(dayjs(), i18n.language),
    }),
    page: 'customerSegment_dailyOrdersSummary',
  })

  const { handleDownload: handleDownloadDailyOrdersSummary, loading: downloadDailyOrdersSummaryLoading } = useCsvDownload({
    data: dailyOrders?.map((o) => ({
      date: o.date,
      orderAmount: o.orderAmount,
      orderCount: o.orderCount,
    })),
    headers: [
      t('features.customerSegments.detail.orders.date'),
      t('features.customerSegments.detail.orders.orderAmount'),
      t('features.customerSegments.detail.orders.orderCount'),
    ],
    fileName: t('features.customerSegments.detail.orders.downloadFileName_summary', {
      name: customerSegment?.name,
      date: formatDate(dayjs(), i18n.language),
    }),
    page: 'customerSegment_dailyOrdersSummary',
  })

  const baseEndDate = shop.lastDatasetEtlCompletedAt ? timestampToDayjs(shop.lastDatasetEtlCompletedAt) : dayjs()

  return (
    <>
      <div className='flex flex-col gap-4'>
        <div className='flex items-center justify-between'>
          <DateRangePicker
            dateRangePresetOption={DateRangePresetOption.last7Days}
            maxEndDate={baseEndDate}
            timezone={shop.timezone}
            onApplyDateRangeChange={(dateRange) => {
              handleOrdersTimeRangeChange(dayjs(dateRange.startDate).format('YYYY-MM-DD'), dayjs(dateRange.endDate).format('YYYY-MM-DD'))
              setOrderStartDate(dayjs(dateRange.startDate).format('YYYY-MM-DD'))
              setOrderEndDate(dayjs(dateRange.endDate).format('YYYY-MM-DD'))
              dispatch('ChangeSegmentOrdersTimeRange', {
                startDate: dayjs(dateRange.startDate).format('YYYY-MM-DD'),
                endDate: dayjs(dateRange.endDate).format('YYYY-MM-DD'),
              })
            }}
          />
        </div>

        {dailyOrders && dailyOrders.length === 1 && (
          <Card className='p-6'>
            <div className='flex items-center gap-2'>
              <Info className='h-4 w-4' />
              <p className='text-sm'>{t('features.customerSegments.detail.orders.alert_summary')}</p>
            </div>
          </Card>
        )}

        <Card>
          <CardHeader>
            <div className='flex items-center justify-between'>
              <div className='flex flex-col gap-1'>
                <CardTitle>{t('features.customerSegments.detail.orders.title_summary')}</CardTitle>
                <CardDescription>
                  {dailyOrdersLoading ? (
                    <Skeleton className='w-[240px] h-3' />
                  ) : (
                    <span className='text-sm text-muted-foreground'>
                      {t('features.customerSegments.detail.orders.totalRevenueAndCount', {
                        revenue: formatCurrency(dailyOrders?.reduce((total, order) => total + order.orderAmount, 0) || 0),
                        count: dailyOrders?.reduce((total, order) => total + order.orderCount, 0) || 0,
                      })}
                    </span>
                  )}
                </CardDescription>
              </div>
              <div className='flex items-center gap-2'>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant='ghost'
                      size='icon'
                      disabled={!orders || downloadDailyOrdersSummaryLoading}
                      onClick={handleDownloadDailyOrdersSummary}
                    >
                      <Download />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>{t('features.customerSegments.detail.metrics.download')}</TooltipContent>
                </Tooltip>
              </div>
            </div>
          </CardHeader>
          <CardContent>
            {dailyOrdersLoading || !dailyOrders ? (
              <div className='flex justify-center items-center h-[320px]'>
                <LoaderCircle className='animate-spin' />
              </div>
            ) : dailyOrders.length === 0 ? (
              <EmptyState title={t('features.customerSegments.detail.orders.empty')} />
            ) : (
              <ChartContainer config={ordersChartConfig} className='h-[320px] w-full'>
                <AreaChart accessibilityLayer data={ordersChartData} margin={{ right: 24 }}>
                  <CartesianGrid vertical={false} />
                  <XAxis dataKey='date' tickLine={false} axisLine={false} />
                  <YAxis dataKey='revenue' tickLine={false} axisLine={false} />
                  <ChartTooltip cursor={false} content={<ChartTooltipContent indicator='line' />} />
                  <Area dataKey='revenue' type='natural' fill='var(--color-revenue)' fillOpacity={0.4} stroke='var(--color-revenue)' />
                </AreaChart>
              </ChartContainer>
            )}
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <div className='flex items-center justify-between'>
              <CardTitle>{t('features.customerSegments.detail.orders.title_history')}</CardTitle>
              <div className='flex items-center gap-2'>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant='ghost'
                      size='icon'
                      disabled={!orders || orders.length === 0 || downloadOrdersLoading}
                      onClick={handleDownloadOrders}
                    >
                      {downloadOrdersLoading ? <LoaderCircle className='animate-spin' /> : <Download />}
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>{t('features.customerSegments.detail.metrics.download')}</TooltipContent>
                </Tooltip>
              </div>
            </div>
          </CardHeader>
          <CardContent>
            {ordersLoading || !orders ? (
              <SkeletonTable columnCount={10} rowCount={10} />
            ) : orders.length === 0 ? (
              <div className='flex justify-center items-center h-[320px]'>
                <EmptyState title={t('features.customerSegments.detail.orders.empty')} />
              </div>
            ) : (
              <Table>
                {orders.length === 100 && <TableCaption>{t('features.customerSegments.detail.orders.alert_history')}</TableCaption>}
                <TableHeader>
                  <TableRow>
                    <TableHead>{t('features.orders.name')}</TableHead>
                    <TableHead>{t('features.orders.amount')}</TableHead>
                    <TableHead>{t('features.orders.channel')}</TableHead>
                    <TableHead className='w-[120px]'>{t('features.orders.referrer')}</TableHead>
                    <TableHead>{t('features.orders.coupon')}</TableHead>
                    <TableHead>{t('features.orders.datetime')}</TableHead>
                    <TableHead />
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {orders.map((order) => (
                    <TableRow key={order.id}>
                      <TableCell>{order.name}</TableCell>
                      <TableCell>{formatCurrency(order.amount)}</TableCell>
                      <TableCell>
                        <Badge variant='outline'>{order.channel}</Badge>
                      </TableCell>
                      <TableCell>{order.referrer || '-'}</TableCell>
                      <TableCell>{order.coupon || '-'}</TableCell>
                      <TableCell>{formatDateTime(order.orderAt, i18n.language)}</TableCell>
                      <TableCell className='text-right'>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant='ghost'
                              size='icon'
                              onClick={() => {
                                dispatch('OpenOrderDrawer')
                                setOrderDrawerState({ open: true, order: order })
                              }}
                            >
                              <FileSearch />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>{t('features.customerSegments.detail.orders.viewOrder')}</TooltipContent>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </CardContent>
        </Card>
      </div>

      <OrderDrawer
        open={orderDrawerState.open}
        handleOpen={() => setOrderDrawerState({ open: true, order: orderDrawerState.order })}
        handleClose={() => setOrderDrawerState({ open: false, order: undefined })}
        orderProps={orderDrawerState.order}
      />
    </>
  )
}
