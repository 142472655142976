import { Button } from '@/components/ui/button'
import { FC } from 'react'

type Props = {
  title: string
  buttonText?: string
  buttonOnClick?: () => void
}

export const EmptyState: FC<Props> = ({ title, buttonText, buttonOnClick }) => {
  return (
    <div className='flex flex-col items-center justify-center h-full gap-2'>
      <img src={'/images/empty.svg'} alt='Empty State' className='w-[200px]' />
      <p className='text-sm text-muted-foreground'>{title}</p>
      {buttonText && buttonOnClick && (
        <Button size='sm' onClick={buttonOnClick}>
          {buttonText}
        </Button>
      )}
    </div>
  )
}
