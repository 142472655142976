import { EmptyState } from '@/components/emptyState'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Table, TableBody, TableCell, TableHeader, TableRow } from '@/components/ui/table'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { CustomerSegmentService } from '@/gen/proto/customer_segment/customer_segment_pb'
import { useAuthUser } from '@/hooks/useAuthUser'
import { useCustomSnackbar } from '@/hooks/useCustomSnackbar'
import { useGrpcClient } from '@/hooks/useGrpcClient'
import { useSentryNotifier } from '@/hooks/useSentryNotifier'
import { SkeletonTable } from 'components/skeletonTable'
import dayjs from 'dayjs'
import { getIdToken } from 'firebase/auth'
import { orderBy, query } from 'firebase/firestore'
import { useAccount } from 'hooks/useAccount'
import { useSubCollectionRef } from 'hooks/useCollectionRef'
import { useCsvDownload } from 'hooks/useCsvDownload'
import { useCurrency } from 'hooks/useCurrency'
import { useCollection } from 'hooks/useFirestoreData'
import { Download } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { formatDate } from 'utils/timeUtil'
import { convertNthOrderCohortSummary, NthOrderCohortSummaryType } from '../../types/nthOrderCohort'
import { calculateStartEndYearMonth, TimeRange } from '../../types/timeRange'

enum MetricsIndex {
  CUSTOMER_COUNT = 'customerCount',
  REPEAT_RATE = 'repeatRate',
  AVG_ORDER_VALUE = 'avgOrderValue',
  AVG_DAYS_BETWEEN_ORDERS = 'avgDaysBetweenOrders',
}

export const NthOrderCohort = () => {
  const { t, i18n } = useTranslation()
  const authUser = useAuthUser()
  const { account } = useAccount()
  const { formatCurrency } = useCurrency()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const [nthOrderCohortSummary, setNthOrderCohortSummary] = useState<NthOrderCohortSummaryType>()
  const [customerSegmentId, setCustomerSegmentId] = useState<string>(searchParams.get('customer_segment_id') || '')
  const [timeRange, setTimeRange] = useState<TimeRange>(TimeRange.last12Months)
  const [metricsIndex, setMetricsIndex] = useState<MetricsIndex>(MetricsIndex.CUSTOMER_COUNT)
  const [loading, setLoading] = useState(false)

  const customerSegmentService = useGrpcClient(CustomerSegmentService)
  const { customerSegmentsRef } = useSubCollectionRef(account.shopRef!.id)
  const { data: customerSegments } = useCollection(query(customerSegmentsRef, orderBy('createdAt', 'desc')))

  useEffect(() => {
    setLoading(true)
    const { startYearMonth, endYearMonth } = calculateStartEndYearMonth(timeRange)
    const querySet = customerSegments?.find((cs) => cs.ref.id === customerSegmentId)?.querySet || ''
    const fetch = async () => {
      try {
        const token = await getIdToken(authUser!)
        const resp = await customerSegmentService.estimateNthOrderCohort(
          { querySet, startYearMonth, endYearMonth },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        setNthOrderCohortSummary(convertNthOrderCohortSummary(resp, i18n.language))
      } catch (err) {
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      } finally {
        setLoading(false)
      }
    }
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSegmentId, timeRange])

  const getStepValue = (step: number): string => {
    return `F${step}`
  }

  const getMetricsValue = (metrics: number): string => {
    switch (metricsIndex) {
      case MetricsIndex.CUSTOMER_COUNT:
        return metrics.toLocaleString()
      case MetricsIndex.REPEAT_RATE:
        return `${(metrics * 100).toFixed(1)}%`
      case MetricsIndex.AVG_ORDER_VALUE:
        return formatCurrency(metrics) || ''
      case MetricsIndex.AVG_DAYS_BETWEEN_ORDERS:
        // Do not display the avgDaysBetweenOrders of the first purchase because it does not exist
        return metrics === -1 ? '-' : `${metrics.toFixed(0)}`
    }
  }

  const getOpacityClassName = (step: number, value: number): string => {
    if (step === -1) return 'bg-opacity-0'
    const maxMetricValue = nthOrderCohortSummary
      ? Math.max(...nthOrderCohortSummary.nthResult.flatMap((nr) => nr.stepAndMetricsList.map((sm) => sm[metricsIndex])))
      : 0
    const scale = value / maxMetricValue
    if (scale >= 0.8) return 'bg-opacity-100'
    if (scale >= 0.6) return 'bg-opacity-80'
    if (scale >= 0.4) return 'bg-opacity-60'
    if (scale >= 0.2) return 'bg-opacity-40'
    return 'bg-opacity-20'
  }

  const { handleDownload, loading: downloadLoading } = useCsvDownload({
    data:
      nthOrderCohortSummary &&
      nthOrderCohortSummary.nthResult.flatMap((nr) => {
        return nr.stepAndMetricsList.map((sm) => {
          return {
            firstOrderYearMonth: nr.firstOrderYearMonth,
            step: getStepValue(sm.step),
            customerCount: sm.customerCount,
            repeatRate: sm.repeatRate.toFixed(3),
            avgOrderValue: sm.avgOrderValue,
            avgDaysBetweenOrders: sm.avgDaysBetweenOrders === -1 ? '' : sm.avgDaysBetweenOrders.toFixed(1),
          }
        })
      }),
    headers: [
      t('features.cohorts.nthOrderCohort.firstOrderYearMonth'),
      t('features.cohorts.nthOrderCohort.step'),
      t('features.cohorts.nthOrderCohort.customerCount'),
      t('features.cohorts.nthOrderCohort.repeatRate'),
      t('features.cohorts.nthOrderCohort.avgOrderValue'),
      t('features.cohorts.nthOrderCohort.avgDaysBetweenOrders'),
    ],
    fileName: t('features.cohorts.nthOrderCohort.downloadFileName', { date: formatDate(dayjs(), i18n.language) }),
    page: 'cohort_nthOrder',
  })

  return (
    <div>
      <div className='flex items-center justify-between mb-6'>
        <div className='flex items-center gap-2'>
          <Select value={customerSegmentId} onValueChange={(value) => setCustomerSegmentId(value)} disabled={!customerSegments}>
            <SelectTrigger>
              <SelectValue placeholder={t('features.cohorts.segment_all')} />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>{t('features.cohorts.segment')}</SelectLabel>
                {customerSegments?.map((segment) => (
                  <SelectItem key={segment.ref.id} value={segment.ref.id}>
                    {segment.name}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
          <Select value={timeRange} onValueChange={(value) => setTimeRange(value as TimeRange)}>
            <SelectTrigger>
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>{t('features.cohorts.timeRange')}</SelectLabel>
                {Object.values(TimeRange).map((timeRange) => (
                  <SelectItem key={timeRange} value={timeRange}>
                    {t(`features.cohorts.timeRange_${timeRange}`)}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
          <Select value={metricsIndex} onValueChange={(value) => setMetricsIndex(value as MetricsIndex)}>
            <SelectTrigger>
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>{t('features.cohorts.metric')}</SelectLabel>
                {Object.values(MetricsIndex).map((metric) => (
                  <SelectItem key={metric} value={metric}>
                    {t(`features.cohorts.nthOrderCohort.${metric}`)}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant='ghost'
              size='icon'
              disabled={downloadLoading || !nthOrderCohortSummary || nthOrderCohortSummary.avgResult.length === 0}
              onClick={handleDownload}
            >
              <Download />
            </Button>
          </TooltipTrigger>
          <TooltipContent>{t('features.customerSegments.overview.download')}</TooltipContent>
        </Tooltip>
      </div>

      <Card className='p-6'>
        {loading || !nthOrderCohortSummary ? (
          <SkeletonTable rowCount={15} columnCount={14} />
        ) : nthOrderCohortSummary.nthResult.length === 0 ? (
          <EmptyState title={t('features.cohorts.nthOrderCohort.noData')} />
        ) : (
          nthOrderCohortSummary && (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableCell className='w-[150px]'>{t('features.cohorts.nthOrderCohort.firstOrderYearMonth')}</TableCell>
                  <TableCell className='w-[80px] text-center'>{t('features.cohorts.nthOrderCohort.customerCount')}</TableCell>
                  {nthOrderCohortSummary.avgResult.map((ar) => (
                    <TableCell key={`nthOrderCohort-${ar.step}`} className='text-center w-[80px]'>
                      {getStepValue(ar.step)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {nthOrderCohortSummary.nthResult.map((nr) => (
                  <TableRow key={`nthOrderCohort-${nr.firstOrderYearMonth}`}>
                    <TableCell>{nr.firstOrderYearMonth}</TableCell>
                    <TableCell className='text-center'>{nr.stepAndMetricsList[0].customerCount}</TableCell>
                    {nr.stepAndMetricsList.map((sm) => (
                      <TableCell
                        key={`nthOrderCohort-${nr.firstOrderYearMonth}-${sm.step}`}
                        className={`text-center bg-indigo-200 ${getOpacityClassName(sm.step, sm[metricsIndex])}`}
                      >
                        {getMetricsValue(sm[metricsIndex])}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
                <TableRow className='bg-gray-50'>
                  <TableCell>{t('features.cohorts.nthOrderCohort.all')}</TableCell>
                  <TableCell className='text-center'>{nthOrderCohortSummary.avgResult[0].customerCount}</TableCell>
                  {nthOrderCohortSummary.avgResult.map((ar) => (
                    <TableCell
                      key={`nthOrderCohort-avg-${ar.step}`}
                      className={`text-center bg-indigo-300 ${getOpacityClassName(ar.step, ar[metricsIndex])}`}
                    >
                      {getMetricsValue(ar[metricsIndex])}
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          )
        )}
      </Card>
    </div>
  )
}
