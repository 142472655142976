import { CustomInput } from '@/components/ui/input-custom'
import { cn } from '@/lib/utils'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { QuerySetFormState } from '../schema/querySetFormSchema'

type Props = {
  minValuePath:
    | `query_subsets.${number}.query_items.${number}.value.min`
    | `query_subsets.${number}.query_items.${number}.filters.${number}.value.min`
    | `query_subsets.${number}.query_items.${number}.filters.${number}.value.min_sequence`
  maxValuePath:
    | `query_subsets.${number}.query_items.${number}.value.max`
    | `query_subsets.${number}.query_items.${number}.filters.${number}.value.max`
    | `query_subsets.${number}.query_items.${number}.filters.${number}.value.max_sequence`
  inputAdornmentString: string
  inputPropsMaxValue: number
}

export const IntRangeValue: FC<Props> = ({ minValuePath, maxValuePath, inputAdornmentString, inputPropsMaxValue }) => {
  const { t } = useTranslation()
  const { control, watch } = useFormContext<QuerySetFormState>()

  return (
    <>
      <div className='flex items-center gap-2'>
        <Controller
          control={control}
          name={minValuePath}
          rules={{
            validate: (min) => {
              const max = watch(maxValuePath)
              if (min == null && max == null) {
                // (==) null or undefined
                return t('features.customerSegments.querySet.querySetForm.messageValidationEitherMinOrMax')
              }
              if (typeof min === 'number' && typeof max === 'number' && min > max) {
                return t('features.customerSegments.querySet.querySetForm.messageValidationMinMustSmallerThanMax')
              }
              if (typeof min === 'number' && (min < 1 || min > inputPropsMaxValue)) {
                return t('features.customerSegments.querySet.querySetForm.messageValidationNumberRange', { min: 1, max: inputPropsMaxValue })
              }
              return true
            },
          }}
          render={({ field, fieldState: { error } }) => {
            return (
              <div className='min-w-[120px] flex flex-col gap-1'>
                <CustomInput
                  type='number'
                  value={field.value ?? ''}
                  onChange={(e) => field.onChange(e.target.value !== '' ? Number(e.target.value) : null)}
                  className={cn(error && 'border-red-500')}
                  min={1}
                  max={inputPropsMaxValue}
                  endAdornment={inputAdornmentString}
                />
                {error && <span className='text-red-500 text-xs'>{error.message}</span>}
              </div>
            )
          }}
        />
        <div>〜</div>
        <Controller
          control={control}
          name={maxValuePath}
          rules={{
            validate: (max) => {
              const min = watch(minValuePath)
              if (max == null && min == null) {
                // (==) null or undefined
                return t('features.customerSegments.querySet.querySetForm.messageValidationEitherMinOrMax')
              }
              if (typeof max === 'number' && typeof min === 'number' && max < min) {
                return t('features.customerSegments.querySet.querySetForm.messageValidationMaxMustBiggerThanMin')
              }
              if (typeof max === 'number' && (max < 1 || max > inputPropsMaxValue)) {
                return t('features.customerSegments.querySet.querySetForm.messageValidationNumberRange', { min: 1, max: inputPropsMaxValue })
              }
            },
          }}
          render={({ field, fieldState: { error } }) => {
            return (
              <div className='min-w-[120px] flex flex-col gap-1'>
                <CustomInput
                  type='number'
                  value={field.value ?? ''}
                  onChange={(e) => field.onChange(e.target.value !== '' ? Number(e.target.value) : null)}
                  className={cn(error && 'border-red-500')}
                  min={1}
                  max={inputPropsMaxValue}
                  endAdornment={inputAdornmentString}
                />
                {error && <span className='text-red-500 text-xs'>{error.message}</span>}
              </div>
            )
          }}
        />
      </div>
    </>
  )
}
