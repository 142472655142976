/*
 * Reference: https://betterprogramming.pub/the-best-way-to-manage-routes-in-a-react-project-with-typescript-c4e8d4422d64
 * codesandbox: https://codesandbox.io/s/sweet-cerf-shpzfi
 */

export enum PUBLIC_ROUTE {
  SIGNUP = '/signup',
  LOGIN = '/login',
  RESET_PASSWORD = '/reset-password',
  MEMBER_SIGNUP = '/signup/member',
}

export enum AUTHORIZED_ROUTE {
  // Home
  HOME = '/',

  // Segments
  CUSTOMER_SEGMENTS = '/customer-segments',
  CUSTOMER_SEGMENT_GROUP = '/customer-segments/groups/:id',
  CUSTOMER_SEGMENT_DETAIL = '/customer-segments/:id',
  CUSTOMER_SEGMENT_CREATE = '/customer-segments/create',

  // Insights
  INSIGHTS = '/insights',
  INSIGHT_DETAIL = '/insights/:id',
  PRODUCT_INSIGHTS = '/product-insights',
  COHORTS_MONTHLY = '/cohorts/monthly',
  COHORTS_NTH_ORDER = '/cohorts/nth-order',

  // Templates
  TEMPLATES = '/templates',

  // Settings
  SETTINGS_WORKSPACE = '/settings/workspace',
  SETTINGS_ACCOUNT = '/settings/account',
  SETTINGS_MEMBERS = '/settings/members',
  SETTINGS_PLAN = '/settings/plan',

  // Others
  THANK_YOU = '/thank-you',
}
