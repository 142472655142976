import { isPlanSelectable } from '@/config/plan'
import { BillingPlanIdentifier } from '@/gen/firestore'
import { BillingService } from '@/gen/proto/billing/billing_pb'
import { useAccount } from '@/hooks/useAccount'
import { useAuthUser } from '@/hooks/useAuthUser'
import { useCustomSnackbar } from '@/hooks/useCustomSnackbar'
import { useGrpcClient } from '@/hooks/useGrpcClient'
import { useSentryNotifier } from '@/hooks/useSentryNotifier'
import { AUTHORIZED_ROUTE } from '@/routing'
import { getIdToken } from 'firebase/auth'
import { LoaderCircle } from 'lucide-react'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ChannelTalkClient from 'utils/channelTalk'
import { PlanItem } from '../settings/plan/components/planItem'

export const Free: FC = () => {
  const { t } = useTranslation()
  const { shop } = useAccount()
  const authUser = useAuthUser()
  const { notifySentry } = useSentryNotifier()
  const { enqueueSnackbar } = useCustomSnackbar()
  const billingService = useGrpcClient(BillingService)

  const [syncing, setSyncing] = useState(false)

  useEffect(() => {
    const syncActivePlanFromShopify = async () => {
      setSyncing(true)
      try {
        const token = await getIdToken(authUser!)
        await billingService.syncActivePlanFromShopify({}, { headers: { Authorization: `Bearer ${token}` } })
      } catch (err) {
        notifySentry(err)
      } finally {
        setSyncing(false)
      }
    }

    syncActivePlanFromShopify()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [loadingManager, setLoadingManager] = useState({
    [BillingPlanIdentifier.starter]: false,
    [BillingPlanIdentifier.growth]: false,
    [BillingPlanIdentifier.scale]: false,
  })

  const onPlanSelect = async (plan: BillingPlanIdentifier) => {
    if (plan === BillingPlanIdentifier.enterprise) return ChannelTalkClient.openChat()
    return changeToPaidPlan(plan)
  }

  const changeToPaidPlan = async (plan: BillingPlanIdentifier) => {
    setLoadingManager((prev) => ({ ...prev, [plan]: true }))
    try {
      const token = await getIdToken(authUser!)
      const resp = await billingService.startChangePlanFlow(
        {
          plan: plan,
          returnURL: `${window.location.origin}${AUTHORIZED_ROUTE.THANK_YOU}`,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      // Redirect to Shopify Admin Dashboard
      window.location.href = resp.confirmURL
    } catch (err) {
      enqueueSnackbar(t('features.settings.plan.messageError'), { severity: 'error' })
      notifySentry(err)
      setLoadingManager((prev) => ({ ...prev, [plan]: false }))
    }
  }

  return (
    <>
      {syncing ? (
        <div className='flex justify-center items-center h-screen'>
          <LoaderCircle className='animate-spin' />
        </div>
      ) : (
        <div className='flex flex-col items-center justify-center h-screen'>
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col gap-1'>
              <h1 className='text-xl font-bold'>{t('features.free.title')}</h1>
              <p className='text-sm'>{t('features.free.description')}</p>
            </div>
            <div className='grid grid-cols-4 gap-4'>
              <PlanItem
                plan={BillingPlanIdentifier.starter}
                selected={shop.billingPlan.identifier === BillingPlanIdentifier.starter}
                selectable={isPlanSelectable(shop, BillingPlanIdentifier.starter)}
                onClick={() => onPlanSelect(BillingPlanIdentifier.starter)}
                submitting={loadingManager.starter}
              />
              <PlanItem
                plan={BillingPlanIdentifier.growth}
                selected={shop.billingPlan.identifier === BillingPlanIdentifier.growth}
                selectable={isPlanSelectable(shop, BillingPlanIdentifier.growth)}
                onClick={() => onPlanSelect(BillingPlanIdentifier.growth)}
                submitting={loadingManager.growth}
              />
              <PlanItem
                plan={BillingPlanIdentifier.scale}
                selected={shop.billingPlan.identifier === BillingPlanIdentifier.scale}
                selectable={isPlanSelectable(shop, BillingPlanIdentifier.scale)}
                onClick={() => onPlanSelect(BillingPlanIdentifier.scale)}
                submitting={loadingManager.scale}
              />
              <PlanItem
                plan={BillingPlanIdentifier.enterprise}
                selected={shop.billingPlan.identifier === BillingPlanIdentifier.enterprise}
                selectable={isPlanSelectable(shop, BillingPlanIdentifier.enterprise)}
                onClick={() => onPlanSelect(BillingPlanIdentifier.enterprise)}
                submitting={false}
              />
            </div>
            <div className='text-sm text-muted-foreground'>
              <p>{t('features.free.annotation1', { activeCustomerCount: shop?.activeCustomerCount.toLocaleString() })}</p>
              <div className='flex items-center gap-1'>
                <p>{t('features.free.annotation2')}</p>
                <p onClick={() => ChannelTalkClient.openChat()} className='cursor-pointer underline'>
                  {t('features.free.contact')}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
