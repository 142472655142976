import { DateRangePicker, DateRangePresetOption } from '@/components/dateRangePicker'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { useAccount } from '@/hooks/useAccount'
import { useActionTracker } from '@/hooks/useMixpanel'
import dayjs from 'dayjs'
import { CustomerSegmentState } from 'features/customerSegments/types/types'
import { useCsvDownload } from 'hooks/useCsvDownload'
import { Download, Info } from 'lucide-react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { formatDate, timestampToDayjs } from 'utils/timeUtil'
import { MetricsHistory } from '../types/types'
import { MetricsChartCard } from './components/metricsChartCard'

type Props = {
  customerSegment: CustomerSegmentState | undefined
  metricsHistory: MetricsHistory[] | undefined
  loading: boolean
  handleTimeRangeChange: (startDate: string, endDate: string) => void
}

export enum MetricsIndex {
  count = 'count',
  repeaterRate = 'repeaterRate',
  ltv = 'ltv',
  avgOrderValue = 'avgOrderValue',
  avgOrderCount = 'avgOrderCount',
  avgDaysBetweenOrders = 'avgDaysBetweenOrders',
}

export const Metrics: FC<Props> = ({ customerSegment, metricsHistory, loading, handleTimeRangeChange }) => {
  const { t, i18n } = useTranslation()
  const { shop } = useAccount()
  const { dispatch } = useActionTracker()

  const { handleDownload, loading: downloadLoading } = useCsvDownload({
    data: metricsHistory
      ? metricsHistory.map((item) => ({
          date: item.date,
          ...item.metrics,
        }))
      : [],
    headers: [
      t('features.customerSegments.detail.metrics.date'),
      t('common.customerMetrics.title_count'),
      t('common.customerMetrics.title_repeaterRate'),
      t('common.customerMetrics.title_ltv'),
      t('common.customerMetrics.title_avgOrderValue'),
      t('common.customerMetrics.title_avgOrderCount'),
      t('common.customerMetrics.title_avgDaysBetweenOrders'),
    ],
    fileName: t('features.customerSegments.detail.metrics.downloadFileName', {
      name: customerSegment?.name,
      date: formatDate(dayjs(), i18n.language),
    }),
    page: 'customerSegment_metricsHistory',
  })

  const baseEndDate = shop.lastDatasetEtlCompletedAt ? timestampToDayjs(shop.lastDatasetEtlCompletedAt) : dayjs()

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex items-center justify-between'>
        <DateRangePicker
          dateRangePresetOption={DateRangePresetOption.last30Days}
          maxEndDate={baseEndDate}
          timezone={shop.timezone}
          onApplyDateRangeChange={(dateRange) => {
            dispatch('ChangeSegmentMetricsTimeRange', {
              startDate: dateRange.startDate.format('YYYY-MM-DD'),
              endDate: dateRange.endDate.format('YYYY-MM-DD'),
            })
            handleTimeRangeChange(dateRange.startDate.format('YYYY-MM-DD'), dateRange.endDate.format('YYYY-MM-DD'))
          }}
        />
        <Tooltip>
          <TooltipTrigger asChild>
            <Button variant='ghost' size='icon' disabled={!customerSegment || !metricsHistory || downloadLoading} onClick={handleDownload}>
              <Download />
            </Button>
          </TooltipTrigger>
          <TooltipContent>{t('features.customerSegments.detail.metrics.download')}</TooltipContent>
        </Tooltip>
      </div>

      {metricsHistory && metricsHistory.length === 1 && (
        <Card className='p-6'>
          <div className='flex items-center gap-2'>
            <Info className='h-4 w-4' />
            <p className='text-sm'>{t('features.customerSegments.detail.metrics.alert')}</p>
          </div>
        </Card>
      )}

      <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-4'>
        {Object.values(MetricsIndex).map((metric, index) => (
          <MetricsChartCard key={index} metricsIndex={metric} metricsHistory={metricsHistory} loading={loading} />
        ))}
      </div>
    </div>
  )
}
