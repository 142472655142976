import { getIdToken } from '@firebase/auth'
import { CustomerSegmentService } from 'gen/proto/customer_segment/customer_segment_pb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DailyOrderSummary } from '../types/types'

export const useDailyOrders = (customerSegmentId: string | undefined, startDate: string, endDate: string) => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()

  const customerSegmentService = useGrpcClient(CustomerSegmentService)
  const [dailyOrders, setDailyOrders] = useState<DailyOrderSummary[]>()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (!customerSegmentId) return

    const handleFetch = async () => {
      setLoading(true)
      try {
        const token = await getIdToken(authUser!)
        const resp = await customerSegmentService.getDailyOrdersSummary(
          { customerSegmentId: customerSegmentId, startDate: startDate, endDate: endDate },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        const dailyOrders = resp.dailySummaries.map((summary) => {
          return {
            date: summary.date,
            orderAmount: Number(summary.orderAmount),
            orderCount: Number(summary.orderCount),
          }
        })
        setDailyOrders(dailyOrders)
      } catch (err) {
        enqueueSnackbar(t('common.messageDataFetchError'), { severity: 'error' })
        notifySentry(err)
      } finally {
        setLoading(false)
      }
    }

    handleFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSegmentId, startDate, endDate])

  return { dailyOrders, loading }
}
