import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { useTranslation } from 'react-i18next'
import { OP_DATE_RANGE, OP_NONE, OP_RELATIVE_DATE_RANGE } from '../schema/const'
import { QuerySetFormState } from '../schema/querySetFormSchema'
import { DateRangeValue } from '../value/dateRangeValue'
import { RelativeDateRangeValue } from '../value/relativeDateRangeValue'
import { FilterItemProps } from './type'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { cn } from '@/lib/utils'

export const OrderDateFilter: FC<FilterItemProps> = ({ filterPath }) => {
  const { t, i18n } = useTranslation()
  const { register, watch, control } = useFormContext<QuerySetFormState>()
  const operator = watch(`${filterPath}.operator`)

  return (
    <div className={`inline-flex items-center ${i18n.language === 'en' ? 'gap-5' : 'gap-3'}`}>
      <div className={`text-sm`}>{t('features.customerSegments.querySet.filterType', { context: 'order_date' })}</div>
      <input type='hidden' value='order_date' {...register(`${filterPath}.filter_type`)} />
      <Controller
        control={control}
        name={`${filterPath}.operator`}
        render={({ field, fieldState: { error } }) => {
          return (
            <div className='min-w-[120px]'>
              <Select defaultValue={OP_NONE} onValueChange={field.onChange}>
                <SelectTrigger className={cn('w-full', error && 'border-red-500')}>
                  <SelectValue placeholder={t('features.customerSegments.querySet.querySetForm.op_order_date', { context: OP_NONE })} />
                </SelectTrigger>
                <SelectContent className='bg-background'>
                  <SelectItem key={OP_NONE} value={OP_NONE}>
                    {t('features.customerSegments.querySet.querySetForm.op_order_date', { context: OP_NONE })}
                  </SelectItem>
                  <SelectItem key={OP_DATE_RANGE} value={OP_DATE_RANGE}>
                    {t('features.customerSegments.querySet.querySetForm.op', { context: OP_DATE_RANGE })}
                  </SelectItem>
                  <SelectItem key={OP_RELATIVE_DATE_RANGE} value={OP_RELATIVE_DATE_RANGE}>
                    {t('features.customerSegments.querySet.querySetForm.op', { context: OP_RELATIVE_DATE_RANGE })}
                  </SelectItem>
                </SelectContent>
              </Select>
            </div>
          )
        }}
      />

      {operator === OP_DATE_RANGE && (
        <DateRangeValue minDateValuePath={`${filterPath}.value.min_date`} maxDateValuePath={`${filterPath}.value.max_date`} />
      )}

      {operator === OP_RELATIVE_DATE_RANGE && (
        <RelativeDateRangeValue
          minValuePath={`${filterPath}.value.min`}
          maxValuePath={`${filterPath}.value.max`}
          inputAdornmentString={t('features.customerSegments.querySet.querySetForm.suffixDateRange')}
          inputPropsMaxValue={999}
        />
      )}
    </div>
  )
}
