import { LegendProps } from 'recharts'

export const CustomChartLegend: React.FC<LegendProps> = ({ payload }) => {
  if (!payload) return null

  return (
    <div className='flex flex-wrap justify-center pt-6'>
      {payload.map((entry, index) => (
        <div key={`item-${index}`} className='flex items-center mr-4 mb-2 text-xs'>
          <span className='w-3 h-3 inline-block mr-1 ' style={{ backgroundColor: entry.color }} />
          {entry.value}
        </div>
      ))}
    </div>
  )
}
