import { env } from 'config/env'
import mixpanel from 'mixpanel-browser'
import { FC, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'

/*
 * [Terms & Definitions]
 * PageView event → An event that is sent when the page is loaded or the URL changes.
 * Action event → Events other than PageView events (eg. Create of measures, CSV download, etc.)
 */

const MIXPANEL_CUSTOM_FIELD_PREFIX = '_' // Prefix for fields to make them easier to see on the mixpanel GUI
const VIEW_PAGE_EVENT_NAME = 'ViewPage'

type Props = {
  page: FC
  pageIdentifier?: string // String to uniquely identify the page on mixpanel. If not specified, location.pathname is set.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  other?: { [key: string]: any } // Additional information to mixpanel
}

// A high order component that sends a PageView event to mixpanel when the component passed to props' page is rendered
// If the URL changes on the page where this component is used, a PageView event is also sent to mixpanel
export const WithPageViewTracker: FC<Props> = ({ page, pageIdentifier, other }) => {
  const location = useLocation()
  const dynamicParam = useParams()
  const searchParams = Object.fromEntries(new URLSearchParams(decodeURIComponent(location.search))) // convert query param string to object
  const properties = addPrefixToKeys(
    {
      page_identifier: pageIdentifier || location.pathname,
      path: location.pathname,
      ...other,
      ...searchParams, // search params in url
      ...dynamicParam, // dynamic params in url
    },
    MIXPANEL_CUSTOM_FIELD_PREFIX
  )
  useEffect(() => {
    if (env.DEBUG_LOG) {
      console.log(`[DEBUG] Skipping Mixpanel\n* Event: "${VIEW_PAGE_EVENT_NAME}"\n* Properties: ${JSON.stringify(properties, null, 1)}`)
      return
    }
    mixpanel.track(VIEW_PAGE_EVENT_NAME, properties)
  }, [properties])

  const Page = page
  return <Page />
}

// All action events
export type ActionEvent =
  // Segments
  | 'FavoriteSegment'
  | 'UnfavoriteSegment'
  | 'PreviewSegmentMetrics'
  | 'OpenSegmentQueryset'
  | 'OpenSortSegmentMenu'
  | 'OpenSegmentGroupMenu'
  | 'OpenSegmentSearchField'
  | 'ClickDuplicateSegment'
  | 'SwitchSegmentDetailTab'
  | 'ChangeSegmentMetricsTimeRange'
  | 'ChangeSegmentOrdersTimeRange'
  | 'OpenOrderDrawer'
  | 'OpenCustomerDrawer'
  | 'SuggestInsightByAI'
  | 'ClickCreateFirstSegmentFromScratch'
  | 'ClickCreateFirstSegmentFromTemplate'
  // Insights
  | 'ViewInsight'
  | 'SwitchInsightDimension'
  | 'FilterInsightBySegment'
  | 'SearchProductInsight'
  | 'ClickPreferredSegment'
  | 'ClickRelevantProduct'
  // Templates
  | 'ClickTemplate'
  // Others
  | 'ViewGuide'
  | 'OpenQuickStartGuideDialog'
  | 'ClickCheckPlan'
  | 'DownloadCSV'
  | 'OpenSearchDialog'

type dispatchActionEvent = (
  action: ActionEvent,
  properties?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
  }
) => void

export const useActionTracker = (): { dispatch: dispatchActionEvent } => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch = (actionEvent: ActionEvent, _properties?: { [key: string]: any }) => {
    let properties = {}
    if (_properties) {
      properties = addPrefixToKeys(_properties, MIXPANEL_CUSTOM_FIELD_PREFIX)
    }
    if (env.DEBUG_LOG) {
      console.log(`[DEBUG] Skipping Mixpanel\n* Event: "${actionEvent}"\n* Properties: ${JSON.stringify(properties, null, 1)}`)
      return
    }
    mixpanel.track(actionEvent, properties)
  }

  return { dispatch }
}

// Identify event
export const trackIdentifyEvent = (shopName: string) => {
  mixpanel.identify(shopName)
  if (env.DEBUG_LOG) {
    console.log(`send identify event to mixpanel: ${shopName}`)
  }
}

// utils
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const addPrefixToKeys = (obj: { [key: string]: any }, prefix: string): { [key: string]: any } => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: { [key: string]: any } = {}
  for (const [key, value] of Object.entries(obj)) {
    result[`${prefix}${key}`] = value
  }
  return result
}
