import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { useTranslation } from 'react-i18next'
import { OP_NONE, OP_ORDER_SEQUENCE_FIRST, OP_ORDER_SEQUENCE_LAST, OP_ORDER_SEQUENCE_SECOND, OP_ORDER_SEQUENCE_SPECIFY } from '../schema/const'
import { QuerySetFormState } from '../schema/querySetFormSchema'
import { IntRangeValue } from '../value/intRangeValue'
import { FilterItemProps } from './type'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { cn } from '@/lib/utils'

export const OrderSequenceFilter: FC<FilterItemProps> = ({ filterPath }) => {
  const { t, i18n } = useTranslation()
  const { register, control, watch } = useFormContext<QuerySetFormState>()
  const operator = watch(`${filterPath}.operator`)

  return (
    <div className={`flex items-center ${i18n.language === 'en' ? 'gap-5' : 'gap-3'}`}>
      <div className='text-sm'>{t('features.customerSegments.querySet.filterType', { context: 'order_sequence' })}</div>
      <input type='hidden' value='order_sequence' {...register(`${filterPath}.filter_type`)} />
      <Controller
        control={control}
        name={`${filterPath}.operator`}
        render={({ field, fieldState: { error } }) => {
          return (
            <div className={cn('min-w-[120px]', error && 'border-red-500')}>
              <Select value={field.value} onValueChange={field.onChange}>
                <SelectTrigger className='w-full'>
                  <SelectValue placeholder={t('features.customerSegments.querySet.querySetForm.op_order_sequence', { context: field.value })} />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value={OP_NONE}>
                      {t('features.customerSegments.querySet.querySetForm.op_order_sequence', { context: OP_NONE })}
                    </SelectItem>
                    <SelectItem value={OP_ORDER_SEQUENCE_FIRST}>
                      {t('features.customerSegments.querySet.querySetForm.op_order_sequence', { context: OP_ORDER_SEQUENCE_FIRST })}
                    </SelectItem>
                    <SelectItem value={OP_ORDER_SEQUENCE_SECOND}>
                      {t('features.customerSegments.querySet.querySetForm.op_order_sequence', { context: OP_ORDER_SEQUENCE_SECOND })}
                    </SelectItem>
                    <SelectItem value={OP_ORDER_SEQUENCE_LAST}>
                      {t('features.customerSegments.querySet.querySetForm.op_order_sequence', { context: OP_ORDER_SEQUENCE_LAST })}
                    </SelectItem>
                    <SelectItem value={OP_ORDER_SEQUENCE_SPECIFY}>
                      {t('features.customerSegments.querySet.querySetForm.op_order_sequence', { context: OP_ORDER_SEQUENCE_SPECIFY })}
                    </SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
              {error && <span className='text-red-500 text-xs mt-1'>{error.message}</span>}
            </div>
          )
        }}
      />
      {operator === 'specify_sequence' && (
        <IntRangeValue
          minValuePath={`${filterPath}.value.min_sequence`}
          maxValuePath={`${filterPath}.value.max_sequence`}
          inputAdornmentString={t('features.customerSegments.querySet.querySetForm.suffixSequence')}
          inputPropsMaxValue={99}
        />
      )}
    </div>
  )
}
