import { useActionTracker } from 'hooks/useMixpanel'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'

type Props = {
  text: string
}

export const TrialBanner: FC<Props> = ({ text }) => {
  const { t } = useTranslation()
  const { dispatch } = useActionTracker()
  const navigate = useNavigate()

  return (
    <div className='flex items-center justify-between w-full px-6 py-2 bg-primary text-sm text-white'>
      <div>{text}</div>
      <div
        className='cursor-pointer animate-wave underline hover:text-purple-300'
        onClick={() => {
          dispatch('ClickCheckPlan', { referrer: 'trialBanner' })
          navigate(AUTHORIZED_ROUTE.SETTINGS_PLAN)
        }}
      >
        {t('layouts.dashboardLayout.upgradeCTA')}
      </div>
    </div>
  )
}
