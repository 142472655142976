import { Button } from '@/components/ui/button'
import { getIdToken } from 'firebase/auth'
import { DataLoaderService } from 'gen/proto/data_loader/data_loader_pb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { Loader2 } from 'lucide-react'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

export const DataSyncNotStarted: React.FC = () => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const dataLoaderService = useGrpcClient(DataLoaderService)
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()

  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const token = await getIdToken(authUser!)
      await dataLoaderService.startLoadFromShopify({}, { headers: { Authorization: `Bearer ${token}` } })
    } catch (e) {
      enqueueSnackbar(t('features.dataSyncNotStarted.messageError'), { severity: 'error' })
      notifySentry(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='flex justify-center items-center text-center h-screen'>
      <div className='flex flex-col items-center gap-6'>
        <h1 className='text-2xl font-bold'>{t('features.dataSyncNotStarted.title')}</h1>
        <p className='text-sm'>
          <Trans i18nKey='features.dataSyncNotStarted.description' />
        </p>
        <Button type='submit' onClick={handleSubmit} disabled={loading} className='w-fit'>
          {loading ? <Loader2 className='animate-spin' /> : t('features.dataSyncNotStarted.submit')}
        </Button>
      </div>
    </div>
  )
}
