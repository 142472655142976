import { LoaderCircle } from 'lucide-react'
import { CartesianGrid, Line, LineChart, XAxis, YAxis } from 'recharts'

import { EmptyState } from '@/components/emptyState'
import { SkeletonTable } from '@/components/skeletonTable'
import { Card, CardContent } from '@/components/ui/card'
import { ChartConfig, ChartContainer, ChartLegend, ChartTooltip, ChartTooltipContent } from '@/components/ui/chart'
import { Box, Grid, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CategoricalChartFunc } from 'recharts/types/chart/generateCategoricalChart'
import { CategoricalChartState } from 'recharts/types/chart/types'
import { addIndexToName } from '../../types/insight'
import { MonthlyInsightValue, ViewType } from '../detail'
import { CustomChartLegend } from './components/chartLegendContent'
import { NameCheckBoxList } from './components/nameCheckBoxList'

const DEFAULT_VISIBLE_COUNT = 3
const VISUAL_PAPER_HEIGHT = '80vh'
const TABLE_ROWS_PER_PAGE = 10

type Props = {
  viewType: ViewType
  insightValues: MonthlyInsightValue[] | undefined
  loading: boolean
  onChartPointClick: (name: string, month: string) => void
}

type customDotProps = {
  cx?: number
  cy?: number
  payload: { yearMonth: string }
  value: { name: string; fill: string }
}

export const MonthlyInsight: FC<Props> = ({ viewType, insightValues, loading, onChartPointClick }) => {
  const { t } = useTranslation()

  const [selectedNames, setSelectedNames] = useState<string[]>([])
  const [page, setPage] = useState(0)

  useEffect(() => {
    setSelectedNames(insightValues?.slice(0, DEFAULT_VISIBLE_COUNT).map((value) => value.name) || [])
  }, [insightValues])

  const selectedInsightValues = useMemo(() => {
    if (!insightValues) return []
    return insightValues.filter((value) => selectedNames.includes(value.name))
  }, [insightValues, selectedNames])

  useEffect(() => {
    if (loading) setPage(0) // Reset page
  }, [loading])

  const chartData = useMemo(() => {
    if (!insightValues) return []
    const filteredData = insightValues.filter((item) => selectedNames.includes(item.name))
    const yearMonths = Array.from(new Set(filteredData.flatMap((item) => item.values.map((v) => v.yearMonth))))

    return yearMonths.map((yearMonth) => {
      const point: { [key: string]: number | string } = { yearMonth }
      filteredData.forEach((data) => {
        const value = data.values.find((v) => v.yearMonth === yearMonth)
        point[data.name] = value ? value.customerCount : 0
      })
      return point
    })
  }, [insightValues, selectedNames])

  const chartConfig = useMemo(() => {
    if (!insightValues) return {}
    return insightValues.reduce(
      (config, value, index) => {
        const colorIndex = index + 1
        config[value.name] = {
          label: value.name,
          color: `hsl(var(--chart-${colorIndex}))`,
        }
        return config
      },
      {} satisfies ChartConfig as Record<string, { label: string; color: string }>
    )
  }, [insightValues])

  const [activeYearMonth, setActiveYearMonth] = useState<string | null>(null)

  const mouseMoveDisabled = useRef(false) // Disable mouseMove event while clicking, since the click event and mouseMove event may conflict and cause problems when clicking.

  const handleMouseMove: CategoricalChartFunc = (e: CategoricalChartState | null) => {
    if (mouseMoveDisabled.current) return

    if (e && e.activeLabel && e.activeLabel !== activeYearMonth) {
      setActiveYearMonth(e.activeLabel)
    }
  }

  const CustomDot = ({ cx, cy, payload, value }: customDotProps) => {
    if (!cx || !cy || payload.yearMonth !== activeYearMonth) return null

    const handleClick = () => {
      if (payload && payload.yearMonth) {
        console.log(payload.yearMonth, 'payload.yearMonth')
        onChartPointClick(value.name, payload.yearMonth)
      }
    }
    return (
      <g onClick={handleClick} style={{ cursor: 'pointer' }}>
        {/* Actual hit range */}
        <circle cx={cx} cy={cy} r={8} fill='transparent' stroke='none' />

        {/* Visible Dots */}
        <circle cx={cx} cy={cy} r={5} fill={value.fill} stroke='none' />
      </g>
    )
  }

  return (
    <>
      {viewType === ViewType.visual && (
        <Grid container spacing={2}>
          <Grid item xs={9}>
            {loading || !insightValues ? (
              <Card className='flex justify-center items-center h-[80vh] rounded-xl border'>
                <LoaderCircle className='animate-spin' />
              </Card>
            ) : insightValues.length === 0 ? (
              <Card className='flex flex-col justify-center items-center h-[80vh] rounded-xl border'>
                <EmptyState title={t('features.insights.detail.noData')} />
              </Card>
            ) : (
              <Card className='flex flex-col w-full h-full justify-center'>
                <CardContent w-full h-full>
                  <ChartContainer config={chartConfig}>
                    <LineChart
                      accessibilityLayer
                      data={chartData}
                      style={{ aspect: 0.5 }}
                      margin={{ left: 4, right: 4 }}
                      onMouseMove={handleMouseMove}
                      onMouseDown={() => (mouseMoveDisabled.current = true)}
                      onMouseUp={() => (mouseMoveDisabled.current = false)}
                    >
                      <CartesianGrid vertical={false} />
                      <YAxis tickLine={false} axisLine={false} tickFormatter={(value) => value.toLocaleString()} />
                      <XAxis dataKey='yearMonth' tickLine={false} axisLine={false} tickMargin={8} />
                      <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
                      {selectedInsightValues &&
                        selectedInsightValues.map((value: MonthlyInsightValue) => (
                          <Line
                            key={value.name}
                            dataKey={value.name}
                            type='monotone'
                            stroke={value.fill}
                            strokeWidth={2}
                            dot={(props) => <CustomDot {...props} value={value} />}
                            activeDot={false}
                          />
                        ))}
                      <ChartLegend content={<CustomChartLegend />} />
                    </LineChart>
                  </ChartContainer>
                </CardContent>
              </Card>
            )}
          </Grid>
          <Grid item xs={3}>
            <NameCheckBoxList
              loading={loading}
              items={insightValues?.map((value) => ({ name: value.name, isVisible: value.isVisible })) || []}
              selectedNames={selectedNames}
              setSelectedNames={setSelectedNames}
              height={VISUAL_PAPER_HEIGHT}
            />
          </Grid>
        </Grid>
      )}

      {viewType === ViewType.table && (
        <Card className='p-[20px_36px] rounded-xl border'>
          {loading || !insightValues ? (
            <SkeletonTable columnCount={12} rowCount={10} />
          ) : insightValues.length === 0 ? (
            <EmptyState title={t('features.insights.detail.noData')} />
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      '& .MuiTableCell-head': {
                        color: (theme) => theme.palette.text.secondary,
                        fontSize: '13px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    }}
                  >
                    <TableCell>{t('features.insights.detail.name')}</TableCell>
                    {insightValues[0].values.map((v, i) => (
                      <TableCell key={i} align='center'>
                        {v.yearMonth}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {insightValues.slice(page * TABLE_ROWS_PER_PAGE, page * TABLE_ROWS_PER_PAGE + TABLE_ROWS_PER_PAGE).map((row) => (
                    <TableRow key={row.name}>
                      <TableCell
                        component='th'
                        scope='row'
                        style={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                      >
                        <Tooltip title={row.name} placement='top'>
                          <span>{addIndexToName(row.name, row.rank)}</span>
                        </Tooltip>
                      </TableCell>
                      {row.values.map((v, index) => (
                        <TableCell key={index} align='center'>
                          <Tooltip title={t('features.insights.detail.customers', { count: v.customerCount })} placement='top'>
                            <span>{v.customerCount}</span>
                          </Tooltip>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {insightValues && insightValues.length > 0 && (
            <Box display='flex' justifyContent='center'>
              <Pagination
                shape='rounded'
                count={insightValues ? Math.ceil(insightValues.length / TABLE_ROWS_PER_PAGE) : 0}
                page={page + 1}
                onChange={(_, v) => setPage(v - 1)}
                sx={{ marginY: '20px' }}
              />
            </Box>
          )}
        </Card>
      )}
    </>
  )
}
