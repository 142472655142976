import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Table, TableBody, TableCell, TableRow } from '@/components/ui/table'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { BillingService } from '@/gen/proto/billing/billing_pb'
import { GuideIcon } from 'components/guideIcon'
import { billingStatus, isPlanSelectable, isUpgradeRequired } from 'config/plan'
import dayjs from 'dayjs'
import { getIdToken } from 'firebase/auth'
import { BillingPlanIdentifier, Shop, ShopBillingStatus } from 'gen/firestore'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { CircleHelp, LoaderCircle, TriangleAlert } from 'lucide-react'
import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AUTHORIZED_ROUTE } from 'routing'
import ChannelTalkClient from 'utils/channelTalk'
import { DowngradeToFreeDialog } from './components/downgradeToFreeDialog'
import { PlanItem } from './components/planItem'

type Props = {
  shop: Shop
}

export const Plan: FC<Props> = ({ shop }) => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const { notifySentry } = useSentryNotifier()
  const { enqueueSnackbar } = useCustomSnackbar()
  const billingService = useGrpcClient(BillingService)
  const shopBillingStatus = billingStatus(shop, dayjs())

  const [syncing, setSyncing] = useState(false)

  const activeCustomerPercentage = useMemo(() => {
    return (shop.activeCustomerCount / shop.billingPlan.maxActiveCustomerCount) * 100
  }, [shop])

  useEffect(() => {
    const syncActivePlanFromShopify = async () => {
      setSyncing(true)
      try {
        const token = await getIdToken(authUser!)
        await billingService.syncActivePlanFromShopify({}, { headers: { Authorization: `Bearer ${token}` } })
      } catch (err) {
        notifySentry(err)
      } finally {
        setSyncing(false)
      }
    }

    syncActivePlanFromShopify()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [downgradeToFreeDialogOpen, setDowngradeToFreeDialogOpen] = useState(false)
  const [loadingManager, setLoadingManager] = useState({
    [BillingPlanIdentifier.starter]: false,
    [BillingPlanIdentifier.growth]: false,
    [BillingPlanIdentifier.scale]: false,
  })

  const onPlanSelect = async (plan: BillingPlanIdentifier) => {
    if (plan === BillingPlanIdentifier.enterprise) return ChannelTalkClient.openChat()
    return changeToPaidPlan(plan)
  }

  const changeToPaidPlan = async (plan: BillingPlanIdentifier) => {
    setLoadingManager((prev) => ({ ...prev, [plan]: true }))
    try {
      const token = await getIdToken(authUser!)
      const resp = await billingService.startChangePlanFlow(
        {
          plan: plan,
          returnURL: `${window.location.origin}${AUTHORIZED_ROUTE.THANK_YOU}`,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      // Redirect to Shopify Admin Dashboard
      window.location.href = resp.confirmURL
    } catch (err) {
      enqueueSnackbar(t('features.settings.plan.messageError'), { severity: 'error' })
      notifySentry(err)
      setLoadingManager((prev) => ({ ...prev, [plan]: false }))
    }
  }

  return (
    <>
      {syncing ? (
        <div className='flex justify-center items-center h-[calc(100vh-240px)]'>
          <LoaderCircle className='animate-spin' />
        </div>
      ) : (
        <div className='flex flex-col gap-6'>
          {isUpgradeRequired(shop) && (
            <Card className='bg-red-200'>
              <CardHeader>
                <CardTitle>
                  <div className='flex items-center gap-2'>
                    <TriangleAlert className='w-4 h-4' />
                    {t('features.settings.plan.upgradeIsRequiredTitle')}
                  </div>
                </CardTitle>
              </CardHeader>
              <CardContent>{t('features.settings.plan.upgradeIsRequiredDescription')}</CardContent>
            </Card>
          )}

          <Card>
            <CardHeader>
              <div className='flex items-center justify-between'>
                <div className='flex items-center gap-1'>
                  <CardTitle>{t('features.settings.plan.title')}</CardTitle>
                  <GuideIcon guideType='UpgradeFeature' />
                </div>
                {shop.billingPlan.identifier !== BillingPlanIdentifier.free && (
                  <Button size='sm' variant='secondary' onClick={() => setDowngradeToFreeDialogOpen(true)}>
                    {t('features.settings.plan.unsubscribe')}
                  </Button>
                )}
              </div>
            </CardHeader>
            <CardContent>
              <Table>
                <TableBody>
                  <TableRow className='border-none'>
                    <TableCell className='w-[180px]'>{t('features.settings.plan.currentPlan')}</TableCell>
                    <TableCell>
                      <div className='flex items-center gap-1'>
                        <span className='text-sm'>{t('features.settings.plan.name', { context: shop.billingPlan.identifier })}</span>
                        <span className='text-sm'>{t('features.settings.plan.price_amount', { amount: shop.billingPlan.amount })}</span>
                        {shop.isDemo && (
                          <Badge variant='outline' className='ml-1'>
                            {t('features.settings.plan.demo')}
                          </Badge>
                        )}
                        {shopBillingStatus === ShopBillingStatus.trial && (
                          <Badge variant='outline' className='ml-1'>
                            {t('features.settings.plan.trial')}
                          </Badge>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow className='border-none'>
                    <TableCell>
                      <div className='flex items-center gap-1'>
                        <span className='text-sm'>{t('features.settings.plan.activeCustomers')}</span>
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger>
                              <CircleHelp className='w-3 h-3' />
                            </TooltipTrigger>
                            <TooltipContent>{t('features.settings.plan.activeCustomersDescription')}</TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </div>
                    </TableCell>
                    <TableCell>
                      {shopBillingStatus === ShopBillingStatus.paid
                        ? t('features.settings.plan.activeCustomersPercentage', {
                            current: shop.activeCustomerCount.toLocaleString(),
                            max: shop.billingPlan.maxActiveCustomerCount.toLocaleString(),
                            percentage: activeCustomerPercentage.toFixed(0),
                          })
                        : t('features.settings.plan.activeCustomersCounter', { current: shop.activeCustomerCount.toLocaleString() })}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>

          <div className='grid grid-cols-4 gap-4'>
            <PlanItem
              plan={BillingPlanIdentifier.starter}
              selected={shop.billingPlan.identifier === BillingPlanIdentifier.starter}
              selectable={isPlanSelectable(shop, BillingPlanIdentifier.starter)}
              onClick={() => onPlanSelect(BillingPlanIdentifier.starter)}
              submitting={loadingManager.starter}
            />
            <PlanItem
              plan={BillingPlanIdentifier.growth}
              selected={shop.billingPlan.identifier === BillingPlanIdentifier.growth}
              selectable={isPlanSelectable(shop, BillingPlanIdentifier.growth)}
              onClick={() => onPlanSelect(BillingPlanIdentifier.growth)}
              submitting={loadingManager.growth}
            />
            <PlanItem
              plan={BillingPlanIdentifier.scale}
              selected={shop.billingPlan.identifier === BillingPlanIdentifier.scale}
              selectable={isPlanSelectable(shop, BillingPlanIdentifier.scale)}
              onClick={() => onPlanSelect(BillingPlanIdentifier.scale)}
              submitting={loadingManager.scale}
            />
            <PlanItem
              plan={BillingPlanIdentifier.enterprise}
              selected={shop.billingPlan.identifier === BillingPlanIdentifier.enterprise}
              selectable={isPlanSelectable(shop, BillingPlanIdentifier.enterprise)}
              onClick={() => onPlanSelect(BillingPlanIdentifier.enterprise)}
              submitting={false}
            />
          </div>
        </div>
      )}

      {downgradeToFreeDialogOpen && (
        <DowngradeToFreeDialog open={downgradeToFreeDialogOpen} handleClose={() => setDowngradeToFreeDialogOpen(false)} />
      )}
    </>
  )
}
