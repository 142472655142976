import { RadialBar, RadialBarChart, TooltipProps } from 'recharts'

import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { ChartConfig, ChartContainer, ChartLegend, ChartTooltip } from '@/components/ui/chart'
import { useSort } from '@/hooks/useSort'
import {
  Box,
  Grid,
  LinearProgress,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material'
import { EmptyState } from 'components/emptyState'
import { SkeletonTable } from 'components/skeletonTable'
import { LoaderCircle } from 'lucide-react'
import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent'
import { addIndexToName } from '../../types/insight'
import { PopularityInsightValue, ViewType } from '../detail'
import { CustomChartLegend } from './components/chartLegendContent'
import { NameCheckBoxList } from './components/nameCheckBoxList'

const DEFAULT_VISIBLE_COUNT = 3
const VISUAL_PAPER_HEIGHT = '80vh'
const TABLE_ROWS_PER_PAGE = 10

type Props = {
  viewType: ViewType
  insightValues: PopularityInsightValue[] | undefined
  loading: boolean
  onChartPointClick: (name: string) => void
}

export const PopularityInsight: FC<Props> = ({ viewType, insightValues, loading, onChartPointClick }) => {
  const { t } = useTranslation()
  const { sortedData: sortedInsightValues, sort, toggleSort } = useSort<PopularityInsightValue>(insightValues || [])
  const [page, setPage] = useState(0)
  const [selectedNames, setSelectedNames] = useState<string[]>([])

  const chartData = useMemo(() => {
    if (!insightValues) return []
    return insightValues.filter((value) => selectedNames.includes(value.name)).reverse() // For UI convenience, reverse is executed.
  }, [insightValues, selectedNames])

  const chartConfig = useMemo(() => {
    if (!insightValues) return {}
    return insightValues.reduce(
      (config, value, index) => {
        const colorIndex = index + 1
        config[value.name] = {
          label: value.name,
          color: `hsl(var(--chart-${colorIndex}))`,
        }
        return config
      },
      {} satisfies ChartConfig as Record<string, { label: string; color: string }>
    )
  }, [insightValues])

  useEffect(() => {
    setSelectedNames(insightValues?.slice(0, DEFAULT_VISIBLE_COUNT).map((value) => value.name) || [])
  }, [insightValues])

  useEffect(() => {
    if (loading) setPage(0) // Reset page
  }, [loading])

  return (
    <>
      {viewType === ViewType.visual && (
        <Grid container spacing={2}>
          <Grid item xs={9}>
            {loading || !insightValues ? (
              <Card className='flex justify-center items-center h-[80vh] rounded-xl border'>
                <LoaderCircle className='animate-spin' />
              </Card>
            ) : insightValues.length === 0 ? (
              <Card className='flex flex-col justify-center items-center h-[80vh] rounded-xl border'>
                <EmptyState title={t('features.insights.detail.noData')} />
              </Card>
            ) : (
              <Card className='flex flex-col w-full h-full'>
                <CardHeader className='items-center pb-0'>
                  <CardTitle>{t('features.insights.detail.popularityInsight.totalCustomers')}</CardTitle>
                  <CardDescription>{((insightValues[0].customerCount / insightValues[0].ratio) * 100).toFixed(0)}</CardDescription>
                </CardHeader>
                <CardContent className='flex justify-center items-center flex-1'>
                  <ChartContainer config={chartConfig} className='w-full h-full'>
                    <RadialBarChart data={chartData} innerRadius='30%' outerRadius='90%' dataKey={'customerCount'} startAngle={90} endAngle={-270}>
                      <ChartTooltip cursor={false} content={<CustomTooltip />} />
                      <RadialBar cursor='pointer' dataKey='ratio' background onClick={(data) => onChartPointClick(data.payload.name)} />
                      <ChartLegend content={<CustomChartLegend />} />
                    </RadialBarChart>
                  </ChartContainer>
                </CardContent>
              </Card>
            )}
          </Grid>
          <Grid item xs={3}>
            <NameCheckBoxList
              loading={loading}
              items={insightValues?.map((value) => ({ name: value.name, isVisible: value.isVisible })) || []}
              selectedNames={selectedNames}
              setSelectedNames={setSelectedNames}
              height={VISUAL_PAPER_HEIGHT}
            />
          </Grid>
        </Grid>
      )}
      {viewType === ViewType.table && (
        <Card className='p-[20px_36px] rounded-xl border'>
          {loading || !insightValues ? (
            <SkeletonTable columnCount={3} rowCount={10} />
          ) : insightValues.length === 0 ? (
            <EmptyState title={t('features.insights.detail.noData')} />
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      '& .MuiTableCell-head': {
                        color: (theme) => theme.palette.text.secondary,
                        fontSize: '13px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    }}
                  >
                    <TableCell>{t('features.insights.detail.name')}</TableCell>
                    <TableCell align='center'>
                      <TableSortLabel
                        active={sort.target === 'customerCount'}
                        direction={sort.target === 'customerCount' && sort.orderBy === 'asc' ? 'asc' : 'desc'}
                        onClick={() => toggleSort('customerCount')}
                        hideSortIcon
                      >
                        {t('features.insights.detail.customerCount')}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align='center'>{t('features.insights.detail.popularityInsight.ratio')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedInsightValues.slice(page * TABLE_ROWS_PER_PAGE, page * TABLE_ROWS_PER_PAGE + TABLE_ROWS_PER_PAGE).map((row) => (
                    <TableRow key={row.name}>
                      <TableCell
                        component='th'
                        scope='row'
                        style={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                      >
                        <Tooltip title={row.name} placement='top'>
                          <Typography component='span' fontSize='14px'>
                            {addIndexToName(row.name, row.rank)}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell align='center'>{row.customerCount}</TableCell>
                      <TableCell align='center'>
                        <Box display='flex' alignItems='center'>
                          <Box width='100%' mr={1}>
                            <LinearProgress variant='determinate' value={row.ratio} />
                          </Box>
                          <Box minWidth={35}>
                            <Typography variant='body2' color='textSecondary'>{`${
                              row.ratio < 1 ? row.ratio.toFixed(1) : Math.ceil(row.ratio)
                            }%`}</Typography>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {insightValues && insightValues.length > 0 && (
            <Box display='flex' justifyContent='center'>
              <Pagination
                shape='rounded'
                count={insightValues ? Math.ceil(insightValues.length / TABLE_ROWS_PER_PAGE) : 0}
                page={page + 1}
                onChange={(_, v) => setPage(v - 1)}
                sx={{ marginY: '20px' }}
              />
            </Box>
          )}
        </Card>
      )}
    </>
  )
}

const CustomTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length > 0) {
    const data = payload[0].payload
    return (
      <div className='bg-white p-3 rounded-lg shadow-lg'>
        <p className='m-0 font-bold text-gray-800'>{data.name}</p>
        <p className='m-0 text-gray-600'>{`${data.ratio}%`}</p>
      </div>
    )
  }
  return null
}
