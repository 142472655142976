import { Card, CardContent } from '@/components/ui/card'
import { ChartConfig, ChartContainer, ChartLegend, ChartTooltip, ChartTooltipContent } from '@/components/ui/chart'
import { Box, Grid, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Tooltip } from '@mui/material'
import { EmptyState } from 'components/emptyState'
import { SkeletonTable } from 'components/skeletonTable'
import { addIndexToName } from 'features/insights/types/insight'
import { useSort } from 'hooks/useSort'
import { LoaderCircle } from 'lucide-react'
import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from 'recharts'
import { NthOrderInsightValue, ViewType } from '../detail'
import { CustomChartLegend } from './components/chartLegendContent'
import { NameCheckBoxList } from './components/nameCheckBoxList'

const DEFAULT_VISIBLE_COUNT = 3
const VISUAL_PAPER_HEIGHT = '80vh'
const TABLE_ROWS_PER_PAGE = 10

type Props = {
  viewType: ViewType
  insightValues: NthOrderInsightValue[] | undefined
  loading: boolean
  onChartPointClick: (name: string, index: number) => void
}

export const NthOrderInsight: FC<Props> = ({ viewType, insightValues, loading, onChartPointClick }) => {
  const { t } = useTranslation()

  const [selectedNames, setSelectedNames] = useState<string[]>([])
  const [page, setPage] = useState(0)

  const { sortedData: sortedInsightValues, sort, toggleSort } = useSort<NthOrderInsightValue>(insightValues || [])

  useEffect(() => {
    setSelectedNames(insightValues?.slice(0, DEFAULT_VISIBLE_COUNT).map((value) => value.name) || [])
  }, [insightValues])

  useEffect(() => {
    if (loading) setPage(0) // Reset page
  }, [loading])

  const selectedInsightValues = useMemo(() => {
    if (!insightValues) return []
    return insightValues.filter((value) => selectedNames.includes(value.name))
  }, [insightValues, selectedNames])

  const chartData = useMemo(() => {
    if (!insightValues || selectedNames.length === 0) return []

    return [
      {
        nth: t('features.insights.detail.nthOrderInsight.first'),
        ...Object.fromEntries(selectedInsightValues.map((value) => [value.name, value.first])),
        index: 0,
      },
      {
        nth: t('features.insights.detail.nthOrderInsight.secondPlus'),
        ...Object.fromEntries(selectedInsightValues.map((value) => [value.name, value.secondPlus])),
        index: 1,
      },
      {
        nth: t('features.insights.detail.nthOrderInsight.second'),
        ...Object.fromEntries(selectedInsightValues.map((value) => [value.name, value.second])),
        index: 2,
      },
      {
        nth: t('features.insights.detail.nthOrderInsight.third'),
        ...Object.fromEntries(selectedInsightValues.map((value) => [value.name, value.third])),
        index: 3,
      },
      {
        nth: t('features.insights.detail.nthOrderInsight.fourth'),
        ...Object.fromEntries(selectedInsightValues.map((value) => [value.name, value.fourth])),
        index: 4,
      },
      {
        nth: t('features.insights.detail.nthOrderInsight.fifthPlus'),
        ...Object.fromEntries(selectedInsightValues.map((value) => [value.name, value.fifthPlus])),
        index: 5,
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightValues, selectedInsightValues])

  const chartConfig = useMemo(() => {
    if (!insightValues) return {}
    return insightValues.reduce(
      (config, value, index) => {
        const colorIndex = index + 1
        config[value.name] = {
          label: value.name,
          color: `hsl(var(--chart-${colorIndex}))`,
        }
        return config
      },
      {} satisfies ChartConfig as Record<string, { label: string; color: string }>
    )
  }, [insightValues])

  return (
    <>
      {viewType === ViewType.visual && (
        <Grid container spacing={2}>
          <Grid item xs={9}>
            {loading || !insightValues ? (
              <Card className='flex justify-center items-center h-[80vh] rounded-xl border'>
                <LoaderCircle className='animate-spin' />
              </Card>
            ) : insightValues.length === 0 ? (
              <Card className='flex flex-col justify-center items-center h-[80vh] rounded-xl border'>
                <EmptyState title={t('features.insights.detail.noData')} />
              </Card>
            ) : (
              <Card className='flex flex-col w-full h-full justify-center'>
                <CardContent>
                  <ChartContainer config={chartConfig}>
                    <BarChart accessibilityLayer data={chartData} layout='vertical'>
                      <CartesianGrid horizontal={false} />
                      <XAxis type='number' tickLine={false} axisLine={false} />
                      <YAxis dataKey='nth' type='category' tickLine={false} tickMargin={10} axisLine={false} tickFormatter={(value) => value} />
                      <ChartTooltip cursor={false} content={<ChartTooltipContent hideLabel />} />
                      {selectedInsightValues &&
                        selectedInsightValues.map((value: NthOrderInsightValue) => (
                          <Bar
                            key={value.name}
                            dataKey={value.name}
                            fill={value.fill}
                            radius={5}
                            cursor='pointer'
                            onClick={(data) => onChartPointClick(value.name, data.payload.index)}
                          />
                        ))}
                      <ChartLegend content={<CustomChartLegend />} />
                    </BarChart>
                  </ChartContainer>
                </CardContent>
              </Card>
            )}
          </Grid>
          <Grid item xs={3}>
            <NameCheckBoxList
              loading={loading}
              items={insightValues?.map((value) => ({ name: value.name, isVisible: value.isVisible })) || []}
              selectedNames={selectedNames}
              setSelectedNames={setSelectedNames}
              height={VISUAL_PAPER_HEIGHT}
            />
          </Grid>
        </Grid>
      )}

      {viewType === ViewType.table && (
        <Card className='p-[20px_36px] rounded-xl border'>
          {loading || !insightValues ? (
            <SkeletonTable columnCount={8} rowCount={10} />
          ) : insightValues.length === 0 ? (
            <EmptyState title={t('features.insights.detail.noData')} />
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      '& .MuiTableCell-head': {
                        color: (theme) => theme.palette.text.secondary,
                        fontSize: '13px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    }}
                  >
                    <TableCell>{t('features.insights.detail.name')}</TableCell>
                    <TableCell align='center' sx={{ borderRight: (theme) => `1px solid ${theme.palette.divider}` }}>
                      <TableSortLabel
                        active={sort.target === 'total'}
                        direction={sort.target === 'total' && sort.orderBy === 'asc' ? 'asc' : 'desc'}
                        onClick={() => toggleSort('total')}
                        hideSortIcon
                      >
                        {t('features.insights.detail.customerCount')}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align='center'>
                      <TableSortLabel
                        active={sort.target === 'first'}
                        direction={sort.target === 'first' && sort.orderBy === 'asc' ? 'asc' : 'desc'}
                        onClick={() => toggleSort('first')}
                        hideSortIcon
                      >
                        {t('features.insights.detail.nthOrderInsight.first')}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align='center' sx={{ borderRight: (theme) => `1px solid ${theme.palette.divider}` }}>
                      <TableSortLabel
                        active={sort.target === 'secondPlus'}
                        direction={sort.target === 'secondPlus' && sort.orderBy === 'asc' ? 'asc' : 'desc'}
                        onClick={() => toggleSort('secondPlus')}
                        hideSortIcon
                      >
                        {t('features.insights.detail.nthOrderInsight.secondPlus')}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align='center'>
                      <TableSortLabel
                        active={sort.target === 'second'}
                        direction={sort.target === 'second' && sort.orderBy === 'asc' ? 'asc' : 'desc'}
                        onClick={() => toggleSort('second')}
                        hideSortIcon
                      >
                        {t('features.insights.detail.nthOrderInsight.second')}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align='center'>
                      <TableSortLabel
                        active={sort.target === 'third'}
                        direction={sort.target === 'third' && sort.orderBy === 'asc' ? 'asc' : 'desc'}
                        onClick={() => toggleSort('third')}
                        hideSortIcon
                      >
                        {t('features.insights.detail.nthOrderInsight.third')}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align='center'>
                      <TableSortLabel
                        active={sort.target === 'fourth'}
                        direction={sort.target === 'fourth' && sort.orderBy === 'asc' ? 'asc' : 'desc'}
                        onClick={() => toggleSort('fourth')}
                        hideSortIcon
                      >
                        {t('features.insights.detail.nthOrderInsight.fourth')}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align='center'>
                      <TableSortLabel
                        active={sort.target === 'fifthPlus'}
                        direction={sort.target === 'fifthPlus' && sort.orderBy === 'asc' ? 'asc' : 'desc'}
                        onClick={() => toggleSort('fifthPlus')}
                        hideSortIcon
                      >
                        {t('features.insights.detail.nthOrderInsight.fifthPlus')}
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedInsightValues.slice(page * TABLE_ROWS_PER_PAGE, page * TABLE_ROWS_PER_PAGE + TABLE_ROWS_PER_PAGE).map((row) => (
                    <TableRow key={row.name}>
                      <TableCell
                        component='th'
                        scope='row'
                        style={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                      >
                        <Tooltip title={row.name} placement='top'>
                          <span>{addIndexToName(row.name, row.rank)}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell align='center' sx={{ borderRight: (theme) => `1px solid ${theme.palette.divider}` }}>
                        <Tooltip title={t('features.insights.detail.customers', { count: row.total })} placement='top'>
                          <span>{row.total}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell align='center'>
                        <Tooltip title={t('features.insights.detail.customers', { count: row.first })} placement='top'>
                          <span>{row.first}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell align='center' sx={{ borderRight: (theme) => `1px solid ${theme.palette.divider}` }}>
                        <Tooltip title={t('features.insights.detail.customers', { count: row.secondPlus })} placement='top'>
                          <span>{row.secondPlus}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell align='center'>
                        <Tooltip title={t('features.insights.detail.customers', { count: row.second })} placement='top'>
                          <span>{row.second}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell align='center'>
                        <Tooltip title={t('features.insights.detail.customers', { count: row.third })} placement='top'>
                          <span>{row.third}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell align='center'>
                        <Tooltip title={t('features.insights.detail.customers', { count: row.fourth })} placement='top'>
                          <span>{row.fourth}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell align='center'>
                        <Tooltip title={t('features.insights.detail.customers', { count: row.fifthPlus })} placement='top'>
                          <span>{row.fifthPlus}</span>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {insightValues && insightValues.length > 0 && (
            <Box display='flex' justifyContent='center'>
              <Pagination
                shape='rounded'
                count={insightValues ? Math.ceil(insightValues.length / TABLE_ROWS_PER_PAGE) : 0}
                page={page + 1}
                onChange={(_, v) => setPage(v - 1)}
                sx={{ marginY: '20px' }}
              />
            </Box>
          )}
        </Card>
      )}
    </>
  )
}
