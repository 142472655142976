import { Button } from '@/components/ui/button'
import { TableCell, TableRow } from '@/components/ui/table'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { Collapse, Skeleton } from '@mui/material'
import { QuerySet } from 'features/customerSegments/querySetView/querySet'
import { CustomerSegmentState } from 'features/customerSegments/types/types'
import { SegmentGroup } from 'gen/firestore'
import { useCurrency } from 'hooks/useCurrency'
import { useActionTracker } from 'hooks/useMixpanel'
import { ChevronDown, ChevronUp, Ellipsis } from 'lucide-react'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { AUTHORIZED_ROUTE } from 'routing'
import { extractIconFromTextWithIcon, extractTextFromTextWithIcon } from 'utils/iconUtil'
import { convertQuerySetToForm, convertStringToApiQuerySetState } from '../querySetForm/schema/converter'
import { OverviewSegmentMenu } from './overviewSegmentMenu'

type Props = {
  row: CustomerSegmentState
  group?: SegmentGroup
  dragProps?: object
}

export const SegmentTableItem: FC<Props> = ({ row, group, dragProps }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { formatCurrency } = useCurrency()
  const { dispatch } = useActionTracker()

  const [collapseOpen, setCollapseOpen] = useState(false)

  return (
    <>
      <TableRow {...dragProps}>
        <TableCell>
          <Button
            variant='ghost'
            size='icon'
            onClick={() => {
              if (!collapseOpen) dispatch('OpenSegmentQueryset', { name: row.name })
              setCollapseOpen(!collapseOpen)
            }}
          >
            {collapseOpen ? <ChevronUp /> : <ChevronDown />}
          </Button>
        </TableCell>
        <TableCell className='cursor-pointer' onClick={() => navigate(generatePath(AUTHORIZED_ROUTE.CUSTOMER_SEGMENT_DETAIL, { id: row.id }))}>
          <div className='flex items-center gap-2'>
            <div className='text-lg'>{extractIconFromTextWithIcon(row.name)}</div>
            <div className='text-sm'>{extractTextFromTextWithIcon(row.name)}</div>
          </div>
        </TableCell>
        <TableCell className='text-center'>{row.metrics ? row.metrics.count.toLocaleString() : <Skeleton />}</TableCell>
        <TableCell className='text-center'>{row.metrics ? `${(row.metrics.repeaterRate * 100).toFixed(1)}%` : <Skeleton />}</TableCell>
        <TableCell className='text-center'>{row.metrics ? formatCurrency(row.metrics.ltv) : <Skeleton />}</TableCell>
        <TableCell className='text-center'>{row.metrics ? formatCurrency(row.metrics.avgOrderValue) : <Skeleton />}</TableCell>
        <TableCell className='text-center'>{row.metrics ? row.metrics.avgOrderCount.toFixed(2) : <Skeleton />}</TableCell>
        <TableCell className='text-center'>{row.metrics ? row.metrics.avgDaysBetweenOrders.toFixed(1) : <Skeleton />}</TableCell>
        <TableCell className='text-right'>
          <OverviewSegmentMenu
            trigger={
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <Button variant='ghost' size='icon'>
                      <Ellipsis />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>{t('features.customerSegments.components.segmentItem.menu')}</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            }
            customerSegment={row}
            group={group}
          />
        </TableCell>
      </TableRow>
      {collapseOpen && (
        <TableRow {...dragProps}>
          <TableCell colSpan={1} />
          <TableCell colSpan={8}>
            <Collapse in={collapseOpen} timeout='auto' unmountOnExit>
              {row.description && <div className='text-sm text-muted-foreground mt-2'>{row.description}</div>}
              <QuerySet querySet={convertQuerySetToForm(convertStringToApiQuerySetState(row.querySet))} />
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}
