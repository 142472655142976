import { Button } from '@/components/ui/button'
import { Dialog } from '@mui/material'
import { getIdToken } from 'firebase/auth'
import { BillingPlanIdentifier } from 'gen/firestore'
import { BillingService } from 'gen/proto/billing/billing_pb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { Loader2 } from 'lucide-react'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  open: boolean
  handleClose: () => void
}

export const DowngradeToFreeDialog: FC<Props> = ({ open, handleClose }) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()

  const authUser = useAuthUser()
  const billingService = useGrpcClient(BillingService)

  const [loading, setLoading] = useState(false)

  const downgradeToFree = async () => {
    setLoading(true)
    try {
      const token = await getIdToken(authUser!)
      await billingService.startChangePlanFlow({ plan: BillingPlanIdentifier.free }, { headers: { Authorization: `Bearer ${token}` } })
      enqueueSnackbar(t('features.settings.plan.downgradeToFreeDialog.messageSuccess'), { severity: 'success' })
      handleClose()
    } catch (err) {
      enqueueSnackbar(t('features.settings.plan.downgradeToFreeDialog.messageError'), { severity: 'error' })
      notifySentry(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='xs'
      fullWidth
      PaperProps={{
        sx: { borderRadius: '8px' },
      }}
    >
      <div className='flex flex-col gap-6 p-6'>
        <div className='flex flex-col gap-2'>
          <h2 className='text-lg font-bold'>{t('features.settings.plan.downgradeToFreeDialog.title')}</h2>
        </div>
        <div>
          <p className='text-sm'>{t('features.settings.plan.downgradeToFreeDialog.description')}</p>
        </div>
        <div className='flex justify-end gap-2'>
          <Button disabled={loading} onClick={handleClose}>
            {t('features.settings.plan.downgradeToFreeDialog.cancel')}
          </Button>
          <Button variant='outline' type='submit' disabled={loading} onClick={downgradeToFree}>
            {loading ? <Loader2 className='animate-spin' /> : t('features.settings.plan.downgradeToFreeDialog.submit')}
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
