import { DateRangePicker, DateRangePresetOption } from '@/components/dateRangePicker'
import { Button } from '@/components/ui/button'
import { useAccount } from '@/hooks/useAccount'
import { useTheme } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import { CustomerSegmentState } from 'features/customerSegments/types/types'
import { getIdToken } from 'firebase/auth'
import { CanvasState, SegmentGroup } from 'gen/firestore'
import { SegmentGroupService } from 'gen/proto/segment_group/segment_group_pb'
import { AggregateCustomerCountsResponse, SegmentTransitionService } from 'gen/proto/segment_transition/segment_transition_pb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { CheckCircle, Loader2, Pencil, Plus } from 'lucide-react'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactFlow, {
  addEdge,
  Background,
  Connection,
  Controls,
  Edge,
  MarkerType,
  MiniMap,
  Node,
  Position,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useReactFlow,
  useStoreApi,
  useViewport,
  Viewport,
  XYPosition,
} from 'reactflow'
import 'reactflow/dist/style.css'
import { AddNodeDialog } from './components/addNodeDialog'
import { CancelDialog } from './components/cancelDialog'
import { DeleteNodeDialog } from './components/deleteNodeDialog'
import { NodeContent } from './components/nodeContent'
import { SegmentDrawer } from './components/segmentDrawer'
import { SegmentTransitionDrawer } from './components/segmentTransitionDrawer'

type Props = {
  segmentGroup: SegmentGroup
  customerSegments: CustomerSegmentState[] | undefined
  defaultEndDate: dayjs.Dayjs
}

type SegmentDrawerState = {
  open: boolean
  segment: CustomerSegmentState | undefined
}

type SegmentTransionDrawerState = {
  open: boolean
  sourceId: string | undefined
  targetId: string | undefined
  isDataIncomplete: boolean
}

type DeleteNodeDialogState = {
  open: boolean
  targetNode: string | undefined // Node ID to delete
}

export const CanvasView: FC<Props> = ({ segmentGroup, customerSegments, defaultEndDate }) => {
  return (
    <ReactFlowProvider>
      <CanvasViewDetail segmentGroup={segmentGroup} customerSegments={customerSegments} defaultEndDate={defaultEndDate} />
    </ReactFlowProvider>
  )
}

const CanvasViewDetail: FC<Props> = ({ segmentGroup, customerSegments, defaultEndDate }) => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const theme = useTheme()
  const { shop } = useAccount()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()
  const segmentGroupService = useGrpcClient(SegmentGroupService)
  const segmentTransitionService = useGrpcClient(SegmentTransitionService)
  const store = useStoreApi()
  const reactFlow = useReactFlow()
  const { height: viewportHeight, width: viewportWidth } = store.getState()
  const viewPort = useViewport()

  const defaultSegmentDrawerState: SegmentDrawerState = { open: false, segment: undefined }
  const defaultSegmentTransionDrawerState: SegmentTransionDrawerState = {
    open: false,
    sourceId: undefined,
    targetId: undefined,
    isDataIncomplete: false,
  }
  const defaultDeleteNodeDialogState: DeleteNodeDialogState = { open: false, targetNode: undefined }

  const [isLoading, setIsLoading] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [isCanvasStateUpdating, setCanvasStateUpdating] = useState(false)
  const [isDataIncomplete, setIsDataIncomplete] = useState(false)

  const defaultCustomDateRange = DateRangePresetOption.last7Days
  const defaultStartDate = defaultEndDate.subtract(7, 'day')
  const [startDate, setStartDate] = useState<Dayjs>(defaultStartDate)
  const [endDate, setEndDate] = useState<Dayjs>(defaultEndDate)

  const [selectedSource, setSelectedSource] = useState('')
  const [selectedTarget, setSelectedTarget] = useState<string[]>([])

  const [addNodeDialogOpen, setAddNodeDialogOpen] = useState(false)
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false)
  const [deleteNodeDialogState, setDeleteNodeDialogState] = useState<DeleteNodeDialogState>(defaultDeleteNodeDialogState)

  const [segmentDrawerState, setsegmentDrawerState] = useState<SegmentDrawerState>(defaultSegmentDrawerState)
  const [segmentTransitionDrawerState, setSegmentTransitionDrawerState] = useState<SegmentTransionDrawerState>(defaultSegmentTransionDrawerState)

  const handleSegmentSelect = () => {
    if (!customerSegments) return
    const selectedTargetObject = customerSegments.find((segment) => selectedTarget.includes(segment.id))
    const selectedSourceObject = customerSegments.find((segment) => segment.id === selectedSource)

    if (selectedTargetObject) {
      if (selectedSourceObject) {
        addNodeFromSourceObject(selectedSourceObject, selectedTargetObject)
      } else {
        addNode(selectedTargetObject)
      }
    }
    setAddNodeDialogOpen(false)
  }

  const getSegmentNameFromId = (id: string): string | undefined => {
    const segmentObject = customerSegments?.find((segment) => segment.id === id)
    return segmentObject?.name
  }

  const createNode = (id: string, x: number, y: number) => {
    return {
      id: id,
      position: { x: x, y: y },
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
      style: {
        display: 'flex',
        flexDirection: 'column' as const,
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        borderColor: theme.palette.text.primary,
        borderRadius: '4px',
        minWidth: '200px',
      },
      data: {
        label: (
          <NodeContent
            name={getSegmentNameFromId(id) || ''}
            isEditMode={isEditMode}
            onClickAdd={() => {
              setSelectedSource(id)
              setAddNodeDialogOpen(true)
            }}
            onClickDelete={() => setDeleteNodeDialogState({ open: true, targetNode: id })}
          />
        ),
      },
    }
  }

  const createEdge = (id: string, source: string, target: string) => {
    return {
      id: id,
      source: source,
      target: target,
      animated: false,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 25,
        height: 25,
        color: theme.palette.text.primary,
      },
      labelStyle: {
        fontSize: '16px',
      },
      style: {
        stroke: theme.palette.text.primary,
      },
    }
  }

  const convertCanvasStateToFlowElements = (canvasState: CanvasState) => {
    if (!canvasState || !canvasState.nodes) {
      return { nodes: [], edges: [] }
    }
    const nodes: Node[] = canvasState.nodes.map((node) => createNode(node.id, node.x, node.y))
    const edges: Edge[] = canvasState.edges.map((edge) => createEdge(edge.id, edge.source, edge.target))
    return { nodes, edges }
  }

  const { nodes: initialNodes, edges: initialEdges } = convertCanvasStateToFlowElements(segmentGroup.canvasState)

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes)
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges)

  const onConnect = useCallback(
    (params: Edge | Connection) => {
      const newEdge = {
        ...params,
        markerEnd: {
          type: MarkerType.ArrowClosed,
          width: 25,
          height: 25,
          color: theme.palette.text.primary,
        },
      }
      setEdges((eds) => addEdge(newEdge, eds))
    },
    [setEdges, theme]
  )

  const handleNodeClick = useCallback(
    (node: Node) => {
      if (isEditMode || isLoading) {
        return
      } else {
        const segment = customerSegments?.find((segment) => segment.id === node.id)
        if (segment) setsegmentDrawerState({ open: true, segment })
      }
    },
    [isEditMode, isLoading, customerSegments]
  )

  const handleEdgeClick = useCallback(
    (edge: Edge) => {
      if (isLoading) {
        return
      } else if (isEditMode) {
        setEdges((edges) => edges.filter((e) => e.id !== edge.id))
      } else {
        setSegmentTransitionDrawerState({
          open: true,
          sourceId: edge.source,
          targetId: edge.target,
          isDataIncomplete: edge.data?.isDataIncomplete || false,
        })
      }
    },
    [isEditMode, isLoading, setEdges]
  )

  const resetAndInitializeCanvasState = () => {
    setNodes(initialNodes)
    setEdges(initialEdges)
  }

  useEffect(() => {
    setEdges((prevEdges) =>
      prevEdges.map((edge) => ({
        ...edge,
        label: isLoading ? '' : edge.label,
        animated: isLoading,
      }))
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  useEffect(() => {
    resetAndInitializeCanvasState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentGroup, isEditMode])

  // A value to ensure that the generated node positions do not overlap.
  // 10,20,30... and so on are added to the x,y axis when the node overlaps.
  const currentOverlapOffset = useRef(0)
  const overlapOffsetToAdd = 10

  const addNode = (targetSegment: CustomerSegmentState) => {
    const nodeCenterPosition = calcPositionForCenteringNode(viewportWidth, viewportHeight, viewPort)

    const xPosition = nodeCenterPosition.x + currentOverlapOffset.current
    const yPosition = nodeCenterPosition.y + currentOverlapOffset.current

    const newNode = createNode(targetSegment.id, xPosition, yPosition)
    setNodes((nodes) => nodes.concat(newNode))
    currentOverlapOffset.current += overlapOffsetToAdd
  }

  const addNodeFromSourceObject = (sourceSegment: CustomerSegmentState, targetSegment: CustomerSegmentState) => {
    const sourceObject = nodes.find((node) => node.id === sourceSegment.id)
    if (!sourceObject) {
      throw new Error(`${sourceSegment.id} does not exist in nodes.`)
    }
    const xPosition = sourceObject.position.x + 350 + currentOverlapOffset.current
    const yPosition = sourceObject.position.y + 50 + currentOverlapOffset.current

    const newEdge = createEdge(`edge-${sourceSegment.id}-${targetSegment.id}`, sourceSegment.id, targetSegment.id)
    setEdges((edges) => [...edges, newEdge])

    const newNode = createNode(targetSegment.id, xPosition, yPosition)
    setNodes((nodes) => nodes.concat(newNode))
    currentOverlapOffset.current += overlapOffsetToAdd
  }

  const calcPositionForCenteringNode = (viewportWidth: number, viewportHeight: number, viewPort: Viewport): XYPosition => {
    const zoomMultiplier = 1 / viewPort.zoom

    const centerX = -viewPort.x * zoomMultiplier + (viewportWidth * zoomMultiplier) / 2
    const centerY = -viewPort.y * zoomMultiplier + (viewportHeight * zoomMultiplier) / 2

    // The nodeWidth and nodeHeight values are experimental.
    // The values that are visually near the center of the canvas at any magnification are x:260 and y:150.
    const nodeWidth = 260
    const nodeHeight = 150

    const nodeWidthOffset = nodeWidth / 2
    const nodeHeightOffset = nodeHeight / 2

    const x = centerX - nodeWidthOffset
    const y = centerY - nodeHeightOffset

    return { x, y }
  }

  useEffect(() => {
    if (isEditMode) return // When in edit mode, don't fetch data

    const fetchAndDisplayEdgeLabels = async () => {
      setIsLoading(true)
      try {
        const token = await getIdToken(authUser!)
        const resp: AggregateCustomerCountsResponse = await segmentTransitionService.aggregateCustomerCounts(
          {
            transitions: edges.map((edge) => ({
              sourceCustomerSegmentId: edge.source,
              targetCustomerSegmentId: edge.target,
            })),
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD'),
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        let dataIncompleteFlag = false
        const updatedEdges = edges.map((edge) => {
          const result = resp.results.find((e) => e.sourceCustomerSegmentId === edge.source && e.targetCustomerSegmentId === edge.target)
          const count = Number(result?.customerCount) || 0
          if (result?.isDataIncomplete) {
            dataIncompleteFlag = true
          }
          return {
            ...edge,
            label: result?.isDataIncomplete ? `⚠️ ${count.toString()}` : count.toString(),
            data: { isDataIncomplete: result?.isDataIncomplete || false },
          }
        })
        setIsDataIncomplete(dataIncompleteFlag)
        setEdges((prevEdges) => {
          const newEdges = [...prevEdges]
          updatedEdges.forEach((updatedEdge) => {
            const index = newEdges.findIndex((edge) => edge.id === updatedEdge.id)
            if (index !== -1) {
              newEdges[index] = updatedEdge
            }
          })
          return newEdges
        })
      } catch (err) {
        enqueueSnackbar(t('features.customerSegments.group.canvasView.messageError'), { severity: 'error' })
        notifySentry(err)
      } finally {
        setIsLoading(false)
      }
    }

    fetchAndDisplayEdgeLabels()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode, startDate, endDate])

  const saveAndUpdateCanvasState = async () => {
    try {
      setCanvasStateUpdating(true)
      const token = await getIdToken(authUser!)
      await segmentGroupService.updateCanvasState(
        {
          segmentGroupId: segmentGroup.ref.id,
          nodes: nodes.map((node) => ({
            id: node.id,
            x: node.position.x,
            y: node.position.y,
          })),
          edges: edges.map((edge) => ({
            id: edge.id,
            source: edge.source,
            target: edge.target,
            label: edge.label,
            data: { label: edge.label },
          })),
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      enqueueSnackbar(t('features.customerSegments.group.canvasView.messageSaved'), { severity: 'success' })
      setIsEditMode(false)
    } catch (err) {
      enqueueSnackbar(t('features.customerSegments.group.canvasView.messageError'), { severity: 'error' })
      notifySentry(err)
    } finally {
      setCanvasStateUpdating(false)
    }
  }

  const handleFitView = () => {
    if (isEditMode) return
    if (nodes.length > 0 || edges.length > 0) {
      reactFlow.fitView({ padding: 0.1, includeHiddenNodes: false, minZoom: 0.5, maxZoom: 2, duration: 1000 })
    } else {
      reactFlow.setViewport({
        x: 0,
        y: 0,
        zoom: 1,
      })
    }
  }

  const resetOverlapOffset = () => {
    currentOverlapOffset.current = 0
  }

  return (
    <>
      <div className='flex flex-col gap-4'>
        {!isEditMode ? (
          <div className='flex justify-between items-center'>
            <div className='flex justify-between items-center gap-4'>
              <DateRangePicker
                dateRangePresetOption={defaultCustomDateRange}
                maxEndDate={defaultEndDate}
                timezone={shop.timezone}
                onApplyDateRangeChange={(dateRange) => {
                  setStartDate(dateRange.startDate)
                  setEndDate(dateRange.endDate)
                }}
              />
              {!isLoading && isDataIncomplete && (
                <p className='text-sm text-muted-foreground'>{t('features.customerSegments.group.canvasView.dataIncomplete')}</p>
              )}
            </div>
            <Button
              variant='outline'
              size='sm'
              onClick={() => {
                setIsEditMode(true)
                resetAndInitializeCanvasState()
              }}
            >
              <Pencil />
              {t('features.customerSegments.group.canvasView.edit')}
            </Button>
          </div>
        ) : (
          <div className='flex justify-between items-center'>
            <Button variant='outline' size='sm' onClick={() => setAddNodeDialogOpen(true)}>
              <Plus />
              {t('features.customerSegments.group.canvasView.add')}
            </Button>
            <div className='flex justify-end items-center gap-2'>
              <Button variant='secondary' size='sm' onClick={() => setCancelDialogOpen(true)}>
                {t('features.customerSegments.group.canvasView.cancel')}
              </Button>
              <Button size='sm' onClick={() => saveAndUpdateCanvasState()}>
                {isCanvasStateUpdating ? <Loader2 className='animate-spin' /> : <CheckCircle />}
                {t('features.customerSegments.group.canvasView.save')}
              </Button>
            </div>
          </div>
        )}

        <div className='w-full h-[75vh]'>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={(nodes) => {
              onNodesChange(nodes)
              handleFitView()
            }}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onNodeClick={(_, node) => handleNodeClick(node)}
            onEdgeClick={(_, edge) => handleEdgeClick(edge)}
            onMove={resetOverlapOffset}
            nodesDraggable={isEditMode}
            nodesConnectable={isEditMode}
            elementsSelectable={isEditMode}
          >
            <Controls showInteractive={false} />
            <Background gap={12} size={1} />
            <MiniMap />
          </ReactFlow>
        </div>
      </div>

      <SegmentDrawer
        open={segmentDrawerState.open}
        handleOpen={() => setsegmentDrawerState({ open: true, segment: segmentDrawerState.segment })}
        handleClose={() => setsegmentDrawerState({ open: false, segment: undefined })}
        segment={segmentDrawerState.segment}
      />

      <SegmentTransitionDrawer
        open={segmentTransitionDrawerState.open}
        handleClose={() => setSegmentTransitionDrawerState(defaultSegmentTransionDrawerState)}
        handleOpen={() => setSegmentTransitionDrawerState((prevState) => ({ ...prevState, open: true }))}
        sourceId={segmentTransitionDrawerState.sourceId}
        targetId={segmentTransitionDrawerState.targetId}
        sourceName={getSegmentNameFromId(segmentTransitionDrawerState.sourceId || '')}
        targetName={getSegmentNameFromId(segmentTransitionDrawerState.targetId || '')}
        startDate={startDate}
        endDate={endDate ?? defaultEndDate}
        isDataIncomplete={segmentTransitionDrawerState.isDataIncomplete}
      />

      <AddNodeDialog
        open={addNodeDialogOpen}
        onClose={() => setAddNodeDialogOpen(false)}
        onSelect={handleSegmentSelect}
        isLoading={isLoading}
        customerSegments={customerSegments}
        nodes={nodes}
        selectedTarget={selectedTarget}
        setSelectedTarget={setSelectedTarget}
      />

      <CancelDialog
        open={cancelDialogOpen}
        handleClose={() => setCancelDialogOpen(false)}
        handleSubmit={() => {
          setCancelDialogOpen(false)
          resetAndInitializeCanvasState()
          setIsEditMode(!isEditMode)
        }}
      />

      <DeleteNodeDialog
        open={deleteNodeDialogState.open}
        handleClose={() => setDeleteNodeDialogState(defaultDeleteNodeDialogState)}
        handleSubmit={() => {
          const nodeToDelete = deleteNodeDialogState.targetNode
          setNodes((nds) => nds.filter((node) => node.id !== nodeToDelete))
          setEdges((eds) => eds.filter((edge) => edge.source !== nodeToDelete && edge.target !== nodeToDelete))
          setDeleteNodeDialogState(defaultDeleteNodeDialogState)
        }}
      />
    </>
  )
}
