import * as React from "react"

import { cn } from "@/lib/utils"

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  endAdornment?: React.ReactNode;
}

const CustomInput = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, endAdornment, ...props }, ref) => {
  return (
    <div
      className={cn("flex items-center justify-between gap-2 p-2 h-9 rounded-md border border-input bg-background text-sm ring-offset-background disabled:cursor-not-allowed disabled:opacity-50",className)}>
      <input
        type={type}
        className={cn("pl-3 py-1 min-w-[80px] max-w-[120px] placeholder:text-muted-foreground disabled:opacity-50")}
        ref={ref}
        {...props}
      />
      {endAdornment && <span className="text-sm text-muted-foreground">{endAdornment}</span>}
    </div>
  )
})

CustomInput.displayName = "Input"

export { CustomInput }
