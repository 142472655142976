import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Separator } from '@/components/ui/separator'
import { Skeleton } from '@/components/ui/skeleton'
import { Table, TableBody, TableCell, TableRow } from '@/components/ui/table'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { Shop } from 'gen/firestore'
import { Pencil } from 'lucide-react'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UpdateOrderPricingRuleDialog } from './components/updateOrderPricingRuleDialog'

type Props = {
  shop: Shop | undefined
}

export const Workspace: FC<Props> = ({ shop }) => {
  const { t } = useTranslation()

  const [updateOrderPricingRuleDialogOpen, setUpdateOrderPricingRuleDialogOpen] = useState(false)

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>{t('features.settings.workspace.general')}</CardTitle>
        </CardHeader>
        <CardContent>
          <Table>
            <TableBody>
              <TableRow className='border-none'>
                <TableCell className='w-[180px]'>{t('features.settings.workspace.name')}</TableCell>
                <TableCell>{shop?.displayName || <Skeleton className='w-[120px]' />}</TableCell>
              </TableRow>
              <TableRow className='border-none'>
                <TableCell className='w-[180px]'>{t('features.settings.workspace.shopName')}</TableCell>
                <TableCell>{shop?.shopName || <Skeleton className='w-[120px]' />}</TableCell>
              </TableRow>
              <TableRow className='border-none'>
                <TableCell className='w-[180px]'>{t('features.settings.workspace.currency')}</TableCell>
                <TableCell>{shop?.currency || <Skeleton className='w-[120px]' />}</TableCell>
              </TableRow>
              <TableRow className='border-none'>
                <TableCell className='w-[180px]'>{t('features.settings.workspace.timezone')}</TableCell>
                <TableCell>{shop?.timezone || <Skeleton className='w-[120px]' />}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>

        <Separator />

        <CardHeader>
          <CardTitle>{t('features.settings.workspace.orderPricing')}</CardTitle>
        </CardHeader>
        <CardContent>
          <Table>
            <TableBody>
              <TableRow className='border-none'>
                <TableCell className='w-[180px]'>{t('features.settings.workspace.rule')}</TableCell>
                <TableCell>
                  <div className='flex items-center gap-4'>
                    {shop ? (
                      t('features.settings.workspace.orderPricingRule', { context: shop.orderPricingRule })
                    ) : (
                      <Skeleton className='w-[120px]' />
                    )}
                    <Tooltip>
                      <TooltipTrigger>
                        <Pencil
                          className='w-3 h-3 cursor-pointer hover:text-muted-foreground'
                          onClick={() => setUpdateOrderPricingRuleDialogOpen(true)}
                        />
                      </TooltipTrigger>
                      <TooltipContent>{t('features.settings.edit')}</TooltipContent>
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>

      {shop && (
        <UpdateOrderPricingRuleDialog
          open={updateOrderPricingRuleDialogOpen}
          shop={shop}
          handleClose={() => setUpdateOrderPricingRuleDialogOpen(false)}
        />
      )}
    </>
  )
}
