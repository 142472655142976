import { Button } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Alert, Box, List, ListItem, ListItemButton, ListItemText, Pagination, Skeleton, SwipeableDrawer, Typography } from '@mui/material'
import { MetricsItems } from 'components/metricsItems'
import { Dayjs } from 'dayjs'
import { CustomerDrawer } from 'features/customers/customerDrawer'
import { getIdToken } from 'firebase/auth'
import { CustomerMetrics } from 'gen/firestore'
import { SegmentTransitionService } from 'gen/proto/segment_transition/segment_transition_pb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useGrpcClient } from 'hooks/useGrpcClient'
import { X } from 'lucide-react'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatDate } from 'utils/timeUtil'

const CUSTOMERS_PER_PAGE = 10

type CustomerDrawerState = {
  open: boolean
  customer:
    | {
        id: string
        name: string
        email: string
      }
    | undefined
}

type Props = {
  open: boolean
  handleClose: () => void
  handleOpen: () => void
  sourceId: string | undefined
  targetId: string | undefined
  sourceName: string | undefined
  targetName: string | undefined
  startDate: Dayjs
  endDate: Dayjs
  isDataIncomplete: boolean
}

export const SegmentTransitionDrawer: FC<Props> = ({
  open,
  handleClose,
  handleOpen,
  sourceId,
  sourceName,
  targetId,
  targetName,
  startDate,
  endDate,
  isDataIncomplete,
}) => {
  const { t, i18n } = useTranslation()
  const authUser = useAuthUser()
  const segmentTransitionService = useGrpcClient(SegmentTransitionService)

  const [isLoading, setIsLoading] = useState(false)
  const [customers, setCustomers] = useState<Array<{ id: string; name: string; email: string }>>([])
  const [metrics, setMetrics] = useState<CustomerMetrics | undefined>()
  const [page, setPage] = useState(0)
  const [customerDrawerState, setCustomerDrawerState] = useState<CustomerDrawerState>({ open: false, customer: undefined })

  useEffect(() => {
    if (!sourceId || !targetId) return
    const fetchMetrics = async () => {
      setIsLoading(true)
      try {
        const token = await getIdToken(authUser!)
        const response = await segmentTransitionService.aggregateCustomerMetrics(
          {
            sourceCustomerSegmentId: sourceId,
            targetCustomerSegmentId: targetId,
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD'),
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        setMetrics({
          count: Number(response.customerCount),
          repeaterRate: Number(response.repeaterRate),
          ltv: Number(response.ltv),
          avgOrderValue: Number(response.avgOrderValue),
          avgOrderCount: response.avgOrderCount,
          avgDaysBetweenOrders: response.avgDaysBetweenOrders,
        })
        setCustomers(response.customers.map((c) => ({ id: c.id, name: `${c.firstName} ${c.lastName}`, email: c.email })))
      } catch (error) {
        console.error('Failed to fetch customer metrics:', error)
      }
      setIsLoading(false)
    }

    fetchMetrics()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceId, targetId, startDate, endDate])

  return (
    <>
      <SwipeableDrawer anchor='right' open={open} onOpen={handleOpen} onClose={handleClose}>
        <div className='w-[360px]'>
          <div className='flex justify-between items-center p-4'>
            <p className='text-sm font-bold'>{t('features.customerSegments.group.edgeDrawer.title')}</p>
            <Button variant='ghost' size='icon' onClick={handleClose}>
              <X />
            </Button>
          </div>

          <Separator />

          <div className='flex flex-col gap-4 p-4'>
            {isDataIncomplete && (
              <Alert severity='warning' icon={false} sx={{ padding: '4px 12px', marginBottom: '8px' }}>
                {t('features.customerSegments.group.canvasView.dataIncomplete')}
              </Alert>
            )}

            <div className='p-2'>
              {[
                {
                  label: t('features.customerSegments.group.edgeDrawer.source'),
                  value: sourceName || <Skeleton width='48px' />,
                },
                {
                  label: t('features.customerSegments.group.edgeDrawer.target'),
                  value: targetName || <Skeleton width='48px' />,
                },
                {
                  label: t('features.customerSegments.group.edgeDrawer.period'),
                  value: `${formatDate(startDate, i18n.language)} - ${formatDate(endDate, i18n.language)}`,
                },
              ].map((item, index) => (
                <Box key={index} display='flex' justifyContent='space-between' marginBottom={index < 2 ? '16px' : '0'}>
                  <Typography variant='body2' color='textSecondary'>
                    {item.label}
                  </Typography>
                  <Typography variant='body2'>{item.value}</Typography>
                </Box>
              ))}
            </div>

            <Separator />

            <div className='p-2'>
              <MetricsItems metrics={!isLoading ? metrics : undefined} type='list' />
            </div>

            <Separator />

            <div>
              {isLoading ? (
                <List sx={{ padding: '0px 8px' }}>
                  {[...Array(5)].map((_, index) => (
                    <ListItem key={index} sx={{ paddingLeft: 0 }}>
                      <Box display='flex' justifyContent='space-between' width='100%'>
                        <Skeleton width='75%' height='18px' />
                        <Skeleton width='15%' height='18px' />
                      </Box>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <>
                  <List sx={{ padding: '0px 8px' }}>
                    {customers.slice(page * CUSTOMERS_PER_PAGE, page * CUSTOMERS_PER_PAGE + CUSTOMERS_PER_PAGE).map((customer, i) => (
                      <ListItem
                        key={i}
                        onClick={() =>
                          setCustomerDrawerState({
                            open: true,
                            customer: { id: customer.id, name: customer.name, email: customer.email },
                          })
                        }
                        dense
                        disableGutters
                      >
                        <ListItemButton dense disableGutters sx={{ paddingX: '8px', borderRadius: '4px' }}>
                          <ListItemText primary={<Typography variant='body2'>{customer.name}</Typography>} />
                          <ArrowForwardIosIcon fontSize='small' sx={{ fontSize: '12px' }} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                  <Box display='flex' justifyContent='center' marginY='12px'>
                    <Pagination
                      shape='rounded'
                      count={customers ? Math.ceil(customers.length / CUSTOMERS_PER_PAGE) : 0}
                      page={page + 1}
                      onChange={(_, v) => setPage(v - 1)}
                    />
                  </Box>
                </>
              )}
            </div>
          </div>
        </div>
      </SwipeableDrawer>

      <CustomerDrawer
        open={customerDrawerState.open}
        handleOpen={() => setCustomerDrawerState({ open: true, customer: customerDrawerState.customer })}
        handleClose={() => setCustomerDrawerState({ open: false, customer: undefined })}
        customer={customerDrawerState.customer}
      />
    </>
  )
}
