import { Button } from '@/components/ui/button'
import { BillingService } from '@/gen/proto/billing/billing_pb'
import { useAuthUser } from '@/hooks/useAuthUser'
import { useGrpcClient } from '@/hooks/useGrpcClient'
import { useSentryNotifier } from '@/hooks/useSentryNotifier'
import { AUTHORIZED_ROUTE } from '@/routing'
import { getIdToken } from 'firebase/auth'
import { LoaderCircle } from 'lucide-react'
import { FC, useEffect, useState } from 'react'
import Confetti from 'react-confetti'
import { useTranslation } from 'react-i18next'

export const ThankYou: FC = () => {
  const { t } = useTranslation()
  const authUser = useAuthUser()
  const { notifySentry } = useSentryNotifier()
  const billingService = useGrpcClient(BillingService)

  const [syncing, setSyncing] = useState(true)

  useEffect(() => {
    const syncActivePlanFromShopify = async () => {
      try {
        const token = await getIdToken(authUser!)
        await billingService.syncActivePlanFromShopify({}, { headers: { Authorization: `Bearer ${token}` } })
      } catch (err) {
        notifySentry(err)
      } finally {
        setSyncing(false)
      }
    }

    syncActivePlanFromShopify()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {syncing ? (
        <div className='flex justify-center items-center h-screen'>
          <LoaderCircle className='animate-spin' />
        </div>
      ) : (
        <div className='flex flex-col items-center justify-center h-screen'>
          <div className='flex flex-col items-center gap-4'>
            <img src='/images/plan/thank_you.svg' alt='upgradeThankYou' className='w-1/2' />
            <h1 className='text-xl font-bold text-center'>{t('features.thankYou.title')}</h1>
            <p className='text-md text-center'>{t('features.thankYou.description')}</p>
            <Button
              onClick={() => {
                window.location.href = AUTHORIZED_ROUTE.HOME // redirect to home (refresh)
              }}
            >
              {t('features.thankYou.button')}
            </Button>
          </div>
          <Confetti numberOfPieces={200} />
        </div>
      )}
    </>
  )
}
