import { PageContentWrapper } from '@/components/pageContentWrapper'
import { PageHeader } from '@/components/pageHeader'
import { Button } from '@/components/ui/button'
import { Table, TableBody, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { useAccount } from '@/hooks/useAccount'
import { closestCenter, DndContext, DragEndEvent, DragStartEvent, MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToParentElement } from '@dnd-kit/modifiers'
import { arrayMove, SortableContext, useSortable } from '@dnd-kit/sortable'
import { Card, CardActionArea, CardContent, Grid, Skeleton, useTheme } from '@mui/material'
import { EmptyState } from 'components/emptyState'
import { SkeletonTable } from 'components/skeletonTable'
import dayjs from 'dayjs'
import { SegmentGridItem } from 'features/customerSegments/components/segmentGridItem'
import { SegmentTableItem } from 'features/customerSegments/components/segmentTableItem'
import { CustomerSegmentState } from 'features/customerSegments/types/types'
import { getIdToken } from 'firebase/auth'
import { SegmentGroup } from 'gen/firestore'
import { SegmentGroupService } from 'gen/proto/segment_group/segment_group_pb'
import { useAuthUser } from 'hooks/useAuthUser'
import { useCsvDownload } from 'hooks/useCsvDownload'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useGrpcClient } from 'hooks/useGrpcClient'
import useLocalStorage from 'hooks/useLocalStrage'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { Download, LayoutGrid, Pencil, Rows3, Search, Trash } from 'lucide-react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { extractTextFromTextWithIcon } from 'utils/iconUtil'
import { formatDate, timestampToDayjs } from 'utils/timeUtil'
import { AddSegmentDialog } from './components/addSegmentDialog'
import { CanvasView } from './components/canvasView'
import { DeleteDialog } from './components/deleteDialog'
import { UpdateDialog } from './components/updateDialog'
import { useGroupSegments } from './hooks/useGroupSegments'

export enum ViewType {
  grid = 'grid',
  table = 'table',
  canvas = 'canvas',
}

type AddSegmentDialogState = {
  open: boolean
  group: SegmentGroup | undefined
}

export const CustomerSegmentGroup = () => {
  const { enqueueSnackbar } = useCustomSnackbar()
  const { t, i18n } = useTranslation()
  const { notifySentry } = useSentryNotifier()
  const authUser = useAuthUser()
  const { shop } = useAccount()
  const segmentGroupService = useGrpcClient(SegmentGroupService)

  const params = useParams()
  const groupId = params.id

  const { segmentGroup, customerSegments } = useGroupSegments(groupId)

  const [searchOpen, setSearchOpen] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const [viewType, setViewType] = useLocalStorage<ViewType>('SegmentGroupViewType', ViewType.table)

  const filteredCustomerSegments = useMemo(() => {
    return customerSegments?.filter((row) => !searchInput || row.name.toLowerCase().includes(searchInput.toLowerCase()))
  }, [customerSegments, searchInput])

  const [draggableCustomerSegments, setDraggableCustomerSegments] = useState(filteredCustomerSegments)

  useEffect(() => {
    setDraggableCustomerSegments(filteredCustomerSegments)
  }, [filteredCustomerSegments])

  const [updateDialogOpen, setUpdateDialogOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

  const [addSegmentDialogState, setAddSegmentDialogState] = useState<AddSegmentDialogState>({ open: false, group: undefined })

  const { handleDownload, loading: downloadLoading } = useCsvDownload({
    data: filteredCustomerSegments?.map((row) => {
      return {
        segmentName: extractTextFromTextWithIcon(row.name),
        count: row.metrics?.count,
        repeaterRate: row.metrics?.repeaterRate.toFixed(3),
        ltv: row.metrics?.ltv,
        avgOrderValue: row.metrics?.avgOrderValue,
        avgOrderCount: row.metrics?.avgOrderCount.toFixed(2),
        avgDaysBetweenOrders: row.metrics?.avgDaysBetweenOrders.toFixed(1),
      }
    }),
    headers: [
      t('features.customerSegments.group.name'),
      t('common.customerMetrics.title', { context: 'count' }),
      t('common.customerMetrics.title', { context: 'repeaterRate' }),
      t('common.customerMetrics.title', { context: 'ltv' }),
      t('common.customerMetrics.title', { context: 'avgOrderValue' }),
      t('common.customerMetrics.title', { context: 'avgOrderCount' }),
      t('common.customerMetrics.title', { context: 'avgDaysBetweenOrders' }),
    ],
    fileName: t('features.customerSegments.group.downloadFileName', { group: segmentGroup?.name, date: formatDate(dayjs(), i18n.language) }),
    page: 'customerSegments',
  })

  const saveNewSegmentsSequence = async (customerSegments: CustomerSegmentState[], segmentGroup: SegmentGroup) => {
    if (!segmentGroup || customerSegments.length === 0) return
    try {
      const token = await getIdToken(authUser!)
      await segmentGroupService.updateSegmentsSequence(
        {
          segmentGroupId: segmentGroup.ref.id,
          segmentIdsInSequence: customerSegments.map((customerSegment) => customerSegment.id),
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
    } catch (err) {
      enqueueSnackbar(t('features.customerSegments.group.'), { severity: 'error' })
      notifySentry(err)
    }
  }
  const [draggingSegmentId, setDraggingSegmentId] = useState<string | undefined>(undefined)

  const handleDragStart = (event: DragStartEvent) => {
    const activeId = event.active.id as string
    if (activeId !== undefined) {
      setDraggingSegmentId(activeId)
    }
  }

  const handleDragEnd = (event: DragEndEvent, segmentGroup: SegmentGroup) => {
    setDraggingSegmentId(undefined)

    const { active, over } = event

    if (over && active.id !== over.id) {
      setDraggableCustomerSegments((prevItems) => {
        if (prevItems) {
          const oldIndex = prevItems.findIndex((item) => item.id === active.id)
          const newIndex = prevItems.findIndex((item) => item.id === over.id)
          const newItems = arrayMove(prevItems, oldIndex, newIndex)

          saveNewSegmentsSequence(newItems, segmentGroup)

          return newItems
        }
      })
    }
  }

  const dndSensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5, // Dragging more than 5px will initiate the drag operation.
      },
    })
  )

  const isActiveSearch = useMemo(() => {
    if (!customerSegments || !filteredCustomerSegments) {
      return false
    }
    return customerSegments.length !== filteredCustomerSegments.length
  }, [customerSegments, filteredCustomerSegments])

  return (
    <>
      <PageHeader
        title={segmentGroup?.name || ''}
        menuComponent={
          <>
            {viewType === ViewType.canvas ? (
              <Button size='sm' variant='outline' onClick={() => setViewType(ViewType.table)} className='ml-3'>
                {t('features.customerSegments.group.closeCanvas')}
              </Button>
            ) : (
              <>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button variant='ghost' size='icon' onClick={() => setViewType(viewType === ViewType.grid ? ViewType.table : ViewType.grid)}>
                      {viewType === ViewType.grid ? <Rows3 /> : <LayoutGrid />}
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    {viewType === ViewType.grid ? t('features.customerSegments.group.tableView') : t('features.customerSegments.group.gridView')}
                  </TooltipContent>
                </Tooltip>
                <div className='flex items-center'>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button variant='ghost' size='icon' onClick={() => setSearchOpen(!searchOpen)} className='rounded-md'>
                        <Search className='h-4 w-4' />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>{t('features.customerSegments.group.search')}</TooltipContent>
                  </Tooltip>
                  {searchOpen && (
                    <input
                      type='text'
                      autoFocus
                      className='border-b border-gray-300 focus:border-gray-500 focus:outline-none text-sm'
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                  )}
                </div>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant='ghost'
                      size='icon'
                      disabled={!filteredCustomerSegments || filteredCustomerSegments.length === 0 || downloadLoading}
                      onClick={handleDownload}
                    >
                      <Download />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>{t('features.customerSegments.group.download')}</TooltipContent>
                </Tooltip>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button variant='ghost' size='icon' onClick={() => setUpdateDialogOpen(true)}>
                      <Pencil />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>{t('features.customerSegments.group.edit')}</TooltipContent>
                </Tooltip>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button variant='ghost' size='icon' onClick={() => setDeleteDialogOpen(true)}>
                      <Trash />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>{t('features.customerSegments.group.delete')}</TooltipContent>
                </Tooltip>
                <Button size='sm' variant='outline' disabled={!segmentGroup} onClick={() => setViewType(ViewType.canvas)} className='ml-3'>
                  {t('features.customerSegments.group.openCanvas')}
                </Button>
                <Button
                  size='sm'
                  disabled={!segmentGroup}
                  onClick={() => setAddSegmentDialogState({ open: true, group: segmentGroup })}
                  className='ml-3'
                >
                  {t('features.customerSegments.group.add')}
                </Button>
              </>
            )}
          </>
        }
      />

      <PageContentWrapper>
        {customerSegments &&
          (customerSegments.length === 0 ? (
            <EmptyState
              title={t('features.customerSegments.group.groupIsEmpty')}
              buttonText={t('features.customerSegments.group.add')}
              buttonOnClick={() => setAddSegmentDialogState({ open: true, group: segmentGroup })}
            />
          ) : (
            filteredCustomerSegments?.length === 0 && (
              <EmptyState title={t('features.customerSegments.group.searchResultZero', { input: searchInput })} />
            )
          ))}

        {viewType === ViewType.table &&
          (!filteredCustomerSegments ? (
            <SkeletonTable rowCount={3} columnCount={9} />
          ) : (
            filteredCustomerSegments.length > 0 && (
              <div className='rounded-md border'>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead />
                      <TableHead>{t('features.customerSegments.group.name')}</TableHead>
                      <TableHead className='text-center'>{t('common.customerMetrics.title_count')}</TableHead>
                      <TableHead className='text-center'>{t('common.customerMetrics.title_repeaterRate')}</TableHead>
                      <TableHead className='text-center'>{t('common.customerMetrics.title_ltv')}</TableHead>
                      <TableHead className='text-center'>{t('common.customerMetrics.title_avgOrderValue')}</TableHead>
                      <TableHead className='text-center'>{t('common.customerMetrics.title_avgOrderCount')}</TableHead>
                      <TableHead className='text-center'>{t('common.customerMetrics.title_avgDaysBetweenOrders')}</TableHead>
                      <TableHead />
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {segmentGroup && draggableCustomerSegments && (
                      <DndContext
                        sensors={dndSensors}
                        collisionDetection={closestCenter}
                        onDragStart={handleDragStart}
                        onDragEnd={(e) => {
                          handleDragEnd(e, segmentGroup)
                        }}
                        modifiers={[restrictToParentElement]}
                      >
                        <SortableContext items={draggableCustomerSegments.map((item) => item.id)}>
                          {draggableCustomerSegments.map((row) => (
                            <DraggableSegmentItem
                              key={row.id}
                              row={row}
                              group={segmentGroup}
                              viewType={viewType}
                              isDraggable={isActiveSearch}
                              draggingSegmentId={draggingSegmentId}
                            />
                          ))}
                        </SortableContext>
                      </DndContext>
                    )}
                  </TableBody>
                </Table>
              </div>
            )
          ))}

        {viewType === ViewType.grid && (
          <Grid container spacing={3} alignItems='stretch' sx={{ padding: '8px 4px' }}>
            {!filteredCustomerSegments
              ? Array.from(new Array(8)).map((_, i) => (
                  <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
                    <Card variant='outlined' sx={{ borderRadius: '12px' }}>
                      <CardActionArea>
                        <CardContent>
                          <Skeleton width={30} height={50} animation='wave' />
                          <Skeleton width='60%' height={16} animation='wave' />
                          <Skeleton width='100%' height={16} animation='wave' />
                          <Skeleton width='100%' height={16} animation='wave' />
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))
              : segmentGroup &&
                draggableCustomerSegments && (
                  <DndContext
                    sensors={dndSensors}
                    collisionDetection={closestCenter}
                    onDragStart={handleDragStart}
                    onDragEnd={(e) => {
                      handleDragEnd(e, segmentGroup)
                    }}
                    modifiers={[restrictToParentElement]}
                  >
                    <SortableContext items={draggableCustomerSegments.map((item) => item.id)}>
                      {draggableCustomerSegments.map((row) => (
                        <DraggableSegmentItem
                          key={row.id}
                          row={row}
                          group={segmentGroup}
                          viewType={viewType}
                          isDraggable={isActiveSearch}
                          draggingSegmentId={draggingSegmentId}
                        />
                      ))}
                    </SortableContext>
                  </DndContext>
                )}
          </Grid>
        )}

        {viewType === ViewType.canvas && segmentGroup && filteredCustomerSegments && filteredCustomerSegments.length > 0 && (
          <CanvasView
            segmentGroup={segmentGroup}
            customerSegments={filteredCustomerSegments}
            defaultEndDate={(shop.lastDatasetEtlCompletedAt && timestampToDayjs(shop.lastDatasetEtlCompletedAt).tz(shop.timezone)) || dayjs()}
          />
        )}
      </PageContentWrapper>

      {addSegmentDialogState.open && addSegmentDialogState.group && (
        <AddSegmentDialog
          open={addSegmentDialogState.open}
          group={addSegmentDialogState.group}
          handleClose={() => setAddSegmentDialogState({ open: false, group: undefined })}
        />
      )}

      {updateDialogOpen && segmentGroup && (
        <UpdateDialog open={updateDialogOpen} handleClose={() => setUpdateDialogOpen(false)} id={segmentGroup.ref.id} name={segmentGroup.name} />
      )}

      {deleteDialogOpen && segmentGroup && (
        <DeleteDialog open={deleteDialogOpen} handleClose={() => setDeleteDialogOpen(false)} id={segmentGroup.ref.id} name={segmentGroup.name} />
      )}
    </>
  )
}
type DraggableSegmentItemProps = {
  row: CustomerSegmentState
  group: SegmentGroup
  viewType: ViewType
  isDraggable: boolean
  draggingSegmentId: string | undefined
}

const DraggableSegmentItem = ({ row, group, viewType, isDraggable, draggingSegmentId }: DraggableSegmentItemProps) => {
  /*
  When viewType is table, the following warning appears in the console.
  ====================================
  Warning: validateDOMNesting(...):
  <div> cannot appear as a child of <tbody>.
  ====================================
  This warning has no real harm on the UI, so we skip its resolution.
  This warning is caused by the insertion of a div tag automatically generated by dnd kit inside a table tag.
  */
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: row.id })
  const theme = useTheme()
  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : '',
    zIndex: draggingSegmentId === row.id ? '1' : '0',
    position: 'relative',
    background: draggingSegmentId === row.id && viewType === ViewType.table ? theme.palette.background.paper : 'inherit',
    transition,
  }
  const dragProps = isDraggable ? {} : { ref: setNodeRef, style, ...attributes, ...listeners }
  return (
    <>
      {viewType === ViewType.grid ? (
        <Grid item xs={12} sm={6} md={4} lg={3} {...dragProps}>
          <SegmentGridItem key={row.id} row={row} group={group} />
        </Grid>
      ) : (
        <SegmentTableItem key={row.id} row={row} group={group} dragProps={dragProps} />
      )}
    </>
  )
}
