import { Button } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { cn } from '@/lib/utils'
import { SquarePlus, Trash2 } from 'lucide-react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  name: string
  isEditMode: boolean
  onClickAdd: () => void
  onClickDelete: () => void
}

export const NodeContent: FC<Props> = ({ name, isEditMode, onClickAdd, onClickDelete }) => {
  const { t } = useTranslation()

  return (
    <div className='flex flex-col gap-2'>
      <div className={cn('flex justify-center items-center text-sm p-4', { 'cursor-grab': isEditMode, 'cursor-pointer': !isEditMode })}>{name}</div>

      {isEditMode && (
        <>
          <Separator />
          <div className='flex justify-end'>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <Button variant='ghost' size='icon' onClick={onClickDelete}>
                    <Trash2 className='text-muted-foreground' />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>{t('features.customerSegments.group.canvasView.delete')}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <Button variant='ghost' size='icon' onClick={onClickAdd}>
                    <SquarePlus />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>{t('features.customerSegments.group.canvasView.addTarget')}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </>
      )}
    </div>
  )
}
