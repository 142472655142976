import { Avatar, AvatarImage } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { auth } from 'config/firebaseClient'
import { signOut } from 'firebase/auth'
import { useAccount } from 'hooks/useAccount'
import { useCustomSnackbar } from 'hooks/useCustomSnackbar'
import { useSentryNotifier } from 'hooks/useSentryNotifier'
import { CircleUser, LogOut } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

const USER_AVATAR_PATH = '/images/avatar.svg'

export const LimitedAccessLayout = () => {
  const { t } = useTranslation()
  const { account, shop } = useAccount()
  const { enqueueSnackbar } = useCustomSnackbar()
  const { notifySentry } = useSentryNotifier()

  const handleLogout = async () => {
    try {
      await signOut(auth)
    } catch (err) {
      enqueueSnackbar(t('layouts.dataSyncLayout.messageLogoutError'), { severity: 'error' })
      notifySentry(err)
    }
  }

  return (
    <div className='relative'>
      <div className='absolute top-0 flex items-center justify-between w-full px-8 py-4'>
        <img src='/logo.png' alt='ecpower' className='w-[110px]' />

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant='ghost' size='icon'>
              <CircleUser />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className='w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg' align='end'>
            <DropdownMenuLabel className='p-0 font-normal'>
              <div className='flex items-center gap-2 px-1 py-1.5 text-left text-sm'>
                <Avatar className='h-6 w-6 rounded-lg'>
                  <AvatarImage src={USER_AVATAR_PATH} alt={shop.shopName} />
                </Avatar>
                <div className='grid flex-1 text-left text-sm leading-tight'>
                  <span className='truncate font-semibold'>{shop.shopName}</span>
                  <span className='truncate text-xs'>{account.email}</span>
                </div>
              </div>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={handleLogout}>
              <LogOut />
              {t('layouts.dashboardLayout.logout')}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <Outlet />
    </div>
  )
}
