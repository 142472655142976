import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { cn } from '@/lib/utils'

import { FC, useContext } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { DimensionOptionsContext } from 'features/customerSegments/context/dimensionOptionsContext'
import { OP_CONTAIN_ALL, OP_CONTAIN_ANY, OP_NOT_CONTAIN } from '../schema/const'
import { QuerySetFormState } from '../schema/querySetFormSchema'
import { AutoCompleteValue } from '../value/autoCompleteValue'
import { QueryItemProps } from './type'
import { useTranslation } from 'react-i18next'

export const ReferrerQuery: FC<QueryItemProps> = ({ queryItemPath }) => {
  const { t } = useTranslation()
  const { control } = useFormContext<QuerySetFormState>()
  const { data } = useContext(DimensionOptionsContext)
  const options = data.referrer

  return (
    <>
      <Controller
        control={control}
        name={`${queryItemPath}.operator`}
        render={({ field, fieldState: { error } }) => {
          return (
            <div className={cn('min-w-[140px]', error && 'border-red-500')}>
              <Select value={field.value} onValueChange={field.onChange}>
                <SelectTrigger className='w-full'>
                  <SelectValue placeholder={t('features.customerSegments.querySet.querySetForm.op', { context: field.value })} />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value={OP_CONTAIN_ANY}>
                      {t('features.customerSegments.querySet.querySetForm.op', { context: OP_CONTAIN_ANY })}
                    </SelectItem>
                    <SelectItem value={OP_CONTAIN_ALL}>
                      {t('features.customerSegments.querySet.querySetForm.op', { context: OP_CONTAIN_ALL })}
                    </SelectItem>
                    <SelectItem value={OP_NOT_CONTAIN}>
                      {t('features.customerSegments.querySet.querySetForm.op', { context: OP_NOT_CONTAIN })}
                    </SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
              {error && <span className='text-red-500 text-xs mt-1'>{error.message}</span>}
            </div>
          )
        }}
      />

      <AutoCompleteValue queryItemValuePath={`${queryItemPath}.value`} options={options} />
    </>
  )
}
