import { cn } from '@/lib/utils'

import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { QuerySetFormState } from '../schema/querySetFormSchema'
import { useTranslation } from 'react-i18next'
import { CustomInput } from '@/components/ui/input-custom'

type Props = {
  queryItemValuePath: `query_subsets.${number}.query_items.${number}.value`
  inputAdornmentString: string
  inputPropsMaxValue: number
}

export const IntValue: FC<Props> = ({ queryItemValuePath, inputAdornmentString, inputPropsMaxValue }) => {
  const { t } = useTranslation()
  const { control } = useFormContext<QuerySetFormState>()

  return (
    <Controller
      control={control}
      name={queryItemValuePath}
      rules={{
        validate: (v) => {
          if (v == null) {
            // (==) null or undefined
            return t('features.customerSegments.querySet.querySetForm.messageValidationMustNumber')
          }
          if (typeof v === 'number' && (v < 1 || v > inputPropsMaxValue)) {
            return t('features.customerSegments.querySet.querySetForm.messageValidationNumberRange', { min: 1, max: inputPropsMaxValue })
          }
        },
      }}
      render={({ field, fieldState: { error } }) => {
        return (
          <div className='min-w-[120px] mr-2 gap-1'>
            <CustomInput
              type='number'
              value={typeof field.value === 'number' ? field.value : ''}
              onChange={(e) => field.onChange(e.target.value !== '' ? Number(e.target.value) : null)}
              className={cn(error && 'border-red-500')}
              min={1}
              max={inputPropsMaxValue}
              endAdornment={inputAdornmentString}
            />
            {error && <span className='text-red-500 text-xs'>{error.message}</span>}
          </div>
        )
      }}
    />
  )
}
